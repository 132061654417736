import React, { ChangeEvent, Key, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tree as RefTree } from 'antd';
import styled from '@emotion/styled';
import { Colors, times8 } from 'Constants/Styles';
import classNames from 'classnames';
import { uniqFast } from 'Utils/functional';
import { Input } from 'Components/Primitives/Input/Input';
import { Checkbox, CheckboxChangeEvent } from 'Components/Primitives/Checkbox/Checkbox';
import { TreeSelectValue, TreeNode } from 'Components/Primitives/Tree/Tree.types';
import {
  TreeNodeWithKey,
  getTreeNodeKeys,
  getTreeNodesMapByValue,
  getTreeNodesWithKey,
  getTreeSelectabledChildrenIds
} from 'Components/Primitives/Tree/Tree/TreeUtils';

export interface TreeProps {
  value?: TreeSelectValue;
  options: TreeNode[];
  selectMultiple?: boolean;
  height?: number;
  className?: string;
  'data-testid'?: string;
  onChange?(ids: TreeSelectValue): void;
}

const StyledTree = styled.div`
  .tree-header {
    margin-bottom: ${times8()}px;
  }

  .checkbox-select-all-wrapper {
    position: relative;
    border-bottom: 1px solid #e6e7e8;
    height: 48px;
    .select-all-cb,
    .select-matching-cb,
    .total-selected {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      margin-inline-start: 0;
      transition:
        opacity 0.2s ease-out,
        transform 0.2s ease-out;
    }
    .select-all-cb {
      opacity: 1;
      transform: translateY(-50%);
      pointer-events: auto;
    }
    .select-matching-cb {
      opacity: 0;
      transform: translateY(0);
      pointer-events: none;
    }
    &.filtered {
      .select-all-cb {
        opacity: 0;
        transform: translateY(-100%);
        pointer-events: none;
      }
      .select-matching-cb {
        opacity: 1;
        transform: translateY(-50%);
        pointer-events: auto;
      }
    }
    .total-selected {
      right: 0;
      left: auto;
      color: ${Colors.SubText};
      transform: translateY(-50%);
    }
  }

  .ant-tree-checkbox {
    position: relative;
    border-radius: 2px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -4px;
      left: -4px;
      height: 32px;
      width: 300px;
      z-index: 1;
    }
  }
  &.flat-tree {
    .ant-tree-switcher.ant-tree-switcher-noop {
      display: none;
    }
  }
`;

const DEFAULT_HEIGHT = 400;
type MultiCheck = Key[] | { checked: Key[]; halfChecked: Key[] };

export const Tree: React.FC<TreeProps> = ({
  value,
  options,
  selectMultiple,
  height = DEFAULT_HEIGHT,
  className,
  'data-testid': dataTestId,
  onChange
}) => {
  const { t } = useTranslation(['common']);
  const [treeData, setTreeData] = useState<TreeNodeWithKey[]>([]);
  const [selected, setSelected] = useState<Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<{ checked: Key[]; halfChecked: Key[] }>({
    checked: [],
    halfChecked: []
  });
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [searchVal, setSearchVal] = useState<string>('');

  const isFlatTree = useMemo((): boolean => options.every((o) => !o.children.length), [options]);
  const treeNodesMapByValue = useMemo(() => getTreeNodesMapByValue(options), [options]);
  const numberOfSelected = useMemo(
    (): number => checkedKeys.checked.filter((k) => treeNodesMapByValue[k.toString()].node.selectable).length,
    [checkedKeys, treeNodesMapByValue]
  );
  const allSelectableTreeNodesIds = useMemo((): Key[] => getTreeSelectabledChildrenIds(options), [options]);
  const visibleSelectableTreeNodeIds = useMemo((): Key[] => getTreeSelectabledChildrenIds(treeData), [treeData]);

  // search
  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const q = e.target.value;
      setSearchVal(q);
      const searchOptions = getTreeNodesWithKey(options, q);
      setTreeData(searchOptions);
      setExpandedKeys(q === '' ? [] : getTreeNodeKeys(searchOptions));
    },
    [options]
  );

  // expand
  const onExpand = useCallback((e: Key[]) => {
    setExpandedKeys(e);
  }, []);

  //
  // single select
  const emitSingleSelectChange = useCallback(
    (changedValues: Key) => {
      onChange && onChange(changedValues as TreeSelectValue);
    },
    [onChange]
  );

  const onSelect = useCallback(
    (e: Key[]) => {
      if (selectMultiple) return;
      e.length > 0 && emitSingleSelectChange(e[0]);
    },
    [selectMultiple, emitSingleSelectChange]
  );

  //
  // multi select
  const updateCheckedList = useCallback(
    (changedValues: Key[]): void => {
      const changedCheckedKeys = Object.values(treeNodesMapByValue).reduce(
        (acc: { checked: Key[]; halfChecked: Key[] }, v) => {
          const checked =
            changedValues.includes(v.node.value) || v.selectableChildrenIds.every((id) => changedValues.includes(id));
          const halfChecked = !checked && v.selectableChildrenIds.some((id) => changedValues.includes(id));
          if (checked) {
            acc.checked.push(v.node.value);
          }
          if (halfChecked) {
            acc.halfChecked.push(v.node.value);
          }
          return acc;
        },
        { checked: [], halfChecked: [] }
      );
      setCheckedKeys(changedCheckedKeys);
    },
    [treeNodesMapByValue]
  );

  const emitMultiSelectChange = useCallback(
    (changedValues: Key[]) => {
      // update state
      setSelected(changedValues);
      updateCheckedList(changedValues);

      // emit change
      onChange && onChange(changedValues as TreeSelectValue);
    },
    [updateCheckedList, onChange]
  );

  const checkAll = useMemo(
    (): boolean => selected.length === allSelectableTreeNodesIds.length,
    [selected, allSelectableTreeNodesIds]
  );
  const checkAllIndeterminate = useMemo(
    (): boolean => !checkAll && selected.length > 0 && selected.length < allSelectableTreeNodesIds.length,
    [checkAll, selected, allSelectableTreeNodesIds]
  );

  const partialCheckAll = useMemo(
    (): boolean => visibleSelectableTreeNodeIds.every((o) => selected.includes(o)),
    [selected, visibleSelectableTreeNodeIds]
  );
  const partialCheckAllIndeterminate = useMemo(
    (): boolean => !partialCheckAll && visibleSelectableTreeNodeIds.some((o) => selected.includes(o)),
    [partialCheckAll, selected, visibleSelectableTreeNodeIds]
  );

  const onCheckAll = useCallback(
    (e: CheckboxChangeEvent) => {
      const changedValues = e.target.checked
        ? uniqFast([...selected, ...visibleSelectableTreeNodeIds] as (string | number)[])
        : selected.filter((v) => !visibleSelectableTreeNodeIds.includes(v));

      emitMultiSelectChange(changedValues);
    },
    [selected, visibleSelectableTreeNodeIds, emitMultiSelectChange]
  );

  const onCheck = useCallback(
    (_: MultiCheck, e: { checked: boolean; node: TreeNode }) => {
      if (!selectMultiple) return;
      const node = treeNodesMapByValue[e.node.value];
      const selectedKeys = node.selectableChildrenIds.length > 0 ? node.selectableChildrenIds : [node.node.value];
      const curSelectabledKeys = checkedKeys.checked.filter((k) => treeNodesMapByValue[k.toString()].node.selectable);

      const changedValues = e.checked
        ? uniqFast([...curSelectabledKeys, ...selectedKeys] as (string | number)[])
        : curSelectabledKeys.filter((v) => !selectedKeys.includes(v));

      emitMultiSelectChange(changedValues);
    },
    [selectMultiple, treeNodesMapByValue, checkedKeys, emitMultiSelectChange]
  );

  useEffect(() => {
    setTreeData(getTreeNodesWithKey(options));
  }, [options]);

  useEffect(() => {
    if (!value) return;
    const checked = Array.isArray(value) ? value : [value];
    const selectableChecked = checked.filter((v) => allSelectableTreeNodesIds.includes(v));
    updateCheckedList(selectableChecked);
    setSelected(selectableChecked);
  }, [value, allSelectableTreeNodesIds, updateCheckedList]);

  return (
    <StyledTree data-testid={dataTestId} className={classNames(className, isFlatTree ? 'flat-tree' : '')}>
      <div className="tree-header">
        {allSelectableTreeNodesIds.length > 5 && (
          <Input.Search className="search-tree" placeholder="Search" allowClear={true} onChange={onSearch} />
        )}

        {selectMultiple && (
          <div className={classNames('checkbox-select-all-wrapper', searchVal && searchVal.length > 0 && 'filtered')}>
            <Checkbox
              data-testid="checkbox-select-all"
              className="checkbox-select-option select-all-cb"
              onChange={onCheckAll}
              checked={checkAll}
              indeterminate={checkAllIndeterminate}
            >
              {t('all')}
            </Checkbox>
            <Checkbox
              data-testid="checkbox-select-matching"
              className="checkbox-select-option select-matching-cb"
              onChange={onCheckAll}
              checked={partialCheckAll}
              indeterminate={partialCheckAllIndeterminate}
            >
              {t('all-matching')}
            </Checkbox>

            {checkedKeys && <div className="total-selected">Selected ({numberOfSelected})</div>}
          </div>
        )}
      </div>

      <RefTree
        checkedKeys={checkedKeys}
        checkStrictly={true}
        selectedKeys={[]}
        height={height}
        treeData={treeData}
        multiple={selectMultiple}
        checkable={selectMultiple}
        expandAction={selectMultiple ? undefined : 'click'}
        expandedKeys={expandedKeys}
        virtual={true}
        onCheck={selectMultiple ? onCheck : undefined}
        onSelect={selectMultiple ? undefined : onSelect}
        onExpand={onExpand}
      />
    </StyledTree>
  );
};
