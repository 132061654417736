export enum PredefinedDateRange {
  // Present periods
  ThisWeek = '[ThisWeek]',
  ThisMonth = '[ThisMonth]',
  ThisQuarter = '[ThisQuarter]',
  ThisYear = '[ThisYear]',

  // Past dates
  LastWeek = '[LastWeek]',
  LastMonth = '[LastMonth]',
  LastQuarter = '[LastQuarter]',
  LastYear = '[LastYear]',

  PastWeek = '[PastWeek]',
  Past2Weeks = '[Past2Weeks]',
  PastMonth = '[PastMonth]',
  Past3Months = '[Past3Months]',
  Past6Months = '[Past6Months]',
  PastYear = '[PastYear]',

  // Future dates
  NextWeek = '[NextWeek]',
  NextMonth = '[NextMonth]',
  NextQuarter = '[NextQuarter]',
  NextYear = '[NextYear]',

  Next7Days = '[Next7Days]',
  Next30Days = '[Next30Days]',
  Next90Days = '[Next90Days]'
}

export type AbsoluteDateRange = [string, string];

export type DateRangeValue = PredefinedDateRange | AbsoluteDateRange;

export type PredefinedDateRangeOption = {
  label: string;
  value: PredefinedDateRange;
  description: string;
};
