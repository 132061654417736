import React, { useMemo } from 'react';
import { getColorByText } from 'Utils/colors';
import styled from '@emotion/styled';
import { getUserInitials } from 'Utils/text';
import classNames from 'classnames';
import { hexAlpha15 } from 'Constants/Styles';
import { NO_VALUE } from 'Constants/Chars.consts';
import { AvatarBadgeSize, AvatarProps } from 'Components/Primitives/Avatar/Avatar.types';
import { AvatarFontSize, AvatarSizeToPx, getAvatarInitialsFontSize } from 'Components/Primitives/Avatar/AvatarUtils';

const badgeSizePos: Record<AvatarBadgeSize, { size: number; bottom: number; right: number }> = {
  small: { size: 16, bottom: -2, right: -6 },
  large: { size: 24, bottom: -8, right: -10 }
};

const StyledAvatar = styled.span<{ size: number; fontSize: number; badgeSize: AvatarBadgeSize }>`
  position: relative;
  display: inline-block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  vertical-align: middle;
  flex-shrink: 0;

  .avatar-content {
    position: relative;
    overflow: hidden;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    line-height: ${({ size }) => size}px;
    font-size: ${({ fontSize }) => fontSize}px;
    color: white;
    text-align: center;
    white-space: nowrap;

    > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .badge {
    position: absolute;
    bottom: ${({ badgeSize }) => badgeSizePos[badgeSize].bottom}px;
    right: ${({ badgeSize }) => badgeSizePos[badgeSize].right}px;
  }
`;

const StyledAvatarBadge = styled.div<{ color: string; size: AvatarBadgeSize }>`
  width: ${({ size }) => badgeSizePos[size].size}px;
  height: ${({ size }) => badgeSizePos[size].size}px;
  line-height: ${({ size }) => badgeSizePos[size].size}px;
  border-radius: 100%;
  background-color: white;
  text-align: center;
  color: ${({ color }) => color};
  font-size: 12px;

  .badge-value {
    position: relative;
    z-index: 2;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    top: 0;
    left: 0;
    background-color: ${({ color }) => `${color}${hexAlpha15}`};
  }
`;

export const Avatar: React.FC<AvatarProps> = ({ size, src, title, badge, className, 'data-testid': dataTestId }) => {
  const backgroundColor: string = useMemo(() => getColorByText(title || ''), [title]);

  const initials = useMemo((): string | undefined => {
    const titleNames = (title || '').split(' ');
    return titleNames.length > 1
      ? getUserInitials(titleNames[0], titleNames[titleNames.length - 1])
      : titleNames[0].charAt(0);
  }, [title]);

  return (
    <StyledAvatar
      size={typeof size === 'number' ? size : AvatarSizeToPx[size]}
      fontSize={typeof size === 'number' ? getAvatarInitialsFontSize(size) : AvatarFontSize[size]}
      badgeSize={badge?.size || 'small'}
      className={classNames('avatar', className)}
      data-testid={dataTestId}
    >
      <div className="avatar-content" style={{ background: src ? '#fff' : backgroundColor }}>
        {src ? <img src={src} alt={title} /> : <>{initials || NO_VALUE}</>}
      </div>
      {badge && (
        <StyledAvatarBadge
          data-testid="avatar-badge"
          className="badge"
          color={badge.color}
          size={badge.size || 'small'}
        >
          <div className="badge-value">{badge.value}</div>
        </StyledAvatarBadge>
      )}
    </StyledAvatar>
  );
};
