import { SortOrder } from 'Components/Table/Table.types';
import { isDate1BeforeDate2 } from 'Utils/date';

type TableValue = string | number | null;

export const sortValues = (params: {
  a: TableValue;
  b: TableValue;
  a2?: TableValue;
  b2?: TableValue;
  sortOrder: SortOrder | undefined;
}): number => {
  const { a, b } = params;
  return typeof a === 'string' || typeof b === 'string' ? sortString(params) : sortNumber(params);
};

export const sortDates = (params: {
  a: string | null;
  b: string | null;
  a2?: TableValue;
  b2?: TableValue;
  sortOrder: SortOrder | undefined;
}): number => {
  const { a, b, a2, b2, sortOrder } = params;
  if (a === b) return a2 && b2 ? sortValues({ a: a2, b: b2, sortOrder }) : 0;
  if (a === null || a === '') return sortOrder === 'ascend' ? 1 : -1;
  if (b === null || b === '') return sortOrder === 'ascend' ? -1 : 1;
  return a && b && isDate1BeforeDate2(new Date(a), new Date(b)) ? 1 : -1;
};

export const sortBoolean = (params: {
  a: boolean | null;
  b: boolean | null;
  a2?: TableValue;
  b2?: TableValue;
  sortOrder: SortOrder | undefined;
}): number => {
  const { a, b, a2, b2, sortOrder } = params;
  if (a === b) return a2 && b2 ? sortValues({ a: a2, b: b2, sortOrder }) : 0;
  if (a === null || a === undefined) return sortOrder === 'ascend' ? 1 : -1;
  if (b === null || b === undefined) return sortOrder === 'ascend' ? -1 : 1;
  return a > b ? -1 : 1;
};

const sortString = (params: {
  a: TableValue;
  b: TableValue;
  a2?: TableValue;
  b2?: TableValue;
  sortOrder: SortOrder | undefined;
}): number => {
  const { a: valA, b: valB, a2, b2, sortOrder } = params;
  const a = typeof valA === 'string' ? valA.toLowerCase() : valA;
  const b = typeof valB === 'string' ? valB.toLowerCase() : valB;
  if (a === b) return a2 && b2 ? sortValues({ a: a2, b: b2, sortOrder }) : 0;
  else if (a === null || a === '') return sortOrder === 'ascend' ? 1 : -1;
  else if (b === null || b === '') return sortOrder === 'ascend' ? -1 : 1;
  else return a < b ? -1 : 1;
};

const sortNumber = (params: {
  a: TableValue;
  b: TableValue;
  a2?: TableValue;
  b2?: TableValue;
  sortOrder: SortOrder | undefined;
}): number => {
  const { a, b, a2, b2, sortOrder } = params;
  if (a === b) return a2 && b2 ? sortValues({ a: a2, b: b2, sortOrder }) : 0;
  else if (a === null) return sortOrder === 'ascend' ? 1 : -1;
  else if (b === null) return sortOrder === 'ascend' ? -1 : 1;
  else return a < b ? 1 : -1;
};
