import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

export interface DnDProps {
  list: DnDListItem[];
  onOrderChange?(orderedList: DnDListItem[]): void;
}

export interface DnDListItem {
  key: string | number;
  element: {
    content: ReactNode;
    dataTestId?: string;
    classNames?: string[];
  };
  isDragDisabled?: boolean;
}

const reorder = (list: DnDListItem[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const DnD: React.FC<DnDProps> = ({ list, onOrderChange }) => {
  const [orderedList, setOrderedList] = useState<DnDListItem[]>(list);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) return;

      const ordered = reorder(orderedList, result.source.index, result.destination.index);
      setOrderedList(ordered);
      onOrderChange && onOrderChange(ordered);
    },
    [orderedList, onOrderChange]
  );

  useEffect(() => {
    setOrderedList(list);
  }, [list]);

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(p1) => {
            return (
              <ul {...p1.droppableProps} ref={p1.innerRef} className="dnd-list">
                {orderedList.map((listItem, index) => {
                  return (
                    <Draggable
                      key={listItem.key}
                      draggableId={listItem.key.toString()}
                      index={index}
                      isDragDisabled={listItem.isDragDisabled}
                    >
                      {(p2, snapshot2) => (
                        <li
                          ref={p2.innerRef}
                          {...p2.draggableProps}
                          {...p2.dragHandleProps}
                          data-testid={listItem.element.dataTestId}
                          className={classNames(listItem.element.classNames, snapshot2.isDragging && 'dragging')}
                        >
                          {listItem.element.content}
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {p1.placeholder}
              </ul>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
