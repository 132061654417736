import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { borderRadius } from 'Constants/Styles';
import { getTrendBackgroundColor, getTrendDisplayValue } from 'Components/Primitives/Trend/TrendUtils';
import { TrendIcon } from 'Components/Primitives/Trend/TrendIcon';
import { TrendArrowDirectionMode, TrendMode } from 'Components/Primitives/Trend/Trend.types';

export interface TrendProps {
  value: number;
  mode: TrendMode;
  backgroundColor?: string;
  suffix?: ReactNode;
  arrowDirectionMode?: TrendArrowDirectionMode;
}

const StyledTrend = styled.span<{ backgroundColor?: string; arrowDirectionMode?: TrendArrowDirectionMode }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: initial;
  display: inline-block;
  padding: ${({ backgroundColor }) => (backgroundColor ? `4px 12px` : 0)};
  border-radius: ${borderRadius}px;

  .trend-icon {
    margin-left: 2px;
  }

  .trend-value,
  .trend-icon,
  .trend-suffix {
    display: inline-block;
    vertical-align: middle;
  }
  .trend-suffix {
    margin-left: 4px;
  }
`;

const TrendBase: React.FC<TrendProps> = ({ value, mode = 'text', backgroundColor, suffix, arrowDirectionMode }) => {
  return (
    <StyledTrend
      data-testid="trend"
      backgroundColor={backgroundColor || getTrendBackgroundColor(value, mode)}
      arrowDirectionMode={arrowDirectionMode}
    >
      <span className="trend-value" data-testid="trend-value">
        {getTrendDisplayValue(value)}
      </span>

      <TrendIcon value={value} arrowDirectionMode={arrowDirectionMode} />

      {suffix && (
        <span className="trend-suffix" data-testid="trend-suffix">
          {suffix}
        </span>
      )}
    </StyledTrend>
  );
};

export const Trend = React.memo(TrendBase);
