import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown } from 'antd';
import classNames from 'classnames';
import { ColorPalette, Colors, times8 } from 'Constants/Styles';
import { Input } from 'Components/Primitives/Input/Input';
import { Icon } from 'Components/Primitives/Icon/Icon';
import { Icons } from 'Components/Primitives/Icon/Icon.types';
import { getColorWithBrightness } from 'Utils/colors';
import { useTranslation } from 'react-i18next';

export interface ColorPickerProps {
  value?: string | null;
  colorList?: string[];
  allowClear?: boolean;
  className?: string;
  onChange?(v: string | null): void;
}

const StyledColorPicker = styled(Dropdown)`
  position: relative;
  cursor: pointer;
  .color-picker-input {
    pointer-events: none;
    width: 100%;
  }
  .color-picker-input-color-box {
    width: 24px;
    height: 20px;
  }
  .ant-select-clear {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
`;

const StyledColorBox = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 3px;
`;

const StyledColorPickerDropdown = styled.div`
  width: 240px;
  background-color: white;
  padding: 16px;
  box-shadow:
    0 3px 6px -4px rgb(0 0 0 / 12%),
    0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 2px;
  .color-picker-color-button {
    cursor: pointer;
    width: 100%;
    height: ${times8(3)}px;
    &:hover {
      border: 1px solid ${ColorPalette.Blue900};
    }
  }
`;

const getColorScale = (color: string): string[] =>
  [...Array(7)].map((_, index) => getColorWithBrightness(color, (index - 3) / 12));

export const ColorPicker: React.FC<ColorPickerProps> = ({
  value,
  colorList,
  allowClear = true,
  className,
  onChange
}) => {
  const { t } = useTranslation(['common']);

  const [compValue, setCompValue] = useState<string | null | undefined>();
  const [visible, setVisible] = useState<boolean>(false);
  const [showClear, setShowClear] = useState<boolean>(false);

  const colors = useMemo(
    (): string[] =>
      colorList
        ? colorList
        : getColorScale(Colors.Primary)
            .concat(getColorScale(Colors.Positive).reverse())
            .concat(getColorScale(Colors.Neutral))
            .concat(getColorScale(Colors.Negative).reverse())
            .concat(getColorScale(ColorPalette.Gray600)),
    [colorList]
  );

  const showClearButton = useMemo((): boolean => showClear && typeof compValue === 'string', [showClear, compValue]);

  const onColorClick = useCallback(
    (col: string) => {
      setCompValue(col);
      setVisible(false);
      onChange && onChange(col);
    },
    [onChange]
  );

  const onClear = useCallback(() => {
    setCompValue(null);
    setVisible(false);
    onChange && onChange(null);
  }, [onChange]);

  useEffect(() => {
    setCompValue(value);
  }, [value]);

  return (
    <StyledColorPicker
      open={visible}
      onOpenChange={(isVisible) => isVisible !== visible && setVisible(isVisible)}
      trigger={['click']}
      autoFocus
      overlayStyle={{ minWidth: 0 }}
      dropdownRender={() => (
        <StyledColorPickerDropdown>
          {colors.map((c) => (
            <StyledColorBox
              key={c}
              color={c}
              className="color-picker-color-button"
              data-testid="color-picker-color-button"
              onClick={() => onColorClick(c)}
            />
          ))}
        </StyledColorPickerDropdown>
      )}
    >
      <div
        data-testid="color-picker-select"
        className={classNames('color-picker-select', className)}
        onMouseEnter={() => allowClear && setShowClear(true)}
        onMouseLeave={() => allowClear && setShowClear(false)}
      >
        <Input
          className="color-picker-input"
          data-testid="color-picker-input"
          readOnly
          value={compValue || undefined}
          placeholder={t('color-picker.placeholder')}
          prefix={compValue && <StyledColorBox className="color-picker-input-color-box" color={compValue} />}
          onFocus={() => setVisible(true)}
          onBlur={() => setVisible(false)}
          suffix={
            showClearButton ? null : visible ? (
              <Icon icon={Icons.ChevronUp} className="ant-select-arrow" />
            ) : (
              <Icon icon={Icons.ChevronDown} className="ant-select-arrow" />
            )
          }
        />
        {allowClear && (
          <button
            data-testid="color-picker-clear"
            style={{ opacity: showClearButton ? 1 : 0 }}
            className="ant-select-clear"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClear();
            }}
          >
            <Icon icon={Icons.CloseRound} />
          </button>
        )}
      </div>
    </StyledColorPicker>
  );
};
