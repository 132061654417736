import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Button, ButtonProps } from 'Components/Primitives';
import { FloatingFish, OceanBubbles, OceanBubblesProps } from 'Components/Styled/StyledOcean';
import { OceanBackground2, OceanFishShadow, OceanPufferFish } from 'Components/Styled/OceanAssets';
import { Layout, mediaQueryMax, times8 } from 'Constants/Styles';
import { isMobileApp } from 'Mobile/MobileAppUtils';

export interface ErrorPageProps {
  pageMode: 'fullscreen' | 'inApp';
  title: string;
  subtitle?: ReactNode;
  className?: string;
  bubbleProps?: OceanBubblesProps;
  buttonConfig?: {
    label: string;
    onClick(): void;
    type?: ButtonProps['type'];
  };
  'data-testid'?: string;
}

export const StyledErrorPage = styled.div<{ pageMode: ErrorPageProps['pageMode'] }>`
  height: ${({ pageMode }) => (pageMode === 'fullscreen' ? '100vh' : 'calc(100vh - 220px)')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .error-title,
  .error-subtitle {
    margin-bottom: ${times8(2)}px;
  }

  .ocean-img-wrapper {
    margin-top: ${times8(10)}px;
    pointer-events: none;
    position: relative;
    z-index: -1;
  }

  .bubbles-container {
    position: fixed;
    top: 0;
    left: 0;
    width: ${({ pageMode }) => (pageMode === 'fullscreen' ? '100vw' : `calc(100vw - ${Layout.sideBarWidth}px)`)};
    left: ${({ pageMode }) => (pageMode === 'fullscreen' ? 0 : `${Layout.sideBarWidth}px`)};
    height: 100vh;
  }

  .floatin-fish {
    position: relative;
    z-index: 2;
    height: 25vh;
    max-height: 180px;
  }

  .ocean-background {
    position: absolute;
    z-index: -2;
    width: 90%;
    left: 5%;
    top: -50px;
  }

  .fish-shadow {
    position: relative;
    display: block;
    margin: 50px auto 0;
    left: 0;
    width: 100%;
    max-width: 220px;
    opacity: 0.4;
  }

  // Mobile
  ${mediaQueryMax.small} {
    position: fixed;
    top: 40px;
    left: 0;
    width: 100%;
    height: ${({ pageMode }) => (pageMode === 'fullscreen' ? '100%' : 'calc(100% - 40px)')};

    .floatin-fish {
      height: 120px;
      max-height: none;
    }

    .ocean-background {
      width: 180px;
      left: calc(50% - 90px);
      top: -50px;
    }

    .fish-shadow {
      max-width: 160px;
    }
  }
`;

export const ErrorPage: React.FC<ErrorPageProps> = ({
  pageMode,
  title,
  subtitle,
  className,
  bubbleProps,
  buttonConfig,
  'data-testid': dataTestId
}) => {
  const [mobile] = useState<boolean>(isMobileApp());
  const bubbleContainerRef = useRef<HTMLDivElement>(null);
  const [bubbleWrapperSize, setBubbleWrapperSize] = useState<number>();

  useEffect(() => {
    if (bubbleContainerRef.current && bubbleContainerRef.current.clientWidth > 0) {
      setBubbleWrapperSize(bubbleContainerRef.current.clientWidth);
    }
  }, [bubbleContainerRef]);

  return (
    <StyledErrorPage pageMode={pageMode} className={className} data-testid={dataTestId}>
      <h1 className="error-title">{title}</h1>

      {subtitle && <div className="error-subtitle">{subtitle}</div>}

      {buttonConfig && (
        <Button type={buttonConfig.type || 'primary'} onClick={buttonConfig.onClick}>
          {buttonConfig.label}
        </Button>
      )}

      <div className="ocean-img-wrapper">
        <div className="bubbles-container" ref={bubbleContainerRef}>
          <OceanBubbles
            numberOfBubbles={mobile ? 8 : 15}
            maxBubbleSize={mobile ? 15 : undefined}
            size={bubbleWrapperSize}
            {...bubbleProps}
          />
        </div>
        <OceanBackground2 className="ocean-background" />
        <FloatingFish className="floatin-fish">
          <OceanPufferFish />
        </FloatingFish>
        <OceanFishShadow className="fish-shadow" />
      </div>
    </StyledErrorPage>
  );
};
