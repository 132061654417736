import { ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import { nonNullable, reactNodeToString, uniqFast } from 'Utils/functional';
import { CheckboxSelectOption } from 'Components/CheckboxSelect';
import { TableColumn, TableColumnSelectConfig } from 'Components/Table/Table.types';

const TableColumnSelectOption = styled.div`
  display: inline-flex;
  align-items: center;
  .suffix {
    font-size: 12px;
    margin-left: 4px;
    height: 14px;
    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
`;

export const getTableColumnSelectOptions = function <T>(
  config: TableColumnSelectConfig,
  columns: TableColumn<T>[]
): CheckboxSelectOption[] {
  return columns
    .filter((col) => col.id && config.columnIds.includes(col.id))
    .map((col) => {
      const columnTitleNode = getColumnTitle(col);
      return columnTitleNode && col.id
        ? { label: columnTitleNode, value: col.id, searchValue: reactNodeToString(columnTitleNode) }
        : null;
    })
    .filter(nonNullable);
};

const getColumnTitle = function <T>(col: TableColumn<T>): ReactNode {
  if (!col.header) return null;
  if (typeof col.header === 'string') return col.header;
  const columnTitle = col.header as ReactElement;
  return (
    <TableColumnSelectOption>
      <div className="title">{columnTitle.props.title}</div>
      {columnTitle.props.suffix && <div className="suffix">{columnTitle.props.suffix}</div>}
    </TableColumnSelectOption>
  );
};

export const getColumnSelectedIds = function <T>(
  selected: string[] | undefined,
  selectConfig: TableColumnSelectConfig | undefined,
  columns: TableColumn<T>[]
): string[] {
  if (!selectConfig) {
    return columns.map((col) => col.id);
  } else {
    const staticColsIds: string[] = columns.map((col) => col.id).filter((id) => !selectConfig?.columnIds.includes(id));
    return selected ? uniqFast(staticColsIds.concat(selected)) : staticColsIds.concat(selectConfig.columnIds);
  }
};

export const getColumnsOrderIds = function <T>(
  order: string[] | undefined,
  selectConfig: TableColumnSelectConfig | undefined,
  columns: TableColumn<T>[]
): string[] {
  return selectConfig && order && columns
    ? columns
        .map((col) => col.id)
        .filter((id) => !selectConfig.columnIds.includes(id))
        .concat(order)
    : columns.map((col) => col.id);
};
