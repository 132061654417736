export const times8 = (num: number = 1) => num * 8;
export const borderRadius = 6;
export const hexAlpha0 = '00';
export const hexAlpha03 = '08';
export const hexAlpha05 = '0d';
export const hexAlpha07 = '12';
export const hexAlpha10 = '1a';
export const hexAlpha15 = '26';
export const hexAlpha20 = '33';
export const hexAlpha25 = '40';
export const hexAlpha75 = 'bf';

export const IconSize = {
  large: 24,
  medium: 18,
  small: 13
};

export const AvatarBackgroundColors = [
  '#4caf50',
  '#ffc107',
  '#8bc34a',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#03a9f4',
  '#3f51b5',
  '#673ab7'
];

export const ChartColors = ['#5486e7', '#ff723f', '#c957eb', '#7f8a84', '#e88197', '#bdba1a', '#199496', '#356d37'];

export const ColorPalette = {
  White: '#ffffff',
  Gray100: '#fafafa',
  Gray200: '#e6e7e8',
  Gray300: '#D2D4D8',
  Gray400: '#b6b6b6',
  Gray500: '#8e929b',
  Gray600: '#68707e',
  Gray700: '#5b5d62',
  Gray800: '#44464e',
  Gray900: '#333333',
  Blue100: '#EEF2FC',
  Blue200: '#e8e8fd',
  Blue300: '#9ecbe9',
  Blue600: '#4B51E2',
  Blue700: '#2d63c0',
  Blue900: '#222426',
  Green100: '#ecfdf0',
  Green300: '#99E6BC',
  Green500: '#78BAAB',
  Green600: '#24B474',
  Green800: '#357869',
  Red600: '#ff6f6e',
  Yellow200: '#F8DC93',
  Yellow600: '#ffc408',
  Orange100: '#fff9e5',
  Orange200: '#ffccaa',
  Orange400: '#e6a565',
  Orange600: '#FF9F40',
  Orange700: '#e47c14',
  Purple600: '#9379E8',
  Purple700: '#673ab7'
};

export const Colors = {
  Primary: ColorPalette.Blue600,
  Text: ColorPalette.Blue900,
  SubText: ColorPalette.Gray600,
  Positive: ColorPalette.Green600,
  Neutral: ColorPalette.Yellow600,
  Negative: ColorPalette.Red600,
  Warning: ColorPalette.Orange700,
  Border: ColorPalette.Gray200
};

export const Layout = {
  pagePadding: 32,
  backgroundColor: '#fafafa',
  sideBarWidth: 60,
  topBarHeight: 60
};

export enum Breakpoint {
  XSmall = 'xSmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xLarge',
  XXLarge = 'xxLarge'
}
export const breakpoints: Record<Breakpoint, number> = {
  [Breakpoint.XSmall]: 576,
  [Breakpoint.Small]: 768,
  [Breakpoint.Medium]: 992,
  [Breakpoint.Large]: 1200,
  [Breakpoint.XLarge]: 1360,
  [Breakpoint.XXLarge]: 1500
};

export const mediaQueryMin: Record<Breakpoint, string> = Object.values(Breakpoint).reduce(
  (acc: Record<Breakpoint, string>, key) => {
    acc[key] = `@media (min-width: ${breakpoints[key]}px)`;
    return acc;
  },
  {
    [Breakpoint.XSmall]: '',
    [Breakpoint.Small]: '',
    [Breakpoint.Medium]: '',
    [Breakpoint.Large]: '',
    [Breakpoint.XLarge]: '',
    [Breakpoint.XXLarge]: ''
  }
);

export const mediaQueryMax: Record<Breakpoint, string> = Object.values(Breakpoint).reduce(
  (acc: Record<Breakpoint, string>, key) => {
    acc[key] = `@media (max-width: ${breakpoints[key]}px)`;
    return acc;
  },
  {
    [Breakpoint.XSmall]: '',
    [Breakpoint.Small]: '',
    [Breakpoint.Medium]: '',
    [Breakpoint.Large]: '',
    [Breakpoint.XLarge]: '',
    [Breakpoint.XXLarge]: ''
  }
);
