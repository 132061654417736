import React, { Fragment, useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Form, Icon, Icons, Input, RoundAddButton } from 'Components/Primitives';
import { times8 } from 'Constants/Styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FormFieldMetadataStringArray } from 'Components/FormGenerator/FormGenerator.types';
import {
  getFormGeneratorFieldPathName,
  getFormGeneratorFieldRules,
  getFormGenratorFieldTestId
} from 'Components/FormGenerator/FormGeneratorUtils';

export interface ListInputProps {
  metadata: FormFieldMetadataStringArray;
  className?: string;
  'data-testid'?: string;
}

const StyledListInput = styled.div`
  .form-list-item {
    margin-top: ${times8()}px;
    margin-bottom: ${times8()}px;
    .ant-form-item-control {
      margin-bottom: ${times8(0.5)}px;
    }
    .ant-form-item-control-input-content {
      display: flex;
    }
    .input-trash {
      flex-shrink: 0;
      margin-left: ${times8()}px;
    }
  }
`;

const TRANCATE_LIST_THREAHSOLD = 8;

export const ListInput: React.FC<ListInputProps> = ({ metadata, className, 'data-testid': dataTestId }) => {
  const { t } = useTranslation(['common']);
  const [expanded, setExpanded] = useState(false);
  const fieldRules = useMemo(() => getFormGeneratorFieldRules(metadata), [metadata]);
  const onExpand = useCallback(() => {
    setExpanded(true);
  }, []);

  return (
    <Form.List name={getFormGeneratorFieldPathName(metadata.id.toString())}>
      {(fields, { add, remove }, { errors }) => {
        const trancateList = !expanded && fields.length > TRANCATE_LIST_THREAHSOLD;

        return (
          <StyledListInput className={classNames('form-list', className)}>
            {fields.map((field, index) => {
              const disabled =
                (metadata.disableListInput && metadata.disableListInput(field, index)) || metadata.disabled || false;
              const hidden = trancateList && index > TRANCATE_LIST_THREAHSOLD - 2 && index < fields.length - 2;
              return (
                <Fragment key={field.key}>
                  <Form.Item className="form-list-item" label={index === 0 ? metadata.label : ''} hidden={hidden}>
                    <Form.Item {...field} rules={fieldRules} noStyle>
                      <Input
                        data-testid={dataTestId || getFormGenratorFieldTestId(metadata.id.toString())}
                        placeholder={t('form.string-placeholder', { fieldName: metadata.label })}
                        disabled={disabled}
                      />
                    </Form.Item>

                    {!disabled && (
                      <Button
                        data-testid="remove-input-button"
                        className="input-trash"
                        onClick={() => remove(field.name)}
                        icon={<Icon icon={Icons.Trash} />}
                      />
                    )}
                  </Form.Item>
                  {trancateList && index === 6 && (
                    <Button className="show-more-button" type="link" onClick={onExpand}>
                      <div>...</div>
                      {t('show-more-with-number', { number: fields.length - TRANCATE_LIST_THREAHSOLD })}
                      <div>...</div>
                    </Button>
                  )}
                </Fragment>
              );
            })}
            <Form.ErrorList errors={errors} />

            <Form.Item style={{ marginTop: -8 }}>
              <RoundAddButton
                data-testid="add-input-button"
                title={`${t('common:add')} ${metadata.label}`}
                onClick={() => add()}
              />
            </Form.Item>
          </StyledListInput>
        );
      }}
    </Form.List>
  );
};
