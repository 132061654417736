import React, { MouseEvent, KeyboardEvent, ReactNode, useCallback, useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { Colors, times8 } from 'Constants/Styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export interface ClumpLinesProps {
  content: ReactNode;
  maxLine: number | null;
  clump?: boolean;
  className?: string;
  'data-testid'?: string;
}

const StyledClumpLines = styled.div<{ clump: boolean; lines: number | null; morevisible: boolean }>`
  display: flex;

  .ellipsis-text {
    display: -webkit-box;
    -webkit-line-clamp: ${({ clump, lines }) => (clump ? lines : 'unset')};
    -webkit-box-orient: vertical;
    overflow: hidden;
    &:before {
      content: '';
      height: ${({ morevisible, clump }) => (morevisible && clump ? 'calc(100% - 22px)' : '0')};
      float: right;
    }
  }

  .clump-lines-show-more {
    float: right;
    clear: both;
    margin-left: ${times8()}px;
    cursor: pointer;
    color: ${Colors.Primary};
    &:hover {
      color: #a2b3cc;
    }
  }
`;

const ClumpLinesBase: React.FC<ClumpLinesProps> = ({
  content,
  maxLine,
  clump: clumpInput,
  className,
  'data-testid': dataTestId
}) => {
  const { t } = useTranslation(['common']);
  const [clump, setClump] = useState<boolean>(true);
  const [showMoreVisible, setShowMoreVisible] = useState<boolean>(false);

  const elRef = useRef<HTMLDivElement>(null);

  const handleTextClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setClump(false);
    setShowMoreVisible(false);
  }, []);

  const handleTextKey = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setClump(false);
      setShowMoreVisible(false);
    }
  }, []);

  useEffect(() => {
    setShowMoreVisible(typeof maxLine === 'number' && clump === true ? true : false);
  }, [maxLine, clump]);

  useEffect(() => {
    if (elRef.current) {
      setShowMoreVisible(elRef.current.scrollHeight > elRef.current.clientHeight);
    }
  }, [elRef]);

  useEffect(() => {
    if (typeof clumpInput === 'boolean') setClump(clumpInput);
  }, [clumpInput]);

  return (
    <StyledClumpLines
      onClick={handleTextClick}
      onKeyDown={handleTextKey}
      clump={clump}
      morevisible={showMoreVisible}
      lines={maxLine}
      data-testid={dataTestId || 'clump-lines'}
    >
      <div className={classNames('ellipsis-text', className)} ref={elRef}>
        {showMoreVisible && <div className="clump-lines-show-more">{t('more')}</div>}
        {content}
      </div>
    </StyledClumpLines>
  );
};

export const ClumpLines = React.memo(ClumpLinesBase);
