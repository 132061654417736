import React, { ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';
import { Button } from 'Components/Primitives/Button/Button';
import { ColorPalette, hexAlpha15, times8 } from 'Constants/Styles';
import { getColorWithBrightness } from 'Utils/colors';
import { Icon } from 'Components/Primitives/Icon/Icon';
import { Icons } from 'Components/Primitives/Icon/Icon.types';

export interface AlertProps {
  type: 'warning' | 'error';
  message: string;
  showIcon?: boolean;
  action?: ReactNode;
  closable?: boolean;
  closeText?: string;
  'data-testid'?: string;
  onClose?: () => void;
}

export const ALERT_HEIGHT = 48;

const StyledAlert = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${times8(2)}px;
  height: ${ALERT_HEIGHT}px;
  border-bottom: ${({ color }) => `1px solid ${color}`};
  color: ${({ color }) => getColorWithBrightness(color, -0.3)};
  background-color: ${({ color }) => `${color}${hexAlpha15}`};
  .alert-start,
  .alert-end {
    display: flex;
    align-items: center;
  }
  .alert-icon {
    font-size: 22px;
    margin-right: ${times8()}px;
  }
  .alert-close {
    margin-left: ${times8()}px;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

export const Alert: React.FC<AlertProps> = ({
  type,
  message,
  showIcon,
  action,
  closable,
  closeText,
  'data-testid': dataTestId,
  onClose
}) => {
  const alertColor = useMemo((): string => {
    switch (type) {
      case 'warning':
        return ColorPalette.Yellow600;
      case 'error':
        return ColorPalette.Red600;
    }
  }, [type]);

  const alertIcon = useMemo((): Icons => {
    switch (type) {
      case 'warning':
        return Icons.InfoSquare;
      case 'error':
        return Icons.CloseSquare;
    }
  }, [type]);

  return (
    <StyledAlert data-testid={dataTestId} color={alertColor}>
      <div className="alert-start">
        {showIcon && <Icon className="alert-icon" icon={alertIcon} />}
        <div data-testid="alert-message" className="alert-message">
          {message}
        </div>
      </div>

      <div className="alert-end">
        {action && (
          <div data-testid="alert-action" className="alert-action">
            {action}
          </div>
        )}
        {closable && (
          <>
            {closeText ? (
              <Button onClick={onClose} data-testid="alert-close" className="alert-close alert-close-text" type="link">
                {closeText}
              </Button>
            ) : (
              <Button
                onClick={onClose}
                data-testid="alert-close"
                className="alert-close alert-close-x"
                type="text"
                icon={<Icon icon={Icons.Close} />}
              />
            )}
          </>
        )}
      </div>
    </StyledAlert>
  );
};
