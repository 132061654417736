import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import {
  OceanFish,
  OceanFishShadow,
  OceanFloor,
  OceanPlant1,
  OceanPlant2,
  OceanPufferFish
} from 'Components/Styled/OceanAssets';
import { simpleUID } from 'Utils/functional';

const oceanBubbleImgSrc = '/images/ocean_bubble.png';
const oceanOctopusBackImgSrc = '/images/ocean_octupos_back_01.png';
const oceanPufferFishBackImgSrc = '/images/ocean_pufferfish_back_01.png';
const OceanSwordfishBackImgSrc = '/images/ocean_swordfish_back_01.png';
const EmptyComputerScreen01ImgSrc = '/images/empty_computer_screen_01.png';
const EmptyComputerScreen02ImgSrc = '/images/empty_computer_screen_02.png';

const SvgAsBlock = css`
  svg {
    display: block;
    height: 100%;
  }
`;

const floatVer = (y: number) => keyframes`
  from, to {
    transform: translateY(-${y}px);
  }
  50% {
    transform: translateY(${y}px);
  }
`;

const floatHor = (x: number) => keyframes`
  from, to {
    transform: translateX(-${x}px);
  }
  50% {
    transform: translateX(${x}px);
  }
`;

const plantRotate = keyframes`
  from, to {
    transform: rotate(15deg) ;
  }
  50% {
    transform: rotate(-15deg) ;
  }
`;

const bubbleUp = (y: string) => keyframes`
  from {
    transform: translateY(${y}) ;
  }
  to {
    transform: translateY(-30px) ;
  }
`;

const bubbleRotate = keyframes`
  from {
    transform: rotate(0) ;
  }
  to {
    transform: rotate(360deg) ;
  }
`;

const wavesClipPath = keyframes`
  0%, 100% {
    clip-path: polygon(0% 76%, 8% 85%, 21% 89%, 32% 88%, 43% 84%, 57% 77%, 69% 71%, 82% 67%, 94% 68%, 100% 72%, 100% 0%, 0% 0%);
  }

  50% {
    clip-path: polygon(0% 77%, 9% 75%, 20% 76%, 27% 78%, 41% 84%, 54% 88%, 71% 90%, 84% 87%, 93% 83%, 99% 81%, 100% 0%, 0% 0%);
  }
`;

//
// floatin fish
//
const StyledFloatingFish = styled.div<{ floatdis?: number; random1: number; random2: number }>`
  ${SvgAsBlock};
  .fish-inner-1,
  .fish-inner-2 {
    height: 100%;
  }
  .fish-inner-1 {
    animation: ${({ floatdis }) => floatVer(floatdis || 15)} 5s ease-in-out infinite;
    animation-delay: ${({ floatdis, random1 }) => (floatdis ? `${-(floatdis * random1)}s` : 0)};
  }
  .fish-inner-2 {
    animation: ${({ floatdis }) => floatHor(floatdis || 15)} 8s ease-in-out infinite;
    animation-delay: ${({ floatdis, random2 }) => (floatdis ? `${-(floatdis * random2)}s` : 0)};
  }
`;

export const FloatingFish: React.FC<{ className?: string; floatDis?: number; children: ReactNode }> = ({
  className,
  floatDis,
  children
}) => {
  const [random1] = useState<number>(Math.random());
  const [random2] = useState<number>(Math.random());
  return (
    <StyledFloatingFish className={className} floatdis={floatDis} random1={random1} random2={random2}>
      <div className="fish-inner-1">
        <div className="fish-inner-2">{children}</div>
      </div>
    </StyledFloatingFish>
  );
};

//
// ocean floor
//
const StyledOceanFloorAndPlants = styled.div`
  ${SvgAsBlock};
  width: 80vw;
  position: absolute;
  bottom: 0;
  left: 0;
  .ocean-plant1,
  .ocean-plant2 {
    position: absolute;
    height: 15vh;
    min-height: 100px;
    max-height: 160px;
    transform-origin: center bottom;
    animation: ${plantRotate} 14s ease-in-out infinite;
  }

  .ocean-plant1 {
    bottom: 2vw;
    right: 20vw;
    animation-delay: ${-Math.random() * 14}s;
  }

  .ocean-plant2 {
    bottom: 3vw;
    left: 40px;
    animation-delay: ${-Math.random() * 14}s;
  }
`;

//
// bubbles
//
export const OceanFloorAndPlants: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <StyledOceanFloorAndPlants className={className}>
      <OceanFloor />
      <div className="ocean-plant1">
        <OceanPlant1 />
      </div>
      <div className="ocean-plant2">
        <OceanPlant2 />
      </div>
    </StyledOceanFloorAndPlants>
  );
};

const StyledBubble = styled.div<{
  size: number;
  x: number;
  duration: number;
  delay: number;
  startY: string;
  direction: 'reverse' | 'normal';
  zIndex: number;
}>`
  width: ${({ size }) => size}px;
  position: absolute;
  top: 0;
  left: ${({ x }) => x}px;
  animation: ${({ startY }) => bubbleUp(startY)} linear infinite;
  animation-duration: ${({ duration }) => duration}s;
  animation-delay: ${({ delay }) => `${delay}s`};
  z-index: ${({ zIndex }) => zIndex};
  .bubble-inner-1 {
    animation: ${floatHor(15)} ease-in-out infinite;
    animation-duration: ${({ duration }) => duration}s;
    animation-delay: ${({ delay }) => `${delay / 4}s`};
  }
  .bubble-inner-2 {
    animation: ${bubbleRotate} linear infinite;
    animation-direction: ${({ direction }) => direction};
    animation-duration: ${({ duration }) => duration / 2}s;
    animation-delay: ${({ delay }) => `${delay / 2}s`};
  }
  img {
    display: block;
    width: 100%;
  }
`;

export interface OceanBubblesProps {
  numberOfBubbles: number;
  size?: number;
  maxBubbleSize?: number;
  startY?: number; // value px
}

export const OceanBubbles: React.FC<OceanBubblesProps> = ({
  numberOfBubbles,
  size = 0,
  maxBubbleSize = 30,
  startY
}) => {
  const [bubbles, setBubbles] = useState<
    {
      id: string;
      size: number;
      x: number;
      duration: number;
      delay: number;
      direction: 'reverse' | 'normal';
      zIndex: number;
    }[]
  >([]);

  useEffect(() => {
    if (size && size > 0) {
      setBubbles(
        [...Array(numberOfBubbles)].map(() => {
          const bubbleSize = Math.random() * (maxBubbleSize / 2) + maxBubbleSize;
          const duration = startY ? Math.random() * (startY / 30) + startY / 30 : Math.random() * 15 + 25;
          return {
            id: simpleUID(),
            size: bubbleSize,
            duration,
            delay: -Math.random() * duration,
            x: Math.random() * (size - bubbleSize),
            direction: Math.random() > 0.5 ? 'reverse' : 'normal',
            zIndex: Math.random() > 0.5 ? 10 : 0
          };
        })
      );
    }
  }, [size, numberOfBubbles, maxBubbleSize, startY]);

  const bubblesNodes = useMemo(
    (): ReactNode[] =>
      bubbles.map((b) => {
        return (
          <StyledBubble
            className="bubble"
            key={b.id}
            size={b.size}
            x={b.x}
            duration={b.duration}
            delay={b.delay}
            startY={startY ? `${startY}px` : '100vh'}
            zIndex={b.zIndex}
            direction={b.direction}
          >
            <div className="bubble-inner-1">
              <div className="bubble-inner-2">
                <img src={oceanBubbleImgSrc} alt="ocean bubble" />
              </div>
            </div>
          </StyledBubble>
        );
      }),
    [bubbles, startY]
  );

  return <>{bubblesNodes}</>;
};

//
// puffer fish swimming
//
const StyledPufferFishSwimming = styled.div`
  position: relative;
  width: 120px;
  .fish-inner-1 {
    position: relative;
  }
  .fish-inner-2 {
    animation: ${floatVer(5)} 5s ease-in-out infinite;
  }
  .not-puffed,
  .puffed {
    transition:
      transform 0.05s ease-out,
      opacity 0.05s ease-out;
  }
  .not-puffed {
    opacity: 1;
  }
  .puffed {
    position: absolute;
    left: 0;
    top: -16px;
    opacity: 0;
    transform: scaleY(50%);
  }
  .fish-shadow {
    pointer-events: none;
    z-index: -1;
    left: 0;
    width: 110px;
    opacity: 0.25;
  }
  .puffer-fish-swimming-bubbles {
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 130px;
    height: 80px;
    left: -10px;
  }
  &:hover {
    .not-puffed {
      opacity: 0;
      transform: scaleY(150%);
    }
    .puffed {
      transform: scaleY(100%);
      opacity: 1;
    }
  }
`;

export const PufferFishSwimming: React.FC = () => {
  return (
    <StyledPufferFishSwimming className="puffer-fish-swimming">
      <div className="fish-inner-1">
        <div className="fish-inner-2">
          <OceanFish className="not-puffed" />
          <OceanPufferFish className="puffed" />
        </div>
      </div>
      <OceanFishShadow className="fish-shadow" />
      <div className="puffer-fish-swimming-bubbles">
        <OceanBubbles numberOfBubbles={3} size={150} maxBubbleSize={8} startY={80} />
      </div>
    </StyledPufferFishSwimming>
  );
};

//
// Fish in water
//
const StyledFishInWater = styled.div`
  position: relative;
  width: 100px;
  animation: ${wavesClipPath} 2s ease-in-out infinite;
  .circle {
    position: absolute;
    background-color: #fbecef;
    width: 80%;
    height: 100%;
    border-radius: 100%;
    top: 20%;
    left: 10%;
  }
  .fish {
    position: relative;
    transform: translateY(30%) scale(0.8);
  }
  .fish-inner-1 {
    animation: ${floatVer(5)} 5s ease-in-out infinite;
  }
  .fish-inner-2 {
    transform: rotate(-45deg);
  }
`;

export const FishInWater: React.FC = () => {
  return (
    <StyledFishInWater>
      <div className="circle" />
      <div className="fish">
        <div className="fish-inner-1">
          <div className="fish-inner-2">
            <OceanFish />
          </div>
        </div>
      </div>
    </StyledFishInWater>
  );
};

const StyledThreeFishAndScreen = styled.div`
  position: relative;
  width: 400px;
  height: 250px;
  .screen {
    width: 210px;
    position: absolute;
    top: 0;
    left: 100px;
    z-index: 1;
  }
  .ocean-item {
    position: absolute;
    &.octopus {
      width: 162px;
      top: 40px;
      left: 0;
      z-index: 2;
    }
    &.puffer-fish {
      width: 170px;
      top: 100px;
      left: 150px;
      z-index: 3;
    }
    &.sword-fish {
      width: 155px;
      top: 30px;
      left: 240px;
      z-index: 2;
    }
  }
  img {
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

export const ThreeFishAndScreen: React.FC<{ screenType: 'mail' | 'graphs' }> = ({ screenType }) => {
  return (
    <StyledThreeFishAndScreen className="three-fish-screen">
      {screenType === 'mail' && (
        <div className="screen">
          <img src={EmptyComputerScreen01ImgSrc} alt="computer screen illistration" />
        </div>
      )}
      {screenType === 'graphs' && (
        <div className="screen">
          <img src={EmptyComputerScreen02ImgSrc} alt="computer screen illistration" />
        </div>
      )}
      <FloatingFish className="ocean-item octopus" floatDis={5}>
        <img src={oceanOctopusBackImgSrc} alt="octopus illistration" />
      </FloatingFish>
      <FloatingFish className="ocean-item puffer-fish" floatDis={5}>
        <img src={oceanPufferFishBackImgSrc} alt="pufferfish illistration" />
      </FloatingFish>
      <FloatingFish className="ocean-item sword-fish" floatDis={5}>
        <img src={OceanSwordfishBackImgSrc} alt="sword fish illistration" />
      </FloatingFish>

      <OceanBubbles numberOfBubbles={6} size={350} startY={240} maxBubbleSize={12} />
    </StyledThreeFishAndScreen>
  );
};
