import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Drawer as RefDrawer } from 'antd';
import { Button } from 'Components/Primitives/Button/Button';
import { Icon } from 'Components/Primitives/Icon/Icon';
import { Icons } from 'Components/Primitives/Icon/Icon.types';
import { borderRadius } from 'Constants/Styles';

export interface DrawerProps {
  title: ReactNode;
  open: boolean;
  size: 'small' | 'middle' | 'large';
  destroyOnClose?: boolean;
  footer?: ReactNode;
  zIndex?: number;
  placement?: 'right' | 'bottom';
  'data-testid'?: string;
  onClose(): void;
  afterOpenChange?(open: boolean): void;
  children: ReactNode;
}

const widthBySize: Record<DrawerProps['size'], number> = {
  small: 480,
  middle: 700,
  large: 900
};

export const Drawer: React.FC<DrawerProps> = ({
  title,
  open,
  size,
  destroyOnClose,
  footer,
  zIndex,
  placement = 'right',
  'data-testid': dataTestId,
  onClose,
  afterOpenChange,
  children
}) => {
  const [windowDim, setWindowDim] = useState<{ w: number; h: number }>({ w: window.innerWidth, h: window.innerHeight });
  const drawerWidth = useMemo(
    (): number | undefined => (placement === 'right' ? Math.min(widthBySize[size], windowDim.w) : undefined),
    [size, placement, windowDim]
  );
  const drawerHeight = useMemo(
    (): number | undefined => (placement === 'bottom' ? windowDim.h - 80 : undefined),
    [placement, windowDim]
  );

  const updateWindowDim = useCallback(() => {
    setWindowDim({ w: window.innerWidth, h: window.innerHeight });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateWindowDim);
    updateWindowDim();
    return () => {
      window.removeEventListener('resize', updateWindowDim);
    };
  }, [updateWindowDim]);

  return (
    <RefDrawer
      closable={false}
      data-testid={dataTestId}
      title={title}
      width={drawerWidth}
      height={drawerHeight}
      onClose={onClose}
      afterOpenChange={afterOpenChange}
      open={open}
      destroyOnClose={destroyOnClose}
      zIndex={zIndex || 500}
      placement={placement}
      style={
        placement === 'bottom' ? { borderTopRightRadius: borderRadius, borderTopLeftRadius: borderRadius } : undefined
      }
      extra={
        <Button data-testid="drawer-close-button" type="text" onClick={onClose} icon={<Icon icon={Icons.Close} />} />
      }
      footer={footer}
    >
      {children}
    </RefDrawer>
  );
};
