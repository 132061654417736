import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { DateSplitToWords, getDateAsWords } from 'Utils/date';
import { Colors, times8 } from 'Constants/Styles';

export interface DateAsWordsProps {
  date: string;
}

const StyledDateAsWords = styled.div`
  line-height: 1.1;
  .date {
    font-size: ${times8(4)}px;
    font-weight: 600;
  }
  .month-year {
    color: ${Colors.SubText};
  }
`;

export const DateAsWords: React.FC<DateAsWordsProps> = ({ date }) => {
  const dateAsWords = useMemo((): DateSplitToWords => getDateAsWords(date), [date]);

  return (
    <StyledDateAsWords className="date-wrapper">
      <div className="day">{dateAsWords.day}</div>
      <div className="date">{dateAsWords.date}</div>
      <div className="month-year">
        {dateAsWords.month} {dateAsWords.year}
      </div>
    </StyledDateAsWords>
  );
};
