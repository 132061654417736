import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Colors } from 'Constants/Styles';
import { Avatar } from 'Components/Primitives/Avatar/Avatar';
import { Tooltip } from 'Components/Primitives/Tooltip/Tooltip';
import { AvatarAndTitleProps, AvatarSize } from 'Components/Primitives/Avatar/Avatar.types';
import { Highlight } from 'Components/Primitives/Highlight/Highlight';

const StyledAvatarContent = styled.div<{ noWrap: boolean | undefined }>`
  .avatar-and-title-content {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
  }

  .avatar-and-title-texts {
    margin-left: 8px;
    > .avatar-and-title-title {
      font-size: inherit;
      font-weight: normal;
    }
    > .avatar-and-title-subtitle {
      line-height: 1.3;
    }
    * {
      white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'normal')};
    }
  }
  &.size-small {
    .avatar-and-title-texts {
      margin-left: 4px;
    }
  }
  .avatar-and-title-title {
    &.size-xLarge {
      font-size: 24px;
      font-weight: 500;
    }
  }

  a {
    color: initial;
    cursor: default;
    &:hover {
      text-decoration: none;
    }
    .avatar-and-title-title {
      cursor: pointer;
      color: ${Colors.Primary};
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const AvatarText: React.FC<Pick<AvatarAndTitleProps, 'title' | 'titleSuffix' | 'subtitle' | 'size' | 'highlight'>> = ({
  title,
  titleSuffix,
  subtitle,
  size,
  highlight
}) => {
  return (
    <>
      <h5 className={`avatar-and-title-title size-${size}`} data-testid="avatar-and-title-title">
        {highlight ? <Highlight text={title || null} wholeWordOnly={false} highlight={highlight} /> : title}
        {titleSuffix && <> {titleSuffix}</>}
      </h5>
      {subtitle && (
        <div className="avatar-and-title-subtitle" data-testid="avatar-and-title-subtitle">
          {subtitle}
        </div>
      )}
    </>
  );
};

interface AvatarContentProps extends AvatarAndTitleProps {
  size: AvatarSize;
}

const AvatarContent: React.FC<AvatarContentProps> = ({
  avatarSrc,
  title,
  titleSuffix,
  subtitle,
  size,
  tooltip,
  badge,
  highlight
}) => {
  return (
    <Tooltip title={tooltip}>
      <div className="avatar-and-title-content">
        <Avatar data-testid="avatar-and-title-avatar" src={avatarSrc} title={title} size={size} badge={badge} />
        {title && (
          <div className="avatar-and-title-texts">
            <AvatarText title={title} titleSuffix={titleSuffix} subtitle={subtitle} size={size} highlight={highlight} />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export const AvatarAndTitle: React.FC<AvatarAndTitleProps> = ({
  avatarSrc,
  title,
  titleSuffix,
  subtitle,
  linkTo,
  tooltip,
  className,
  noWrap,
  size = 'large',
  badge,
  highlight,
  'data-testid': dataTestId
}) => {
  return (
    <StyledAvatarContent
      noWrap={noWrap}
      data-testid={dataTestId || 'avatar-and-title'}
      className={classNames('avatar-and-title', `size-${size}`, className)}
    >
      {linkTo ? (
        <Link to={linkTo}>
          <AvatarContent
            avatarSrc={avatarSrc}
            title={title}
            titleSuffix={titleSuffix}
            subtitle={subtitle}
            size={size}
            tooltip={tooltip}
            badge={badge}
            highlight={highlight}
          />
        </Link>
      ) : (
        <AvatarContent
          avatarSrc={avatarSrc}
          title={title}
          titleSuffix={titleSuffix}
          subtitle={subtitle}
          size={size}
          tooltip={tooltip}
          badge={badge}
          highlight={highlight}
        />
      )}
    </StyledAvatarContent>
  );
};
