import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'Components/Primitives/Form/Form';
import { Input } from 'Components/Primitives/Input/Input';
import { Modal } from 'Components/Primitives/Modal/Modal';

interface EditTermModalProps {
  term: string | null;
  isOpen: boolean;
  onEdit(oldTerm: string, newTerm: string): void;
  onClose(): void;
}

const EDIT_TERM_FORM_ID = 'editTermForm';

const EditTermModal: React.FC<EditTermModalProps> = ({ term, onEdit, onClose }) => {
  const { t } = useTranslation(['common']);
  const [form] = Form.useForm<{ term: string }>();

  const onFormSubmit = useCallback((formData: { term: string }) => term && onEdit(term, formData.term), [term, onEdit]);

  useEffect(() => {
    if (form && term) {
      form.setFieldsValue({ term });
    }
  }, [form, term]);

  return (
    <Modal
      data-testid="edit-term-modal"
      isOpen={term !== null}
      size="small"
      onClose={onClose}
      title={t('terms-input.edit-modal.title')}
      buttonConfig={{
        ok: {
          label: t('update'),
          dataTestId: 'edit-term-submit',
          buttonProps: {
            htmlType: 'submit',
            form: EDIT_TERM_FORM_ID
          }
        },
        cancel: {
          label: t('cancel')
        }
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFormSubmit} id={EDIT_TERM_FORM_ID}>
        <Form.Item
          name="term"
          label={'Term'}
          rules={[
            {
              required: true,
              message: t('common:required-field', {
                fieldName: t('terms-input.edit-modal.input-label')
              })
            }
          ]}
        >
          <Input data-testid="edit-term-input" placeholder={t('terms-input.edit-modal.input-placeholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditTermModal;
