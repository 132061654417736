import { FieldAggregationType, FieldFormatterType, FieldMetadata, FieldType } from 'Types/Fields.types';
import { ReportColumnType } from 'Types/Reports.types';
import { translate } from 'Utils/translate';

export const fieldNameByFieldType: Record<FieldType, string> = {
  [FieldType.String]: translate('common:fields.types.string'),
  [FieldType.Number]: translate('common:fields.types.number'),
  [FieldType.Boolean]: translate('common:fields.types.boolean'),
  [FieldType.Date]: translate('common:fields.types.date'),
  [FieldType.DateTime]: translate('common:fields.types.date-time'),
  [FieldType.UserReference]: translate('common:fields.types.user-reference'),
  [FieldType.List]: translate('common:fields.types.list')
};

export const fieldTypeToReportColumnType: Record<FieldType, ReportColumnType> = {
  [FieldType.String]: ReportColumnType.String,
  [FieldType.Number]: ReportColumnType.Number,
  [FieldType.Boolean]: ReportColumnType.Boolean,
  [FieldType.Date]: ReportColumnType.Date,
  [FieldType.DateTime]: ReportColumnType.DateTime,
  [FieldType.UserReference]: ReportColumnType.UserReference,
  [FieldType.List]: ReportColumnType.List
};

export const fieldAggregationLabelByType: Record<FieldAggregationType, string> = {
  [FieldAggregationType.None]: translate('common:fields.aggregation.none'),
  [FieldAggregationType.Sum]: translate('common:fields.aggregation.sum'),
  [FieldAggregationType.Avg]: translate('common:fields.aggregation.average')
};

export const fieldFormatterLabelByType: Record<FieldFormatterType, string> = {
  [FieldFormatterType.Currency]: translate('common:fields.formatter.currency'),
  [FieldFormatterType.Percentage]: translate('common:fields.formatter.percentage'),
  [FieldFormatterType.Url]: translate('common:fields.formatter.url')
};

export const getFieldTypeTableCellValue = (fieldType: FieldType, metadata: FieldMetadata): string =>
  fieldType === FieldType.Number
    ? `${fieldNameByFieldType[fieldType]}${metadata.formatter !== null ? ` / ${fieldFormatterLabelByType[metadata.formatter]}` : ''}`
    : fieldNameByFieldType[fieldType];

export const isMetricOverTimeField = (fieldMetadata: FieldMetadata): boolean =>
  fieldMetadata.objectType === 'Customer' &&
  fieldMetadata.definitionType === 'Custom' &&
  [FieldAggregationType.Avg, FieldAggregationType.Sum].includes(fieldMetadata.aggregation);

export const isNumericCustomField = (fieldMetadata: FieldMetadata): boolean =>
  fieldMetadata.objectType === 'Customer' &&
  fieldMetadata.definitionType === 'Custom' &&
  fieldMetadata.fieldType === FieldType.Number;
