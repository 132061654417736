import * as React from "react";
const SvgUserAdd = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "user-add", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 426.132 648.756 C 263.455 648.756 124.519 673.344 124.519 771.862 C 124.519 870.385 262.607 895.862 426.132 895.862 C 588.853 895.862 727.748 871.233 727.748 772.753 C 727.748 674.274 589.698 648.756 426.132 648.756 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 426.132 508.239 C 532.906 508.239 619.448 421.694 619.448 314.924 C 619.448 208.15 532.906 121.606 426.132 121.606 C 319.403 121.606 232.86 208.15 232.86 314.924 C 232.476 421.316 318.388 507.86 424.822 508.239 L 426.132 508.239 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { d: "M 820.854 372.106 L 820.854 541.807", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { d: "M 907.438 456.955 L 734.351 456.955", strokeLinecap: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }));
export default SvgUserAdd;
