import React, { useCallback, useMemo } from 'react';
import { formatDate } from 'Utils/date';
import { Icon } from 'Components/Primitives/Icon/Icon';
import { Icons } from 'Components/Primitives/Icon/Icon.types';
import { DatePicker as DatePickerRef, DatePickerProps as DatePickerPropsRef } from 'antd';
import { RangePickerProps as RangePickerPropsRef } from 'antd/es/date-picker';
import { isDate } from 'date-fns';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

declare type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;
export declare type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;
const { RangePicker: RangePickerRef } = DatePickerRef;

type ShowTime = { showHour: boolean; showMinute: boolean; showSecond: boolean; minuteStep: IntRange<1, 59> };
const SHOW_PICKER_TIME_PROPS: ShowTime = { showHour: true, showMinute: true, showSecond: false, minuteStep: 10 };
interface DatePickerProps
  extends Omit<DatePickerPropsRef, 'value' | 'showTime' | 'onChange' | 'disabledDate' | 'picker'> {
  value?: Date;
  showTime?: ShowTime;
  disabledDate?(current: Date | undefined): boolean;
  onChange?(d: Date | null): void;
}

//
// format function
const formatDateFromDayjs = (d: Dayjs, showTime?: boolean) => formatDate(d.toDate(), !!showTime);

//
// Date picket component
//
const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { onChange, disabledDate, ...componentProps } = props;

  const v = useMemo(
    (): Dayjs | undefined => (props.value && isDate(props.value) ? dayjs(props.value) : undefined),
    [props.value]
  );

  const disableDatePickerDate = useCallback(
    (e: Dayjs | null): boolean => (disabledDate && e ? disabledDate(e.toDate()) : false),
    [disabledDate]
  );

  const onDatePickerChange = useCallback(
    (e: Dayjs | null) => {
      onChange && onChange(e ? e.toDate() : e);
    },
    [onChange]
  );

  return (
    <DatePickerRef
      {...componentProps}
      value={v}
      format={(d) => formatDateFromDayjs(d, !!props.showTime)}
      suffixIcon={<Icon icon={Icons.Calendar} />}
      onChange={onDatePickerChange}
      disabledDate={disabledDate ? disableDatePickerDate : undefined}
    />
  );
};

//
// Range picker component
//
type RangeValue = [Date | null, Date | null] | null;
interface RangePickerProps extends Omit<RangePickerPropsRef, 'value' | 'showTime' | 'onChange'> {
  value?: [Date, Date];
  showTime?: ShowTime;
  onChange?(d: RangeValue | null): void;
}
const RangePicker: React.FC<RangePickerProps> = (props) => {
  const { onChange, ...componentProps } = props;

  const v = useMemo(
    (): [Dayjs, Dayjs] | undefined =>
      props.value && Array.isArray(props.value) && isDate(props.value[0]) && isDate(props.value[1])
        ? [dayjs(props.value[0]), dayjs(props.value[1])]
        : undefined,
    [props.value]
  );

  const onDateRangeChange = useCallback(
    (e: [Dayjs | null, Dayjs | null] | null) => {
      const d1 = (Array.isArray(e) && e[0] && e[0].toDate()) || null;
      const d2 = (Array.isArray(e) && e[1] && e[1].toDate()) || null;
      onChange && onChange([d1, d2]);
    },
    [onChange]
  );

  return (
    <RangePickerRef
      {...componentProps}
      value={v}
      format={(d) => formatDateFromDayjs(d, !!props.showTime)}
      suffixIcon={<Icon icon={Icons.Calendar} />}
      onChange={(e) => onDateRangeChange(e)}
    />
  );
};

export { DatePicker, RangePicker, SHOW_PICKER_TIME_PROPS };
export type { RangeValue, DatePickerProps, RangePickerProps };
