import { Key } from 'react';
import { TreeNode } from 'Components/Primitives/Tree/Tree.types';
import { Highlight } from 'Components/Primitives/Highlight/Highlight';

export interface TreeNodeWithKey extends TreeNode {
  key: Key;
  children: TreeNodeWithKey[];
}

export const getTreeNodesWithKey = (nodes: TreeNode[], searchVal = ''): TreeNodeWithKey[] => {
  return nodes
    .filter((node) => containsSearchVal(node, searchVal))
    .map((node) => ({
      ...node,
      key: node.value,
      title:
        searchVal.length > 0 && typeof node.title === 'string' ? (
          <Highlight text={node.title} wholeWordOnly={false} highlight={[searchVal]} />
        ) : (
          node.title
        ),
      children: getTreeNodesWithKey(node.children, searchVal)
    }));
};

export const getTreeNodeKeys = (nodes: TreeNodeWithKey[]): Key[] => {
  return nodes.reduce<Key[]>((acc, node) => {
    acc.push(node.key);
    if (node.children.length) {
      acc.push(...getTreeNodeKeys(node.children));
    }
    return acc;
  }, []);
};

const containsSearchVal = (node: TreeNode, searchVal: string): boolean => {
  return (
    node.searchValue.toLowerCase().includes(searchVal.toLowerCase()) ||
    node.children.some((child) => containsSearchVal(child, searchVal))
  );
};

type TreeNodeMap = Record<string, { node: TreeNode; selectableChildrenIds: Key[] }>;
export const getTreeNodesMapByValue = (nodes: TreeNode[]): TreeNodeMap =>
  nodes.reduce((acc: TreeNodeMap, node) => {
    const selectableChildrenIds = getTreeSelectabledChildrenIds([node]);
    acc[node.value] = { node, selectableChildrenIds };
    if (node.children.length) {
      acc = { ...acc, ...getTreeNodesMapByValue(node.children) };
    }
    return acc;
  }, {});

export const getTreeSelectabledChildrenIds = (nodes: TreeNode[]): Key[] =>
  nodes.reduce((acc: Key[], node) => {
    if (node.children.length) {
      acc.push(...getTreeSelectabledChildrenIds(node.children));
    }
    node.selectable && acc.push(node.value);
    return acc;
  }, []);

// export const getFlatTreeNodeIds = (nodes: TreeNode[]): Key[] =>
//   nodes.reduce((acc: Key[], node) => {
//     if (node.children.length) {
//       acc.push(...getFlatTreeNodeIds(node.children));
//     }
//     acc.push(node.value);
//     return acc;
//   }, []);
