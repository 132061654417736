import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Avatar } from 'Components/Primitives/Avatar/Avatar';
import uniqBy from 'lodash/uniqBy';
import { ColorPalette, Colors, hexAlpha20 } from 'Constants/Styles';
import { StyledMoreNumber } from 'Components/Styled/PlusMore';
import { AvatarSize, ShowcaseAvatar } from 'Components/Primitives/Avatar/Avatar.types';
import { AvatarSizeToPx } from 'Components/Primitives/Avatar/AvatarUtils';
import { AvatarDropDown } from 'Components/Primitives/Avatar/AvatarDropDown';

export interface AvatarShowcaseProps {
  size: AvatarSize;
  avatarList: ShowcaseAvatar[];
  maxAvatarsToShow?: number;
  showDropdown?: boolean;
  className?: string;
}

const StyledAvatarShowcase = styled.ul<{ size: AvatarSize; showDropdown: boolean }>`
  display: inline-block;
  cursor: ${({ showDropdown }) => (showDropdown ? 'pointer' : 'auto')};
  .showcase-avatar .avatar-content,
  .more-avatar {
    outline: 1px solid ${Colors.SubText}${hexAlpha20};
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
  }
  .showcase-avatar:not(:first-of-type) {
    margin-left: -${({ size }) => marginOffsetBySize[size]}px;
  }
  .more-avatar {
    display: inline-block;
    position: relative;
    text-align: center;
    padding: 0 6px;
    min-width: ${({ size }) => AvatarSizeToPx[size]}px;
    color: ${ColorPalette.Gray800};
    font-size: ${({ size }) => moreAvatarFontSize[size]};
    vertical-align: middle;
    white-space: nowrap;
    flex-shrink: 0;
    font-weight: normal;
  }
`;

const MORE_AVATARS_KEY = 'more-avatars';

const marginOffsetBySize = {
  small: 4,
  middle: 6,
  large: 12,
  xLarge: 24
};

const moreAvatarFontSize = {
  small: '10px',
  middle: '12px',
  large: '14px',
  xLarge: '16px'
};

const getMoreAvatar = (num: number): ShowcaseAvatar => ({
  key: MORE_AVATARS_KEY,
  title: `+${num}`,
  avatarSrc: undefined
});

export const AvatarShowcase: React.FC<AvatarShowcaseProps> = ({
  size,
  avatarList: avatarListBase,
  maxAvatarsToShow,
  showDropdown = true,
  className
}) => {
  const avatarList = useMemo((): ShowcaseAvatar[] => {
    return uniqBy(avatarListBase, 'key');
  }, [avatarListBase]);

  const avatarShowcaseList = useMemo(
    (): ShowcaseAvatar[] =>
      maxAvatarsToShow && maxAvatarsToShow > 1
        ? avatarList.length > maxAvatarsToShow
          ? [...avatarList]
              .splice(0, maxAvatarsToShow - 1)
              .concat([getMoreAvatar(avatarList.length - maxAvatarsToShow + 1)])
          : avatarList
        : avatarList,
    [avatarList, maxAvatarsToShow]
  );

  return (
    <AvatarDropDown avatarList={avatarList} showDropdown={showDropdown}>
      <StyledAvatarShowcase
        data-testid="avatar-showcase-trigger"
        size={size}
        showDropdown={showDropdown}
        className={className}
      >
        {avatarShowcaseList.map((avatar) =>
          avatar.key === MORE_AVATARS_KEY ? (
            <StyledMoreNumber
              height={AvatarSizeToPx[size]}
              data-testid="showcase-avatar-more-indicator"
              key={avatar.key}
              className="showcase-avatar more-avatar"
            >
              {avatar.title}
            </StyledMoreNumber>
          ) : (
            <Avatar
              className="showcase-avatar"
              data-testid="showcase-avatar"
              key={avatar.key}
              size={size}
              title={avatar.title}
              src={avatar.avatarSrc}
            />
          )
        )}
      </StyledAvatarShowcase>
    </AvatarDropDown>
  );
};
