import * as React from "react";
const SvgSave = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "save", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("defs", null), /* @__PURE__ */ React.createElement("path", { d: "M 511.996 110.573 L 511.996 631.142", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentcolor",
  strokeWidth: 70,
  transformBox: "fill-box",
  transformOrigin: "50% 50%"
}, transform: "matrix(-1.000001, 0, 0, -1, -0.000283, 0.000157)" }), /* @__PURE__ */ React.createElement("path", { d: "M 678.18 647.012 L 512.014 444.862 L 345.821 647.012", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentcolor",
  strokeWidth: 70,
  transformBox: "fill-box",
  transformOrigin: "50% 50%"
}, transform: "matrix(-1, 0, 0, -1, 0.000206, -0.000058)" }), /* @__PURE__ */ React.createElement("path", { d: "M 677.221 146.198 C 815.812 209.1 912.2 348.698 912.2 510.808 C 912.2 731.832 733.024 911.008 512 911.008 C 290.976 911.008 111.8 731.832 111.8 510.808 C 111.8 348.698 208.187 209.101 346.777 146.199", style: {
  stroke: "currentcolor",
  strokeWidth: 70,
  fill: "none",
  strokeLinecap: "round"
} }));
export default SvgSave;
