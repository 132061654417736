import * as React from "react";
const SvgStairsLevel = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "stairs-level", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 657.116 702.785 L 835.898 509.296 L 646.279 323.545", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 90
} }), /* @__PURE__ */ React.createElement("path", { d: "M 188.102 321.215 L 422.61 321.215 L 422.61 511.608 L 758.509 511.608", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 90
} }));
export default SvgStairsLevel;
