import { useEffect, useMemo, useState } from 'react';
import { useStakeholdersEssentialsQuery } from 'hooks/QueryHooks/useStakeholdersQuery';
import { StakeholderEssentials } from 'Types/Stakeholder.types';

export type StakeholdersMap = Map<number, StakeholderEssentials>;

export const useStakeholdersMap = (customerId: number): StakeholdersMap => {
  const setLoading = useState<boolean>()[1];
  const stakeholdersMapByCustomerId: Map<number, StakeholdersMap> = useMemo(() => new Map(), []);
  const { data } = useStakeholdersEssentialsQuery({ customerId });

  useEffect(() => {
    if (!data) {
      setLoading(true);
      if (!stakeholdersMapByCustomerId.has(customerId)) {
        stakeholdersMapByCustomerId.set(customerId, new Map());
      }
      return;
    }
    stakeholdersMapByCustomerId.set(
      customerId,
      new Map(data.data.map((stakeholder) => [stakeholder.stakeholder.value, stakeholder]))
    );
    setLoading(false);
  }, [data, stakeholdersMapByCustomerId, customerId, setLoading]);

  return stakeholdersMapByCustomerId.get(customerId) || new Map();
};
