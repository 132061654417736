import { css } from '@emotion/react';

export const CardHover = css`
  transition:
    transform 0.1s ease-out,
    box-shadow 0.1s ease-out;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0);
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 6px 8px 2px rgba(0, 0, 0, 0.1);
  }
`;
