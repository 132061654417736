import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Slider as RefSlider } from 'antd';
import classNames from 'classnames';
import { ProgressProps } from 'Components/Primitives/Progress/Progress';
import { lineSizeByPropSize } from 'Components/Primitives/Progress/Progress.consts';
import styled from '@emotion/styled';
import { ColorPalette, Colors, times8 } from 'Constants/Styles';
import { isNumber } from 'Utils/functional';
import { NO_VALUE } from 'Constants/Chars.consts';
import { InputNumber } from 'Components/Primitives/Input/Input';

export interface SliderProps {
  value?: number | null;
  range?: [number, number];
  step?: number;
  progColor?: string;
  size: ProgressProps['size'];
  showLabel?: ProgressProps['showLabel'];
  labelSuffix?: string;
  className?: string;
  'data-testid'?: string;
  onChange?(v: number): void;
}

const StyledSlider = styled.div<{ size: number; progColor: string }>`
  display: flex;
  align-items: center;
  .slider-ref {
    width: ${({ size }) => size}px;
    padding-block: 0;
    height: 6px;
    margin: 0;
    .ant-slider-rail {
      border-radius: 3px;
    }
    .ant-slider-handle {
      background-color: white;
      border-radius: 100%;
      border: 2px solid ${Colors.Primary};
      width: 14px;
      height: 14px;
      inset-block-start: -4px;
      &:before {
        content: none;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        top: 2px;
        left: 2px;
        background-color: ${Colors.Primary};
        box-shadow: none;
        border-radius: 100%;
      }
    }
    .ant-slider-track {
      background-color: ${({ progColor }) => progColor};
      border-radius: 3px;
    }
    &:hover {
      .ant-slider-track {
        background-color: ${({ progColor }) => progColor};
      }
    }
  }

  &.no-value {
    .ant-slider-rail {
      background-color: ${ColorPalette.Gray300};
    }
    .ant-slider-track {
      opacity: 0;
    }
    .slider-ref .ant-slider-handle {
      border-color: ${ColorPalette.Gray300};
      &:after {
        background-color: ${ColorPalette.Gray300};
      }
    }
  }

  .label {
    margin-left: ${times8()}px;
  }

  .slider-number-input {
    display: inline-block;
    width: 28px;
    border-radius: 0;
    border-bottom: 1px solid transparent;
    margin-left: 4px;
    input {
      padding: 0;
      height: auto;
    }
  }
  &:hover .slider-number-input {
    border-bottom: 1px solid ${Colors.SubText};
  }
`;

export const Slider: React.FC<SliderProps> = ({
  value,
  range = [0, 100],
  step = 1,
  progColor = Colors.Primary,
  size,
  showLabel,
  labelSuffix,
  className,
  'data-testid': dataTestId,
  onChange
}) => {
  const [sliderValue, setSliderValue] = useState<number | null>(isNumber(value) ? value : null);
  const lineSize = useMemo((): number => (typeof size === 'number' ? size : lineSizeByPropSize[size]), [size]);

  const onValueChange = useCallback(
    (v: number | null) => {
      if (v === null) {
        setSliderValue(0);
        onChange && onChange(0);
      } else {
        setSliderValue(v);
        onChange && onChange(v);
      }
    },
    [onChange]
  );

  useEffect(() => {
    setSliderValue(isNumber(value) ? value : null);
  }, [value]);

  return (
    <StyledSlider
      data-testid={dataTestId}
      className={classNames(className, sliderValue === null && 'no-value')}
      size={lineSize}
      progColor={progColor}
    >
      <RefSlider
        className="slider-ref"
        value={isNumber(sliderValue) ? sliderValue : (range[1] - range[0]) / 2}
        min={range[0]}
        max={range[1]}
        step={step}
        onChange={onValueChange}
        tooltip={{ formatter: null }}
      />
      {showLabel !== 'never' && (
        <span className="label">
          {isNumber(sliderValue) ? (
            <>
              <InputNumber
                className="slider-number-input"
                value={sliderValue}
                variant="borderless"
                controls={false}
                min={range[0]}
                max={range[1]}
                onChange={onValueChange}
              />
              {labelSuffix && <span className="slider-input-suffix">{labelSuffix}</span>}
            </>
          ) : (
            <>{NO_VALUE}</>
          )}
        </span>
      )}
    </StyledSlider>
  );
};
