import { ReactNode, useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { nonNullable } from 'Utils/functional';
import { Button, Drawer, FormInstance, Icon, Icons } from 'Components/Primitives';
import { FilterFormData, FilterMetadata } from 'Components/Filters/Filter.types';
import { FilterForm } from 'Components/Filters/FilterForm';

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  .footer-done {
    margin-left: auto;
  }
`;

interface FilterDrawerFormAndTagsMobileProps<T> {
  metadata: FilterMetadata[];
  filterData: T;
  resetDisabled: boolean;
  form: FormInstance<T>;
  formId: string;
  onFormChange(formData: T): void;
  onReset?(): void;
}

const FilterDrawerFormAndTagsMobile = <T extends FilterFormData>({
  metadata,
  filterData,
  resetDisabled,
  form,
  formId,
  onReset,
  onFormChange
}: FilterDrawerFormAndTagsMobileProps<T>) => {
  const { t } = useTranslation(['common']);
  const [visible, setVisible] = useState<boolean>(false);

  const filtersCount = useMemo(
    (): number => metadata.filter((fmd) => nonNullable(filterData[fmd.key])).length,
    [metadata, filterData]
  );

  const onDone = useCallback(() => {
    setVisible(false);
  }, []);

  const footer = useMemo((): ReactNode => {
    return (
      <StyledFooter>
        {onReset && (
          <Button className="footer-reset" onClick={onReset} disabled={resetDisabled}>
            {t('filters.reset')}
          </Button>
        )}
        <Button className="footer-done" type="primary" onClick={onDone}>
          {t('done')}
        </Button>
      </StyledFooter>
    );
  }, [t, resetDisabled, onDone, onReset]);

  return (
    <div className="filter-drawer-form-and-tags">
      <Button
        className="open-filter-drawer-button"
        data-testid="open-filter-drawer-button"
        onClick={() => setVisible(true)}
        icon={<Icon icon={Icons.Filter} />}
      >
        {`${t('filters.filter')} (${filtersCount})`}
      </Button>

      <Drawer
        title={<h2>{t('filters.filters')}</h2>}
        size="small"
        onClose={() => setVisible(false)}
        open={visible}
        destroyOnClose={false}
        zIndex={700}
        placement="bottom"
        footer={footer}
      >
        <div className="filter-form">
          <FilterForm
            metadata={metadata}
            formId={formId}
            form={form}
            initialFormData={filterData}
            onChange={onFormChange}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default FilterDrawerFormAndTagsMobile;
