import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckboxSelectDropdown, CheckboxSelectOption } from 'Components/CheckboxSelect';
import styled from '@emotion/styled';
import { Button, Icon, Icons, Tag, TagList } from 'Components/Primitives';
import { times8 } from 'Constants/Styles';

export type QuickFilterValue = { [key: string]: (number | string)[] };
export type QuickFilterConfig = { label: string; options: CheckboxSelectOption[] };

export interface QuickFiltersProps {
  config: { [key: string]: QuickFilterConfig };
  value?: QuickFilterValue;
  onChange?(value: QuickFilterValue): void;
}

const StyledQuickFilters = styled.div`
  .quick-filter-select {
    margin-right: ${times8()}px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  .quick-filters-selects {
    margin-bottom: ${times8()}px;
  }
  .quick-filter-tag-group {
    margin-right: ${times8(2)}px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  .quick-filter-group-name {
    display: inline-block;
    margin-right: ${times8()}px;
  }
`;

const QuickFilterSelect: React.FC<{
  config: QuickFilterConfig;
  value?: (number | string)[];
  onChange(value: (number | string)[]): void;
}> = ({ config, value, onChange }) => {
  return (
    <CheckboxSelectDropdown
      options={config.options}
      value={value}
      onChange={onChange}
      dropdownPlacement="bottomLeft"
      className="quick-filter-select"
    >
      <Button icon={<Icon icon={Icons.Filter} />} data-testid="quick-filter-select-trigger">
        {config.label}
      </Button>
    </CheckboxSelectDropdown>
  );
};

export const QuickFilters: React.FC<QuickFiltersProps> = ({ config, value: initialValue, onChange }) => {
  const [value, setValue] = useState<QuickFilterValue>(initialValue || {});

  const tagList = useMemo((): { [key: string]: QuickFilterConfig } => {
    return Object.keys(config).reduce((acc: { [key: string]: QuickFilterConfig }, key) => {
      acc[key] = {
        ...config[key],
        options: config[key].options.filter((o) => value[key] && value[key].includes(o.value))
      };
      return acc;
    }, {});
  }, [config, value]);

  const emitChange = useCallback(
    (newValue: QuickFilterValue) => {
      onChange && onChange(newValue);
      setValue(newValue);
    },
    [onChange]
  );

  const onFilterValueChange = useCallback(
    (key: string, selectValue: (string | number)[]) => {
      const newValue: QuickFilterValue = { ...value, [key]: selectValue };
      emitChange(newValue);
    },
    [emitChange, value]
  );

  const onTagClose = useCallback(
    (key: string, tagValue: string | number) => {
      const newValue = { ...value, [key]: value[key].filter((v) => v !== tagValue) };
      emitChange(newValue);
    },
    [emitChange, value]
  );

  useEffect(() => {
    initialValue && setValue(initialValue);
  }, [initialValue]);

  return (
    <StyledQuickFilters>
      <div className="quick-filters-selects">
        {Object.keys(config).map((key) => (
          <QuickFilterSelect
            key={key}
            config={config[key]}
            value={value && value[key]}
            onChange={(v) => onFilterValueChange(key, v)}
          />
        ))}
      </div>

      <TagList className="quick-filter-tag-list">
        {Object.keys(tagList)
          .filter((key) => tagList[key].options.length > 0)
          .map((key) => {
            return (
              <span className="quick-filter-tag-group" key={key}>
                <span className="quick-filter-group-name">{config[key].label}:</span>
                {tagList[key].options.map((o) => (
                  <Tag
                    key={o.value}
                    data-testid="quick-filter-tag"
                    size="middle"
                    onClose={() => onTagClose(key, o.value)}
                  >
                    {o.label}
                  </Tag>
                ))}
              </span>
            );
          })}
      </TagList>
    </StyledQuickFilters>
  );
};
