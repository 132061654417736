import React, { useEffect, useState } from 'react';
import { AvatarShowcase, AvatarSize, ShowcaseAvatar } from 'Components/Primitives';
import { useUsersMap } from 'hooks';
import { nonNullable } from 'Utils/functional';
import { sortValues } from 'Components/Table';

interface UsersShowcaseProps {
  size: AvatarSize;
  userIds: number[];
  maxAvatarsToShow?: number;
  onValuesInvalid?(): void;
}

export const UsersShowcase: React.FC<UsersShowcaseProps> = ({ size, userIds, maxAvatarsToShow, onValuesInvalid }) => {
  const [avatarList, setAvatarList] = useState<ShowcaseAvatar[]>([]);
  const [sanitized, setSanitzed] = useState<boolean>(false);

  const usersMap = useUsersMap();

  useEffect(() => {
    if (usersMap.size === 0) return;

    setAvatarList(
      userIds
        .map((id) => {
          const user = usersMap.get(id);
          return user
            ? {
                key: user.user.value,
                title: user.user.data.fullName || '',
                avatarSrc: user.user.data.avatar,
                linkTo: `/users/${user.user.value}`
              }
            : null;
        })
        .sort((a, b) => sortValues({ a: a?.title || null, b: b?.title || null, sortOrder: 'ascend' }))
        .filter(nonNullable)
    );
  }, [userIds, usersMap]);

  useEffect(() => {
    if (userIds && usersMap.size > 0 && !sanitized) {
      const validUsers = userIds.filter((id) => usersMap.get(id));
      validUsers.length === 0 && onValuesInvalid && onValuesInvalid();
      setSanitzed(true);
    }
  }, [userIds, usersMap, sanitized, onValuesInvalid]);

  return <AvatarShowcase size={size} avatarList={avatarList} maxAvatarsToShow={maxAvatarsToShow} />;
};
