import React, { useEffect, useState } from 'react';
import { useCustomersMap } from 'hooks';
import { AvatarShowcase, AvatarSize, ShowcaseAvatar } from 'Components/Primitives';
import { useCustomerAvatarList } from 'Components/Customers/hooks/useCustomerAvatarList';

interface CustomersShowcaseProps {
  size: AvatarSize;
  customerIds: number[];
  maxAvatarsToShow?: number;
  className?: string;
  onValuesInvalid?(): void;
}

export const CustomersShowcase: React.FC<CustomersShowcaseProps> = ({
  size,
  customerIds,
  maxAvatarsToShow,
  className,
  onValuesInvalid
}) => {
  const [sanitized, setSanitzed] = useState<boolean>(false);
  const avatarList: ShowcaseAvatar[] = useCustomerAvatarList(customerIds);

  const customersMap = useCustomersMap();

  useEffect(() => {
    if (customerIds && customersMap && customersMap.size > 0 && !sanitized) {
      const validCustomers = customerIds.filter((id) => customersMap.get(id));
      validCustomers.length === 0 && onValuesInvalid && onValuesInvalid();
      setSanitzed(true);
    }
  }, [customerIds, customersMap, sanitized, onValuesInvalid]);

  return (
    <AvatarShowcase size={size} avatarList={avatarList} maxAvatarsToShow={maxAvatarsToShow} className={className} />
  );
};
