import { Form as RefForm, FormInstance, FormProps } from 'antd';

export const horizontalFormProps: Pick<FormProps, 'layout' | 'labelCol' | 'wrapperCol'> = {
  layout: 'horizontal',
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

export type { FormInstance, FormProps };
export const Form = RefForm;
