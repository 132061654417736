import React, { useMemo } from 'react';
import { Select, SelectChangeValue, SelectOption, SelectProps } from 'Components/Primitives';
import { useListsQuery } from 'hooks';
import { ListItem, ListType } from 'Types/Shared.types';
import { nonNullable } from 'Utils/functional';

interface ListSelectProps extends Omit<SelectProps, 'options'> {
  type?: ListType;
  listEntries?: ListItem[];
  customOptions?: SelectOption[];
}

export const ListSelect: React.FC<ListSelectProps> = (props) => {
  const { type, customOptions, listEntries } = props;
  const { value, ...propsWithoutValue } = props;
  const { lists } = useListsQuery((!customOptions || !listEntries) && type !== undefined);

  const options = useMemo((): SelectOption[] => {
    if (customOptions) {
      return customOptions;
    } else if (listEntries) {
      return listEntries.map((le) => ({ value: le.id, label: le.name }));
    }
    return type && lists[type] && lists[type]?.length
      ? (lists[type] || []).map((li) => ({
          label: li.name,
          value: li.id
        }))
      : [];
  }, [lists, type, listEntries, customOptions]);

  const val = useMemo((): SelectChangeValue | undefined => {
    if (nonNullable(value)) {
      return typeof value === 'boolean' ? (value === false ? 'false' : 'true') : value;
    } else {
      return undefined;
    }
  }, [value]);

  return (
    <Select
      value={val && options.length > 0 ? val : undefined}
      {...propsWithoutValue}
      options={options}
      showSearch={options.length > 3}
      loading={options.length === 0}
    />
  );
};
