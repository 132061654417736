import React from 'react';
import classNames from 'classnames';
import { TrendArrowDirectionMode } from 'Components/Primitives/Trend/Trend.types';
import { Icon } from 'Components/Primitives/Icon/Icon';
import { Icons } from 'Components/Primitives/Icon/Icon.types';
import styled from '@emotion/styled';
import { Colors } from 'Constants/Styles';
import { getColorWithBrightness } from 'Utils/colors';

interface TrendIconProps {
  value: number;
  arrowDirectionMode?: TrendArrowDirectionMode;
}

const StyledTrendIcon = styled(Icon)<{ arrowdirectionmode: TrendArrowDirectionMode }>`
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;

  &.trend-up {
    color: ${({ arrowdirectionmode }) => (arrowdirectionmode === 'downIsGreen' ? Colors.Negative : Colors.Positive)};
  }
  &.trend-down {
    color: ${({ arrowdirectionmode }) => (arrowdirectionmode === 'downIsGreen' ? Colors.Positive : Colors.Negative)};
  }
  &.trend-level {
    color: ${getColorWithBrightness(Colors.Neutral, -0.15)};
  }
`;

export const TrendIcon: React.FC<TrendIconProps> = ({ value, arrowDirectionMode = 'default' }) => {
  return value === 0 ? (
    <StyledTrendIcon
      data-testid="trend-icon"
      arrowdirectionmode={arrowDirectionMode}
      className={classNames('trend-icon', 'trend-level')}
      icon={Icons.StairsLevel}
    />
  ) : (
    <StyledTrendIcon
      data-testid="trend-icon"
      arrowdirectionmode={arrowDirectionMode}
      className={classNames('trend-icon', value > 0 ? 'trend-up' : 'trend-down')}
      icon={value > 0 ? Icons.StairsUp : Icons.StairsDown}
    />
  );
};
