import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common from './locales/en/common.json';
import login from './locales/en/login.json';
import layout from './locales/en/layout.json';
import onboarding from './locales/en/onboarding.json';
import permissions from './locales/en/permissions.json';
import customersOverview from './locales/en/customersOverview.json';
import customerDetails from './locales/en/customerDetails.json';
import usersOverview from './locales/en/usersOverview.json';
import userDetails from './locales/en/userDetails.json';
import stakeholderDetails from './locales/en/stakeholderDetails.json';
import insights from './locales/en/insights.json';
import settings from './locales/en/settings.json';
import communication from './locales/en/Communication.json';
import reports from './locales/en/reports.json';
import userInputs from './locales/en/userInputs.json';
import dashboard from './locales/en/dashboard.json';
import contentGeneration from './locales/en/contentGeneration.json';
import systemDashboards from './locales/en/systemDashboards.json';

const resources = {
  en: {
    common,
    login,
    layout,
    onboarding,
    permissions,
    customersOverview,
    customerDetails,
    usersOverview,
    userDetails,
    stakeholderDetails,
    insights,
    settings,
    communication,
    reports,
    userInputs,
    dashboard,
    contentGeneration,
    systemDashboards
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  returnNull: false,
  interpolation: {
    escapeValue: false
  },
  saveMissing: true,
  missingKeyHandler: (ng, ns, key, fallbackValue) =>
    console.error(`Translation key not found ${key}, ${ng}, ${ns}, ${fallbackValue}`)
});

export default i18n;
