import * as React from "react";
const SvgPlusSquare = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "plus-square", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { id: "Path", fillRule: "evenodd", clipRule: "evenodd", d: "M 706.825 96.214 L 317.175 96.214 C 181.351 96.214 96.214 192.347 96.214 328.437 L 96.214 695.561 C 96.214 831.652 180.955 927.786 317.175 927.786 L 706.825 927.786 C 843.045 927.786 927.786 831.652 927.786 695.561 L 927.786 328.437 C 927.786 192.347 843.045 96.214 706.825 96.214 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: 60,
  stroke: "currentColor",
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { id: "Line_185", d: "M 512 359.298 L 512 663.916", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { id: "Line_186", d: "M 664.456 511.601 L 359.546 511.601", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }));
export default SvgPlusSquare;
