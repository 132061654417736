import * as React from "react";
const SvgDocPdf = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "doc-pdf", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 152.895 472.723 L 871.106 472.723 L 871.106 791.24 L 755.847 906.296 L 268.154 906.296 L 152.895 791.24 Z M 348.172 679.989 C 361.191 669.337 367.701 651.139 367.701 625.396 C 367.701 601.823 361.191 584.414 348.172 573.17 C 335.153 561.926 317.695 556.304 295.798 556.304 L 200.815 556.304 L 200.815 774.381 L 246.087 774.381 L 246.087 695.968 L 292.395 695.968 C 316.56 695.968 335.153 690.642 348.172 679.989 Z M 288.549 658.389 L 246.087 658.389 L 246.087 594.179 L 288.549 594.179 C 299.3 594.179 307.659 596.595 313.626 601.428 C 319.593 606.261 322.577 614.349 322.577 625.692 C 322.577 637.035 319.618 645.32 313.7 650.548 C 307.683 655.775 299.3 658.389 288.549 658.389 Z M 588.898 733.695 C 600.537 713.574 606.357 689.014 606.357 660.016 C 606.357 648.575 604.976 636.542 602.214 623.917 C 599.452 611.292 594.274 599.554 586.679 588.705 C 577.21 575.291 564.832 566.069 549.544 561.038 C 540.569 558.079 529.325 556.501 515.812 556.304 L 421.864 556.304 L 421.864 774.381 L 515.812 774.381 C 548.755 774.381 573.117 760.819 588.898 733.695 Z M 560.048 663.123 C 560.048 679.2 557.73 693.058 553.095 704.697 C 544.612 725.903 529.62 736.506 508.118 736.506 L 466.101 736.506 L 466.101 594.179 L 508.118 594.179 C 528.831 594.179 542.689 600.097 549.692 611.933 C 556.596 623.868 560.048 640.931 560.048 663.123 Z M 815.277 556.6 L 660.67 556.6 L 660.67 774.381 L 705.943 774.381 L 705.943 682.948 L 801.666 682.948 L 801.666 645.073 L 705.943 645.073 L 705.943 594.919 L 815.277 594.919 Z", style: {
  fill: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 636.076 119.767 L 331.628 119.767 C 236.445 119.425 158.422 190.337 156.179 279.255 L 156.179 737.445 C 154.074 827.817 230.724 902.707 327.418 904.717 C 328.837 904.717 330.209 904.76 331.628 904.717 L 697.214 904.717 C 793.038 901.081 868.544 827.09 867.858 737.445 L 867.858 345.42 L 636.076 119.767 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: 60,
  fill: "none",
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 624.051 119.264 L 624.051 243.681 C 624.051 304.414 676.585 353.642 741.566 353.813 L 867.638 353.813", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: 60,
  fill: "none",
  stroke: "currentColor"
} }));
export default SvgDocPdf;
