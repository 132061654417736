import React from 'react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { Colors, times8 } from 'Constants/Styles';

export interface HamburgerProps {
  state: 'open' | 'closed';
  color?: string;
  role?: string;
  className?: string;
  onClick(): void;
}

const StyledHamburger = styled.div<{ color?: string }>`
  position: relative;
  width: ${times8(5)}px;
  height: ${times8(5)}px;
  .hamburger-line {
    position: absolute;
    height: 2px;
    border-radius: 2px;
    background-color: ${({ color }) => color || Colors.Text};
    width: 18px;
    left: 11px;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
    &:nth-of-type(1) {
      top: 13px;
      transform-origin: top left;
    }
    &:nth-of-type(2) {
      top: 19px;
      transform-origin: center center;
    }
    &:nth-of-type(3) {
      top: 25px;
      transform-origin: bottom left;
    }
  }
  &.closed {
    .hamburger-line {
      :nth-of-type(1) {
        transform: rotate(45deg) translate(1px, -1px);
      }
      :nth-of-type(2) {
        transform: scaleX(0);
      }
      :nth-of-type(3) {
        transform: rotate(-45deg) translate(1px, 1px);
      }
    }
  }
`;

export const Hamburger: React.FC<HamburgerProps> = ({ state, color, role, className, onClick }) => (
  <StyledHamburger
    className={classNames(className, state === 'closed' && 'closed')}
    color={color}
    role={role}
    onClick={onClick}
  >
    <span className="hamburger-line"></span>
    <span className="hamburger-line"></span>
    <span className="hamburger-line"></span>
  </StyledHamburger>
);
