import React, { ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';
import { camelCaseToKebabCase } from 'Utils/functional';
import classNames from 'classnames';
import { Colors, times8 } from 'Constants/Styles';
import { getColorWithBrightness, isColorDark } from 'Utils/colors';

export interface RibbonProps {
  label: ReactNode;
  position?: RibbonPosition;
  ribbonColor?: string;
  labelColor?: string;
  className?: string;
}
type RibbonPosition = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
const shadow = {
  disX: 3,
  disY: 3,
  blur: 4,
  color: 'rgba(0, 0, 0, 0.15)'
};

const StyledRibbon = styled.div<{ bgCol: string; lblCol: string; position: RibbonPosition }>`
  position: absolute;
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    border: ${({ bgCol }) => `${times8(0.5)}px solid ${getColorWithBrightness(bgCol, -0.3)}`};
  }
  span {
    padding: ${times8(0.5)}px ${times8(2)}px;
    display: block;
    background-color: ${({ bgCol }) => bgCol};
    color: ${({ lblCol }) => lblCol};
    border: ${({ bgCol }) => `1px solid ${getColorWithBrightness(bgCol, -0.025)}`};
    box-sizing: border-box;
    border-radius: ${({ position }) => {
      switch (position) {
        case 'topLeft':
          return '2px 2px 2px 0';
        case 'topRight':
          return '2px 2px 0 2px';
        case 'bottomLeft':
          return '0 2px 2px 2px';
        case 'bottomRight':
          return '2px 0 2px 2px';
      }
    }};
  }

  /* top right*/
  &.top-right {
    top: -${times8()}px;
    right: -${times8()}px;
    span {
      box-shadow: -${shadow.disX}px ${shadow.disY}px ${shadow.blur}px ${shadow.color};
    }
    &:before {
      border-bottom-color: transparent;
      border-right-color: transparent;
      bottom: -${times8()}px;
      right: 0;
    }
  }

  /* top left*/
  &.top-left {
    top: -${times8()}px;
    left: -${times8()}px;
    span {
      box-shadow: ${shadow.disX}px ${shadow.disY}px ${shadow.blur}px ${shadow.color};
    }
    &:before {
      border-bottom-color: transparent;
      border-left-color: transparent;
      bottom: -${times8()}px;
      left: 0;
    }
  }

  /* bottom right*/
  &.bottom-right {
    bottom: -${times8()}px;
    right: -${times8()}px;
    span {
      box-shadow: -${shadow.disX}px -${shadow.disY}px ${shadow.blur}px ${shadow.color};
    }
    &:before {
      border-top-color: transparent;
      border-right-color: transparent;
      top: -${times8()}px;
      right: 0;
    }
  }

  /* bottom left*/
  &.bottom-left {
    bottom: -${times8()}px;
    left: -${times8()}px;
    span {
      box-shadow: ${shadow.disX}px -${shadow.disY}px ${shadow.blur}px ${shadow.color};
    }
    &:before {
      border-top-color: transparent;
      border-left-color: transparent;
      top: -${times8()}px;
      left: 0;
    }
  }
`;

export const Ribbon: React.FC<RibbonProps> = ({
  label,
  position = 'topRight',
  ribbonColor = Colors.Primary,
  labelColor,
  className
}) => {
  const positionClass = useMemo(() => camelCaseToKebabCase(position), [position]);
  const labelCol = useMemo(() => {
    if (labelColor) {
      return labelColor;
    } else {
      return isColorDark(ribbonColor)
        ? getColorWithBrightness(ribbonColor, 0.75)
        : getColorWithBrightness(ribbonColor, -0.75);
    }
  }, [labelColor, ribbonColor]);

  return (
    <StyledRibbon
      className={classNames(className, positionClass)}
      bgCol={ribbonColor}
      lblCol={labelCol}
      position={position}
    >
      <span>{label}</span>
    </StyledRibbon>
  );
};
