import * as React from "react";
const SvgSandClock = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "sand-clock", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 433.921 499.093 C 311.912 383.004 281.291 308.501 260.106 178.945 L 772.5 178.945 C 733.557 282.586 710.249 401.964 575.941 499.878 C 555.89 514.491 553.539 544.491 572.031 561.026 C 684.204 661.327 714.451 729.757 738.338 845.056 L 260.106 845.056 C 279.666 726.318 310.231 658.35 432.301 554.775 C 449.2 540.443 449.974 514.368 433.921 499.093 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 191.754 845.056 L 806.626 845.056", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 191.754 178.945 L 832.245 178.945", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }));
export default SvgSandClock;
