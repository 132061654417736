import React, { ReactNode, useCallback, useState } from 'react';
import { nonNullable } from 'Utils/functional';
import { useListsQuery } from 'hooks';
import { ListType } from 'Types/Shared.types';
import { FilterFormData, FilterMetadata, FilterType, FilterValue } from 'Components/Filters/Filter.types';
import { UsersShowcase } from 'Components/Users';
import { DateRangeValue } from 'Types/DateRange.types';
import { Tag, TagList } from 'Components/Primitives';
import { ListDisplayValue } from 'Components/ListSelect';
import { CustomersShowcase } from 'Components/Customers/CustomersShowcase/CustomersShowcase';
import { StakeholderShowcase } from 'Components/Stakeholders/StakeholderShowcase/StakeholderShowcase';
import InsightDisplayValues from 'Components/Insights/InsightDisplayValues/InsightDisplayValues';
import FilterTagDateRange from 'Components/Filters/FilterTagDateRange';
import { Colors } from 'Constants/Styles';

interface FilterTagsProps<T> {
  metadata: FilterMetadata[];
  filterData: T;
  onRemove?(filter: FilterMetadata): void;
  onClick?(filter: FilterMetadata): void;
}

export interface RenderTagProps {
  metadata: FilterMetadata;
  value: FilterValue;
  onValuesInvalid: (filter: FilterMetadata) => void;
  onDateRangeWarningChange?: (warn: boolean) => void;
}

const MAX_SHOWCASE_NUM = 6;

export const RenderFilterTagContent: React.FC<RenderTagProps> = ({
  metadata,
  value,
  onValuesInvalid,
  onDateRangeWarningChange
}): ReactNode => {
  const { lists } = useListsQuery();
  switch (metadata.type) {
    case FilterType.DateRange:
      return (
        <FilterTagDateRange
          metadata={metadata}
          value={value as DateRangeValue}
          onDateRangeWarningChange={onDateRangeWarningChange}
        />
      );
    case FilterType.Customer:
      return (
        <>
          {metadata.label}:{' '}
          <CustomersShowcase
            customerIds={value as number[]}
            size="small"
            maxAvatarsToShow={metadata.options?.maxShowcaseAvatarsToShow || MAX_SHOWCASE_NUM}
            onValuesInvalid={() => onValuesInvalid(metadata)}
          />
        </>
      );
    case FilterType.User:
      return (
        <>
          {metadata.label}:{' '}
          <UsersShowcase
            userIds={value as number[]}
            size="small"
            maxAvatarsToShow={metadata.options?.maxShowcaseAvatarsToShow || MAX_SHOWCASE_NUM}
            onValuesInvalid={() => onValuesInvalid(metadata)}
          />
        </>
      );
    case FilterType.Stakeholders:
      return metadata.options?.customerId ? (
        <>
          {metadata.label}:{' '}
          <StakeholderShowcase
            stakeholderIds={value as number[]}
            customerId={metadata.options?.customerId}
            size="small"
            maxAvatarsToShow={metadata.options?.maxShowcaseAvatarsToShow || MAX_SHOWCASE_NUM}
          />
        </>
      ) : null;
    case FilterType.Insight:
      return (
        <>
          {metadata.label}: <InsightDisplayValues value={value as string[]} />
        </>
      );
    case FilterType.Tier:
      return (
        <>
          {metadata.label}:{' '}
          <ListDisplayValue
            listEntries={lists[ListType.Tier]}
            value={value as number[]}
            onValuesInvalid={() => onValuesInvalid(metadata)}
          />
        </>
      );
    case FilterType.JourneyPhase:
      return (
        <>
          {metadata.label}:{' '}
          <ListDisplayValue
            listEntries={lists[ListType.Journey]}
            value={value as number[]}
            onValuesInvalid={() => onValuesInvalid(metadata)}
          />
        </>
      );
    case FilterType.Event:
      return (
        <>
          {metadata.label}:{' '}
          <ListDisplayValue
            listEntries={lists[ListType.LifecycleEvents]}
            value={value as number[]}
            onValuesInvalid={() => onValuesInvalid(metadata)}
          />
        </>
      );
    case FilterType.ActiveInactive:
      return (
        <>
          {metadata.label}:{' '}
          <ListDisplayValue
            listEntries={lists[ListType.ActiveInactive]}
            value={value as string[]}
            onValuesInvalid={() => onValuesInvalid(metadata)}
          />
        </>
      );
    case FilterType.Sentiment:
      return (
        <>
          {metadata.label}:{' '}
          <ListDisplayValue
            listEntries={lists[ListType.SentimentType]}
            value={value as string[]}
            onValuesInvalid={() => onValuesInvalid(metadata)}
          />
        </>
      );
    case FilterType.StakeholderRole:
      return (
        <>
          {metadata.label}:{' '}
          <ListDisplayValue
            listEntries={lists[ListType.StakeholderRoles]}
            value={value as string[]}
            onValuesInvalid={() => onValuesInvalid(metadata)}
          />
        </>
      );
    case FilterType.Score:
      return (
        <>
          {metadata.label}:{' '}
          <ListDisplayValue
            listEntries={
              lists[
                metadata.key.toLowerCase().includes('sentiment')
                  ? ListType.SentimentBucketScore
                  : ListType.EngagementBucketScore
              ]
            }
            value={value as string[]}
          />
        </>
      );
    case FilterType.Topic:
      return (
        <>
          {metadata.label}:{' '}
          <ListDisplayValue
            listEntries={lists[ListType.Topic]}
            value={value as string[]}
            onValuesInvalid={() => onValuesInvalid(metadata)}
          />
        </>
      );
    case FilterType.List:
      return (
        <>
          {metadata.label}:{' '}
          <ListDisplayValue
            listEntries={metadata.typeData?.listEntries}
            value={value as string[]}
            onValuesInvalid={() => onValuesInvalid(metadata)}
          />
        </>
      );
    default:
      return (
        <>
          {metadata.label}: {Array.isArray(value) ? value.join(', ') : value?.toString()}
        </>
      );
  }
};

interface FilterTagProps<T> extends Omit<FilterTagsProps<T>, 'metadata'> {
  metadata: FilterMetadata;
}
const FilterTag = <T extends FilterFormData>({ metadata, filterData, onRemove, onClick }: FilterTagProps<T>) => {
  const [indicateWarning, setIndicateWarning] = useState<boolean>(false);
  const onFilterClick = useCallback(() => {
    onClick && onClick(metadata);
  }, [metadata, onClick]);

  const onRemoveClick = useCallback(() => {
    onRemove && onRemove(metadata);
  }, [metadata, onRemove]);

  const onValuesInvalid = useCallback(
    (filter: FilterMetadata) => {
      onRemove && onRemove(filter);
    },
    [onRemove]
  );

  const onDateRangeWarningChange = useCallback((warn: boolean) => {
    setIndicateWarning(warn);
  }, []);

  return (
    <Tag
      color={indicateWarning ? Colors.Warning : undefined}
      key={metadata.key}
      data-testid="filter-tag"
      className="filter-tag"
      onClick={onFilterClick}
      onClose={metadata.mandatory ? undefined : onRemoveClick}
    >
      <RenderFilterTagContent
        metadata={metadata}
        value={filterData[metadata.key]}
        onValuesInvalid={onValuesInvalid}
        onDateRangeWarningChange={onDateRangeWarningChange}
      />
    </Tag>
  );
};

export const FilterTags = <T extends FilterFormData>({
  metadata,
  filterData,
  onRemove,
  onClick
}: FilterTagsProps<T>) => {
  return (
    <TagList className="filter-tags">
      {metadata
        .filter((fmd) => nonNullable(filterData[fmd.key]))
        .map((fmd) => {
          return (
            nonNullable(filterData[fmd.key]) && (
              <FilterTag key={fmd.key} metadata={fmd} filterData={filterData} onRemove={onRemove} onClick={onClick} />
            )
          );
        })}
    </TagList>
  );
};
