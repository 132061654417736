import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Layout, times8 } from 'Constants/Styles';
import { Swiper as SwiperJS, SwiperSlide as SwiperJSSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { Icon } from 'Components/Primitives/Icon/Icon';
import { Button } from 'Components/Primitives/Button/Button';
import { Icons } from 'Components/Primitives/Icon/Icon.types';

export interface SwiperProps {
  slides: SwiperSlide[];
  className?: string;
  slidesPerView?: number;
  slideMinWidth?: number;
  slideMaxWidth?: number;
  autoPlayOptions?: {
    delay: number;
  };
  noSwiping?: boolean;
  showNavigation?: boolean;
  'data-testid'?: string;
}

export interface SwiperSlide {
  key: string;
  node: ReactNode;
}

const StyledSwiper = styled.div<{
  slidesPerView: number | undefined;
  slideMinWidth: number | undefined;
  slideMaxWidth: number | undefined;
}>`
  position: relative;

  .swiper-slide {
    width: ${({ slidesPerView }) =>
      slidesPerView
        ? `calc(${100 / slidesPerView}% - ${Math.ceil((16 * (slidesPerView - 1)) / slidesPerView)}px)`
        : 'auto'};
    min-width: ${({ slideMinWidth }) => (slideMinWidth ? `${slideMinWidth}px` : 'unset')};
    max-width: ${({ slideMaxWidth }) => (slideMaxWidth ? `${slideMaxWidth}px` : 'unset')};
    height: auto;
  }

  .swiper {
    padding: ${times8(2)}px;
    margin: -${times8(2)}px;
  }

  .swiper-navigation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;

    .swiper-nav-btn {
      pointer-events: all;
      position: absolute;
      width: ${times8(7)}px;
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      > button {
        position: relative;
      }

      &.swiper-prev {
        left: -${times8(2)}px;
        background: linear-gradient(90deg, ${Layout.backgroundColor} 50%, ${Layout.backgroundColor}00 100%);
        > button {
          left: -${times8()}px;
        }
      }

      &.swiper-next {
        right: -${times8(2)}px;
        background: linear-gradient(270deg, ${Layout.backgroundColor} 50%, ${Layout.backgroundColor}00 100%);
        justify-content: flex-end;
        > button {
          right: -${times8()}px;
        }
      }

      .button-arrow {
        font-size: 32px;
      }

      &.disabled {
        display: none;
      }
    }
  }
`;

export const Swiper: React.FC<SwiperProps> = ({
  className,
  slides,
  slidesPerView,
  slideMinWidth,
  slideMaxWidth,
  autoPlayOptions,
  showNavigation = true,
  noSwiping = true,
  'data-testid': dataTestId
}) => {
  return (
    <StyledSwiper
      className={className}
      data-testid={dataTestId}
      slidesPerView={slidesPerView}
      slideMinWidth={slideMinWidth}
      slideMaxWidth={slideMaxWidth}
    >
      <SwiperJS
        slidesPerView="auto"
        spaceBetween={times8(2)}
        noSwiping={noSwiping}
        noSwipingClass="swiper-slide"
        autoplay={autoPlayOptions}
        navigation={{
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev',
          disabledClass: 'disabled'
        }}
        modules={[Navigation, Autoplay]}
      >
        {slides.map((slide) => {
          return (
            <SwiperJSSlide key={slide.key} className="swiper-slide">
              {slide.node}
            </SwiperJSSlide>
          );
        })}
      </SwiperJS>

      {showNavigation && (
        <div className="swiper-navigation">
          <div className="swiper-nav-btn swiper-prev">
            <Button size="small" type="text" icon={<Icon className="button-arrow" icon={Icons.ChevronLeft} />} />
          </div>
          <div className="swiper-nav-btn swiper-next">
            <Button size="small" type="text" icon={<Icon className="button-arrow" icon={Icons.ChevronRight} />} />
          </div>
        </div>
      )}
    </StyledSwiper>
  );
};
