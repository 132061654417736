import * as React from "react";
const SvgSearch = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "search", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_739", cx: 497.256, cy: 488.682, r: 336.742, strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { id: "Line_181", d: "M 731.465 740.38 L 863.486 872.06", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }));
export default SvgSearch;
