import { Colors, hexAlpha15 } from 'Constants/Styles';
import { TrendMode } from 'Components/Primitives';

export const getTrendBackgroundColor = (value: number, mode: TrendMode): string | undefined => {
  switch (mode) {
    case 'text':
      return undefined;
    case 'tag':
      return value === 0
        ? `${Colors.Neutral}${hexAlpha15}`
        : value > 0
        ? `${Colors.Positive}${hexAlpha15}`
        : `${Colors.Negative}${hexAlpha15}`;
  }
};

export const getTrendDisplayValue = (value: number): string => {
  if (value === 0) {
    return '0%';
  } else if (value > 100) {
    return '>100%';
  } else if (value < -100) {
    return '<-100%';
  } else {
    return `${value.toFixed(0)}%`;
  }
};
