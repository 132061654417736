import React, { CSSProperties, ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';
import { times8 } from 'Constants/Styles';
import { MenuItem } from 'Components/Primitives/Menu/Menu';
import { Dropdown } from 'Components/Primitives/Dropdown/Dropdown';
import { ShowcaseAvatar } from 'Components/Primitives/Avatar/Avatar.types';
import { AvatarAndTitle } from 'Components/Primitives/Avatar/AvatarAndTitle';

interface AvatarDropDownProps {
  avatarList: ShowcaseAvatar[];
  showDropdown?: boolean;
  children: ReactNode;
}

const StyledMenuListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .show-case-list-item-suffix {
    margin-left: ${times8(3)}px;
  }
`;

export const AvatarDropDown: React.FC<AvatarDropDownProps> = ({ avatarList, showDropdown = true, children }) => {
  const menuItems = useMemo(
    (): MenuItem[] =>
      avatarList.map((avatar) => ({
        key: avatar.key.toString(),
        label: (
          <StyledMenuListItem>
            <AvatarAndTitle
              className="show-case-list-item-avatar"
              avatarSrc={avatar.avatarSrc}
              title={avatar.title}
              size="small"
              linkTo={avatar.linkTo}
            />
            {avatar.suffix && <div className="show-case-list-item-suffix">{avatar.suffix}</div>}
          </StyledMenuListItem>
        )
      })),
    [avatarList]
  );

  const menuStyle = useMemo(
    (): CSSProperties => ({
      minWidth: 250,
      maxHeight: 250,
      overflow: 'auto',
      overflowX: 'hidden'
    }),
    []
  );

  return (
    <Dropdown trigger={showDropdown ? ['hover'] : []} arrow={true} menu={{ items: menuItems, style: menuStyle }}>
      {children}
    </Dropdown>
  );
};
