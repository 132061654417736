import * as React from "react";
const SvgRelationship = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "relationship", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 683.432 288.105 C 750.641 339.643 793.975 420.761 793.975 512 M 644.943 760.731 C 605.336 781.945 560.073 793.975 512 793.975 C 471.315 793.975 432.642 785.358 397.707 769.85 M 230.025 512 C 230.025 428.223 266.561 352.979 324.568 301.333", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 50
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 239.856 805.239 C 171.875 805.239 113.814 815.515 113.814 856.685 C 113.814 897.856 171.521 908.503 239.856 908.503 C 307.857 908.503 365.9 898.211 365.9 857.057 C 365.9 815.902 308.209 805.239 239.856 805.239 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 50
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 239.856 746.518 C 284.476 746.518 320.643 710.352 320.643 665.733 C 320.643 621.113 284.476 584.947 239.856 584.947 C 195.255 584.947 159.089 621.113 159.089 665.733 C 158.929 710.194 194.831 746.36 239.309 746.518 L 239.856 746.518 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 50
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 784.141 805.239 C 716.16 805.239 658.1 815.515 658.1 856.685 C 658.1 897.856 715.806 908.503 784.141 908.503 C 852.142 908.503 910.185 898.211 910.185 857.057 C 910.185 815.902 852.495 805.239 784.141 805.239 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 50
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 784.141 746.518 C 828.761 746.518 864.928 710.352 864.928 665.733 C 864.928 621.113 828.761 584.947 784.141 584.947 C 739.541 584.947 703.374 621.113 703.374 665.733 C 703.214 710.194 739.116 746.36 783.594 746.518 L 784.141 746.518 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 50
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 511.999 332.271 C 444.018 332.271 385.957 342.547 385.957 383.718 C 385.957 424.889 443.663 435.536 511.999 435.536 C 579.999 435.536 638.043 425.243 638.043 384.09 C 638.043 342.935 580.352 332.271 511.999 332.271 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 50
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 511.999 273.551 C 556.619 273.551 592.785 237.384 592.785 192.765 C 592.785 148.145 556.619 111.98 511.999 111.98 C 467.398 111.98 431.232 148.145 431.232 192.765 C 431.071 237.226 466.974 273.393 511.451 273.551 L 511.999 273.551 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 50
} }));
export default SvgRelationship;
