import styled from '@emotion/styled';
import { Spin, Tooltip } from 'Components/Primitives';
import { useListsQuery } from 'hooks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItem } from 'Types/Shared.types';

interface InsightDisplayValuesProps {
  value: string[];
}

const StyledInsightDisplayValues = styled.span`
  display: inline-block;
`;

const StyledInsightTooltip = styled.ul`
  li:before {
    content: '• ';
  }
`;

const InsightDisplayValues: React.FC<InsightDisplayValuesProps> = ({ value }) => {
  const { t } = useTranslation(['insights']);
  const { lists, isLoading } = useListsQuery();

  const insightLabelByKey = useMemo(
    (): { [key: string]: string } =>
      lists
        ? (lists.InsightType || []).reduce((acc: { [key: string]: string }, listItem: ListItem) => {
            acc[listItem.id] = listItem.name;
            return acc;
          }, {})
        : {},
    [lists]
  );

  return (
    <Tooltip
      overlayStyle={{ minWidth: 320 }}
      title={
        isLoading ? (
          <Spin />
        ) : (
          <StyledInsightTooltip data-testid="insights-breakdown">
            {value.map((insightType) => (
              <li key={insightType}>{insightLabelByKey[insightType]}</li>
            ))}
          </StyledInsightTooltip>
        )
      }
    >
      {
        <StyledInsightDisplayValues data-testid="insight-display-values-trigger">
          {t('tags.insight-count', { count: value.length })}
        </StyledInsightDisplayValues>
      }
    </Tooltip>
  );
};

export default InsightDisplayValues;
