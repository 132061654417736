import React, { useCallback, useEffect, useState } from 'react';
import { useCustomersEssentialsQuery, useCustomersMap } from 'hooks';
import {
  getSelectedCustomers,
  getCustomersTreeSelectOptions
} from 'Components/Customers/CustomersTreeSelect/CustomersTreeSelectUtils';
import { TreeSelect, TreeNode, TreeSelectValue, SelectProps } from 'Components/Primitives';
import { CustomerEssentials } from 'Types/Customer.types';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

interface CustomersTreeSelectProps {
  value?: TreeSelectValue;
  placeholder?: string;
  mode?: SelectProps['mode'];
  emptyAsAll?: boolean;
  allowClear?: boolean;
  maxTagCount?: number | 'responsive';
  'data-testid'?: string;
  onChange?(ids: TreeSelectValue): void;
  onSelect?(users: CustomerEssentials | CustomerEssentials[] | null): void;
}

const StyledCustomersTreeSelect = styled(TreeSelect)`
  .ant-select-selection-item .avatar-and-title-title strong {
    display: none;
  }
`;

export const CustomersTreeSelect: React.FC<CustomersTreeSelectProps> = ({
  value,
  placeholder,
  mode = 'multiple',
  emptyAsAll = true,
  allowClear = true,
  maxTagCount,
  'data-testid': dataTestId,
  onChange,
  onSelect
}) => {
  const { t } = useTranslation(['common']);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [treeData, setTreeData] = useState<TreeNode[]>([]);

  const customersMap = useCustomersMap();
  const { data, isLoading } = useCustomersEssentialsQuery();

  const onTreeSelectChange = useCallback(
    (e: TreeSelectValue) => {
      onChange && onChange(e);
      onSelect && onSelect(getSelectedCustomers(e, customersMap));
      setSearchTerm(undefined);
    },
    [onChange, onSelect, customersMap]
  );

  const onSearch = useCallback((q: string) => {
    setSearchTerm(q);
  }, []);

  useEffect(() => {
    if (!data) return;
    const options = getCustomersTreeSelectOptions(data.data, searchTerm);
    setTreeData(options);
  }, [data, searchTerm]);

  return (
    <StyledCustomersTreeSelect
      options={treeData}
      data-testid={dataTestId || 'customer-select'}
      value={value && treeData.length > 0 ? value : undefined}
      placeholder={placeholder || t('customer-select.placeholder')}
      selectMultiple={mode === 'multiple'}
      emptyAsAll={emptyAsAll}
      allowClear={allowClear}
      loading={isLoading}
      maxTagCount={maxTagCount}
      onSearch={onSearch}
      onChange={onTreeSelectChange}
    />
  );
};
