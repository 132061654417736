import { ReactNode } from 'react';
import { FeatureFlag } from 'Constants/FeatureFlag.enum';
import { Permission } from 'Types/Permissions.types';

export interface NavigationRoute {
  label: string;
  path: string;
  element: ReactNode;
  disabled?: boolean;
  enableFeatureFlags?: FeatureFlag[];
  disableFeatureFlags?: FeatureFlag[];
  permissions?: Permission[];
  children?: NavigationRoute[];
}

export type OrganizationOrCustomer = 'Organization' | 'Customer';

export interface UserOrStakeholder {
  type: 'User' | 'Stakeholder';
  id: number;
  fullName: string;
  avatar: string | null;
  email: string | null;
}

export interface ListItem {
  id: number | string;
  name: string;
}

export interface ListItemWithPosition extends ListItem {
  position: number;
}

export interface ListMetadata {
  name: ListType;
  values: ListItemWithPosition[];
}

export type ListMap = Partial<Record<ListType, ListItemWithPosition[]>>;

export enum ListType {
  ActiveInactive = 'ActiveInactive',
  Boolean = 'Boolean',
  CustomerStatus = 'CustomerStatus',
  SentimentBucketScore = 'SentimentBucketScore',
  EngagementBucketScore = 'EngagementBucketScore',
  SentimentType = 'SentimentType',
  InsightType = 'InsightType',
  TouchFrequency = 'TouchFrequency',
  UserRoles = 'UserRoles',
  CommType = 'CommType',
  Journey = 'journey',
  Tier = 'tier',
  StakeholderRoles = 'stakeholderRoles',
  LifecycleEvents = 'lifecycleEvent',
  Topic = 'topic'
}

export interface FieldListItem extends ListItem {
  inactive: boolean;
}
export interface FieldTypeData {
  entity?: 'customer' | 'user';
  listEntries?: FieldListItem[];
}

export interface ValueAndTrend {
  value: number | null;
  trend: number | null;
}

export enum WeekDay {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export enum Score {
  High = 'High',
  Average = 'Average',
  Low = 'Low'
}

export enum ActiveInactive {
  Active = 'Active',
  Inactive = 'Inactive'
}
