import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TabComponent from 'Components/Primitives/Tabs/TabComponent';
import { Tab } from 'Components/Primitives/Tabs/Tabs.types';
import classNames from 'classnames';

interface TabDropdownProps {
  tab: Tab;
  activeTabKey?: string;
}

const TabDropdown: React.FC<TabDropdownProps> = ({ tab, activeTabKey }) => {
  const [expand, setExpand] = useState<boolean>(false);
  const location = useLocation();

  const { pathname } = location;

  const handleToggleExpand = useCallback(() => {
    setExpand(!expand);
  }, [expand]);

  useEffect(() => {
    if (tab.children!.some((child) => child.linkTo && pathname.includes(child.linkTo))) {
      setExpand(true);
    }
  }, [pathname, tab.children]);

  return (
    <>
      <TabComponent
        key={tab.key}
        tab={{ ...tab, linkTo: undefined }}
        onTabClick={handleToggleExpand}
        isActive={false}
        className={classNames('tab-expand', { expand })}
      />

      {expand && (
        <div className="tab-children">
          {tab.children!.map((child) => (
            <TabComponent key={`${tab.key}-${child.key}`} tab={child} isActive={activeTabKey === child.key} />
          ))}
        </div>
      )}
    </>
  );
};

export default TabDropdown;
