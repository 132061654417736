import * as React from "react";
const SvgEmail = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "email", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { id: "Stroke 1", d: "M 741.297 389.674 L 568.689 530.028 C 536.077 555.9 490.196 555.9 457.584 530.028 L 283.52 389.674", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 3", fillRule: "evenodd", clipRule: "evenodd", d: "M 702.695 861.62 C 820.839 861.946 900.467 764.872 900.467 645.571 L 900.467 378.756 C 900.467 259.451 820.839 162.38 702.695 162.38 L 321.307 162.38 C 203.161 162.38 123.534 259.451 123.534 378.756 L 123.534 645.571 C 123.534 764.872 203.161 861.946 321.307 861.62 L 702.695 861.62 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }));
export default SvgEmail;
