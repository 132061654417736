export const AvatarSizeToPx = {
  small: 20,
  middle: 26,
  large: 40,
  xLarge: 72
};

export const AvatarFontSize = {
  small: 9,
  middle: 11,
  large: 13,
  xLarge: 24
};

export const getAvatarInitialsFontSize = (size: number) => {
  if (size <= AvatarSizeToPx.small) {
    return AvatarFontSize.small;
  }
  if (size <= AvatarSizeToPx.middle) {
    return AvatarFontSize.middle;
  }
  if (size <= AvatarSizeToPx.large) {
    return AvatarFontSize.large;
  }
  return AvatarFontSize.xLarge;
};
