import { TrackEventName } from 'Constants/AnalyticsEvent.enum';
import { isMobileApp } from 'Mobile/MobileAppUtils';
import { useAuth } from 'Providers/Authentication';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Lead, LoggedInUser } from 'Types/Auth.types';
import { isIFrame } from 'Utils/url';

type AppMode = 'authenticated' | 'unauthenticated';

export const shouldBlockAnalytics = (user: LoggedInUser | undefined): boolean =>
  (user?.impersonateMode || user?.isDemoOrg || window.location.hostname === 'localhost' || false) &&
  window.location.search.indexOf('segment=true') < 0;

// do not show intercom launcher in iframes or in the mobile app
const shouldHideIntercomLauncher = (user: LoggedInUser | undefined) => isIFrame() || isMobileApp() || user?.isDemoOrg;

export const setIntercomLauncherVisibility = (user: LoggedInUser | undefined) => {
  // @ts-ignore
  window.Intercom && window.Intercom('update', { hide_default_launcher: shouldHideIntercomLauncher(user) });
};

export const usePageViews = (mode: AppMode) => {
  const [prevLocation, setPrevLocation] = useState<string | null>(null);
  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (shouldBlockAnalytics(user)) return;

    if (prevLocation !== location.pathname) {
      setPrevLocation(location.pathname);
      window.analytics.page(location.pathname, {
        integrations: {
          All: true,
          'Google Analytics': mode === 'unauthenticated' ? true : false
        }
      });
    }
  }, [user, location, prevLocation, mode]);
};

export const identify = (user: LoggedInUser) => {
  if (shouldBlockAnalytics(user)) {
    window.analytics.reset();
  } else {
    window.analytics.identify(
      user.id.toString(),
      {
        name: user.fullName,
        email: user.email,
        role: user.role,
        'Email Sync': user.syncPermissions.emailSyncPermission,
        'Calendar Sync': user.syncPermissions.calendarEventSyncPermission,
        company: { name: user.organizationName, id: user.organizationId }
      },
      undefined
    );
    window.analytics.group(user.organizationId.toString(), { name: user.organizationName }, undefined, undefined);
    window.analytics.page('session start', { email: user.email });
  }
};

export const identifyLead = (lead: Lead) => {
  window.analytics.identify(
    lead.id.toString(),
    {
      email: lead.email,
      firstname: lead.firstName,
      lastname: lead.lastName
    },
    () => {
      window.analytics.page('complete-signup', { email: lead.email });
    }
  );
};

type TrackParams = {
  eventName: TrackEventName;
  params?: Object;
  integrations?: { [key: string]: boolean };
  callback?: () => void;
};

export const useTrack = (): {
  track: (trackParams: TrackParams) => void;
} => {
  const { user } = useAuth();

  const track = useCallback(
    (trackParams: TrackParams) => {
      const { eventName, params, integrations, callback } = trackParams;
      if (shouldBlockAnalytics(user)) {
        callback && callback();
        return;
      }

      window.analytics.track(
        eventName,
        params && params,
        {
          integrations: integrations || {
            'Google Analytics': false
          }
        },
        callback && callback
      );
    },
    [user]
  );

  const res = useMemo(
    (): {
      track: (trackParams: TrackParams) => void;
    } => ({ track }),
    [track]
  );

  return res;
};
