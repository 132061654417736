import * as React from "react";
const SvgNotificationAlert = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "notification-alert", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 162.764 613.275 C 162.859 553.586 181.549 525.535 200.165 494.377 C 218.783 463.215 237.282 428.917 237.282 356.224 C 237.282 299.054 264.447 237.78 312.294 191 C 360.124 144.237 428.922 111.658 512 111.658 C 577.197 111.658 633.6 131.722 677.976 162.96 M 823.837 494.377 C 842.452 525.534 861.134 553.762 861.228 613.45 C 855.952 673.171 826.376 710.788 769.251 733.16 C 712.229 755.491 627.56 762.918 512 762.918 C 396.461 762.918 311.805 755.605 254.784 733.329 C 197.659 711.014 168.064 673.221 162.764 613.275", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 62,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 609.868 864.522 C 553.983 926.579 466.8 927.315 410.376 864.522", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 62,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "rgb(255, 111, 110)"
}, cx: 805.421, cy: 300.938, rx: 137.344, ry: 137.344 }));
export default SvgNotificationAlert;
