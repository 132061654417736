import * as React from "react";
const SvgIntegrate = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "integrate", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 443.317 562.265 C 439.131 545.212 437.898 530.835 437.898 512.492 C 437.898 389.581 537.536 289.943 660.447 289.943 C 783.357 289.943 882.995 389.581 882.995 512.492 C 882.995 635.402 783.357 735.041 660.447 735.041", style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 153.594 561.773 C 149.408 544.721 148.175 530.344 148.175 512 C 148.175 389.09 247.813 289.453 370.725 289.453 C 493.635 289.453 593.272 389.09 593.272 512 C 593.272 634.91 493.635 734.548 370.725 734.548", transform: "matrix(-0.999463, -0.032756, 0.032756, -0.999463, 724.476955, 1035.869651)", style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor"
} }));
export default SvgIntegrate;
