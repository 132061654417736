import * as React from "react";
const SvgReports = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "reports", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 690.048 132.019 L 333.951 132.019 C 209.824 132.019 132.019 219.875 132.019 344.245 L 132.019 679.755 C 132.019 804.126 209.463 891.982 333.951 891.982 L 690.048 891.982 C 814.537 891.982 891.981 804.126 891.981 679.755 L 891.981 344.245 C 891.981 219.875 814.537 132.019 690.048 132.019 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: 60,
  fill: "none",
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 336.124 443.668 L 336.124 704.34", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 513.451 318.94 L 513.451 704.343", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 687.874 581.419 L 687.874 704.343", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }));
export default SvgReports;
