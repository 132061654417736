import * as React from "react";
const SvgLinkCopy = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "link-copy", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 645.963 594.185 L 624.156 616.7 C 563.866 678.895 466.133 678.895 405.873 616.7 C 345.579 554.534 345.579 453.7 405.873 391.505 L 457.932 337.817 C 513.555 280.411 603.763 280.411 659.416 337.817 L 667.801 346.477 M 628.211 179.639 L 650.017 157.126 C 710.309 94.929 808.041 94.929 868.304 157.126 C 928.593 219.321 928.593 320.124 868.304 382.321 L 816.241 436.008 C 760.619 493.414 670.413 493.414 614.757 436.008 L 606.374 427.347", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70,
  strokeLinecap: "round"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 242.727 145.726 L 490.098 145.726 M 878.272 533.902 L 878.272 781.271 C 878.272 834.843 834.843 878.272 781.271 878.272 L 242.727 878.272 C 189.155 878.272 145.726 834.843 145.726 781.271 L 145.726 242.727 C 145.726 189.155 189.155 145.726 242.727 145.726", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70,
  strokeLinecap: "round"
} }));
export default SvgLinkCopy;
