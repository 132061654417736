import * as React from "react";
const SvgDownload = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "download", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 511.996 154.875 L 511.996 652.159", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentcolor",
  strokeWidth: 70
}, transform: "matrix(-1, 0, 0, -1, 1023.992004, 807.033997)" }), /* @__PURE__ */ React.createElement("path", { d: "M 708.124 667.338 L 512.017 416.281 L 315.877 667.338", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentcolor",
  strokeWidth: 70
}, transform: "matrix(-1, 0, 0, -1, 1024.001038, 1083.619019)" }), /* @__PURE__ */ React.createElement("path", { d: "M 865.17 652.715 L 865.17 737.334 C 865.17 810.62 805.76 870.03 732.474 870.03 L 291.526 870.03 C 218.24 870.03 158.83 810.62 158.83 737.334 L 158.83 652.715", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentcolor",
  strokeWidth: 70
} }));
export default SvgDownload;
