import { useEffect, useState } from 'react';
import { CustomerEssentials } from 'Types/Customer.types';
import { useCustomersEssentialsQuery } from 'hooks/QueryHooks/useCustomersQuery';
import { QueryObserverOptions } from 'react-query';
import { CustomReportData } from 'Types/Reports.types';

export type CustomersMap = Map<number, CustomerEssentials>;

export const useCustomersMap = (
  options?: QueryObserverOptions<{ data: CustomReportData[] }>
): CustomersMap | undefined => {
  const [customerMap, setCustomerMap] = useState<CustomersMap>();
  const { data } = useCustomersEssentialsQuery(options);

  useEffect(() => {
    if (!data) return;
    setCustomerMap(new Map(data.data.map((r) => [r.customer.value, r])));
  }, [data]);

  return customerMap;
};
