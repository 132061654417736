import React, { useCallback, useEffect, useState } from 'react';
import { Slider as RefSlider } from 'antd';
import styled from '@emotion/styled';
import { borderRadius, times8 } from 'Constants/Styles';
import { getColorWithBrightness } from 'Utils/colors';
import { InputNumber } from 'Components/Primitives/Input/Input';

export type SegmentsValue = [number, number];
export type BandColors = [string, string, string];

const MIN_VALUE = 1;
const MAX_VALUE = 99;

export interface SegmentsSliderProps {
  initialValue?: SegmentsValue;
  colors: BandColors;
  step?: number;
  firstTresholdLabel?: string;
  secondTresholdLabel?: string;
  showInputs?: boolean;
  className?: string;
  onChange?(value: SegmentsValue): void;
}

const StyledSegmentsSlider = styled.div<{ range: SegmentsValue; colors: BandColors }>`
  .ant-slider-track {
    background-color: ${({ colors }) => colors[1]};
  }

  .ant-slider {
    width: 100%;
    margin: 0;

    &:hover,
    &:active {
      .ant-slider-track {
        background-color: ${({ colors }) => getColorWithBrightness(colors[1], 0)};
      }

      .ant-slider-handle-1 {
        &:after {
          box-shadow: ${({ colors }) => `0 0 0 2px ${getColorWithBrightness(colors[0], 0.1)}`};
        }
      }

      .ant-slider-handle-2 {
        &:after {
          box-shadow: ${({ colors }) => `0 0 0 2px ${getColorWithBrightness(colors[2], 0.1)}`};
        }
      }
    }
  }

  .ant-slider-handle-1 {
    &:after {
      box-shadow: ${({ colors }) => `0 0 0 2px ${colors[0]}`};
    }

    &:hover,
    &:active,
    &:focus {
      &:after {
        box-shadow: ${({ colors }) => `0 0 0 2px ${getColorWithBrightness(colors[0], 0.1)}`};
      }
    }
  }

  .ant-slider-handle-2 {
    &:after {
      box-shadow: ${({ colors }) => `0 0 0 2px ${colors[2]}`};
    }

    &:hover,
    &:active,
    &:focus {
      &:after {
        box-shadow: ${({ colors }) => `0 0 0 2px ${getColorWithBrightness(colors[2], 0.1)}`};
      }
    }
  }

  .ant-slider-rail {
    &::before {
      content: '';
      display: block;
      height: 100%;
      width: ${({ range }) => range[0]}%;
      background-color: ${({ colors }) => colors[0]};
      position: absolute;
      top: 0;
      left: 0;
      border-top-left-radius: ${borderRadius}px;
      border-bottom-left-radius: ${borderRadius}px;
    }

    &::after {
      content: '';
      display: block;
      height: 100%;
      width: ${({ range }) => 100 - range[1]}%;
      background-color: ${({ colors }) => colors[2]};
      position: absolute;
      top: 0;
      right: 0;
      border-top-right-radius: ${borderRadius}px;
      border-bottom-right-radius: ${borderRadius}px;
    }
  }

  .segments-slider-inputs {
    display: flex;
    margin-top: ${times8(2)}px;
  }

  .segments-slider-input-item {
    display: flex;
    align-items: center;
    margin-right: ${times8(2)}px;
    margin-bottom: 0;
  }

  .segments-slider-input-item-label {
    margin-right: ${times8()}px;
  }

  .segments-slider-number-input {
    width: 48px;

    input {
      text-align: center;
      height: 32px;
      padding: 4px;
    }
  }
`;

const getFirstValue = (value: number, secondValue: number): number => {
  if (value < MIN_VALUE || secondValue < MIN_VALUE) return MIN_VALUE;

  if (value >= secondValue) return secondValue - 1;

  return value;
};

const getSecondValue = (value: number, firstValue: number): number => {
  if (value > MAX_VALUE) return MAX_VALUE;

  if (value <= firstValue) return firstValue + 1;

  return value;
};

export const SegmentsSlider: React.FC<SegmentsSliderProps> = ({
  initialValue,
  colors,
  step = 1,
  firstTresholdLabel = 'Low',
  secondTresholdLabel = 'High',
  showInputs,
  className,
  onChange
}) => {
  const [value, setValue] = useState<SegmentsValue>([MIN_VALUE, MAX_VALUE]);

  const handleValueChange = useCallback(
    (v: number[]) => {
      const firstValue = getFirstValue(v[0], v[1]);
      const secondValue = getSecondValue(v[1], firstValue);
      const fixedValue: [number, number] = [firstValue, secondValue];
      setValue(fixedValue);
      onChange && onChange(fixedValue);
    },
    [onChange]
  );

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <StyledSegmentsSlider range={value} colors={colors} className={className}>
      <RefSlider
        range
        step={step}
        min={MIN_VALUE}
        max={MAX_VALUE}
        onChange={handleValueChange}
        value={value}
        tooltip={{ formatter: null }}
      />
      {showInputs && (
        <div className="segments-slider-inputs">
          <div className="segments-slider-input-item">
            <span className="segments-slider-input-item-label">{firstTresholdLabel}</span>
            <InputNumber
              className="segments-slider-number-input"
              value={value[0]}
              controls={false}
              min={MIN_VALUE}
              max={MAX_VALUE - 1}
              onBlur={(e) => handleValueChange([Number(e.target.value || 0), value[1]])}
              data-testid="segments-slider-input-1"
            />
          </div>
          <div className="segments-slider-input-item">
            <span className="segments-slider-input-item-label">{secondTresholdLabel}</span>
            <InputNumber
              className="segments-slider-number-input"
              value={value[1]}
              controls={false}
              min={MIN_VALUE + 1}
              max={MAX_VALUE}
              onBlur={(e) => handleValueChange([value[0], Number(e.target.value || 0)])}
              data-testid="segments-slider-input-2"
            />
          </div>
        </div>
      )}
    </StyledSegmentsSlider>
  );
};
