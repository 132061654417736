import { message as RefMessage } from 'antd';
import { simpleUID } from 'Utils/functional';

export const messageDuration = {
  short: 3,
  medium: 6,
  long: 10
};

const errorMessageMap: Map<string, string> = new Map();

export const message = {
  success: (content: string | undefined, duration?: number) => {
    RefMessage.success({
      content,
      duration
    });
  },
  error: (content: string | undefined, duration?: number) => {
    if (!content) return;
    if (Array.from(errorMessageMap.values()).includes(content)) return;

    const key = simpleUID('message-key-', 8);
    errorMessageMap.set(key, content);

    RefMessage.error({
      content,
      key,
      duration,
      onClose: () => {
        errorMessageMap.delete(key);
      }
    });
  }
};
