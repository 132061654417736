import { TreeNode } from 'Components/Primitives/Tree/Tree.types';

export const getTreeValues = (treeNodes: TreeNode[], includeParents: boolean = false): (string | number)[] => {
  const getTreeNodeIds = (nodes: TreeNode[]): (string | number)[] =>
    nodes.reduce((acc: (string | number)[], tn) => {
      if (tn.children.length > 0) {
        acc = [...acc, ...getTreeNodeIds(tn.children)].concat(includeParents ? [tn.value] : []);
      } else {
        acc = [...acc, tn.value];
      }
      return acc;
    }, []);

  return getTreeNodeIds(treeNodes);
};

export const getTreeSelectedValues = (selected: (string | number)[], treeNodes: TreeNode[]): (number | string)[] => {
  const getTreeNodeSelectedValues = (nodes: TreeNode[]): (string | number)[] =>
    nodes.reduce((acc: (string | number)[], tn) => {
      if (selected.includes(tn.value)) {
        acc = [...acc, ...(tn.children.length > 0 ? getTreeValues(tn.children) : [tn.value])];
      } else if (tn.children.length > 0) {
        acc = [...acc, ...getTreeNodeSelectedValues(tn.children)];
      }
      return acc;
    }, []);

  return getTreeNodeSelectedValues(treeNodes);
};
