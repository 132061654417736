import React, { ReactNode, useEffect } from 'react';
import FullPageSpinner from 'Components/Styled/FullPageSpinner';
import { identify } from 'Utils/analytics';
import { AuthState } from 'Types/Auth.types';
import { useLoggedInUserQuery } from 'hooks';

const UnauthContext: AuthState = {
  isAuthenticated: false,
  user: undefined,
  refreshSession: () => {}
};

const AuthContext = React.createContext<AuthState>(UnauthContext);

const AuthProvider: React.FC<{ children?: ReactNode }> = (props) => {
  const { data: user, isLoading, refetch: refreshSession } = useLoggedInUserQuery();

  useEffect(() => {
    if (user && user.id) {
      identify(user);
    }
  }, [user]);

  return isLoading ? (
    <FullPageSpinner />
  ) : user && user.id ? (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated: true,
        refreshSession
      }}
      {...props}
    />
  ) : (
    <AuthContext.Provider value={UnauthContext} {...props} />
  );
};

const useAuth = (): AuthState => React.useContext(AuthContext);

export { AuthProvider, useAuth };
