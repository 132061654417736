import React from 'react';
import ProgressCircle from 'Components/Primitives/Progress/ProgressCircle';
import ProgressLine from 'Components/Primitives/Progress/ProgressLine';

export interface ProgressProps {
  progress: number;
  type: 'circle' | 'bar';
  size: 'small' | 'middle' | 'large' | number;
  strokeWidth?: number;
  showLabel?: 'always' | 'hover' | 'never';
  labelSuffix?: string;
  progressColor?: string;
  railColor?: string;
  className?: string;
  'data-testid'?: string;
}

export const Progress: React.FC<ProgressProps> = ({
  progress,
  type,
  size,
  strokeWidth,
  showLabel,
  labelSuffix,
  progressColor,
  railColor,
  className,
  'data-testid': dataTestId
}) => {
  return type === 'circle' ? (
    <ProgressCircle
      className={className}
      progress={progress}
      size={size}
      strokeWidth={strokeWidth}
      showLabel={showLabel}
      labelSuffix={labelSuffix}
      progressColor={progressColor}
      railColor={railColor}
      data-testid={dataTestId}
    />
  ) : (
    <ProgressLine
      className={className}
      progress={progress}
      size={size}
      strokeWidth={strokeWidth}
      showLabel={showLabel}
      labelSuffix={labelSuffix}
      progressColor={progressColor}
      railColor={railColor}
      data-testid={dataTestId}
    />
  );
};
