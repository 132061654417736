export enum TrackEventName {
  Login = 'Login',
  InvitationAccepted = 'Invitation accepted',
  RegisterOrganization = 'Register organization',
  InviteUser = 'Invite a user',
  MarkEmailAsClosed = 'Mark email as closed',
  OpenCommunicationTimeline = 'Open communication timeline',
  CustomerAdded = 'Customer added',
  ReportCreate = 'Report create',
  ReportUpdate = 'Report update',
  ReportDelete = 'Report delete',
  ReportSchedule = 'Report schedule',
  ReportScheduleDelete = 'Report schedule delete',
  ReportDownload = 'Report download',
  ReportEmail = 'Report email',
  CustomReportCreate = 'Custom report create',
  CustomReportUpdate = 'Custom report update',
  CustomReportDelete = 'Custom report delete',
  MondayTicket = 'Monday ticket',
  EmailSentimentChange = 'Email sentiment change',
  ChatSentimentChange = 'Chat sentiment change',
  TicketSentimentChange = 'Ticket sentiment change',
  InsightsConfigChange = 'Insights config change',
  RelationshipScoreChange = 'Relationship score change',
  CommunicationTimelineShowMore = 'Communication timeline show more',
  ContentGenerationModalOpen = 'Content generation modal open',
  ContentGenerationEmailSend = 'Content generation email send',
  LifecycleEventAdd = 'Add lifecycle event',
  LifecycleEventRemove = 'Remove lifecycle event',
  CommunicationDeepLinkCopy = 'Communication deep link copy',
  QuestionsAndAnswersSubmit = 'Questions and answers submit',
  AddDashboard = 'Add dashboard',
  DuplicateDashboard = 'Duplicate dashboard',
  UpdateDashboard = 'Update dashboard',
  DeleteDashboard = 'Delete dashboard',
  AddDashboardCard = 'Add dashboard card',
  UpdateDashboardCard = 'Update dashboard card',
  DeleteDashboardCard = 'Delete dashboard card',
  DownloadImage = 'Download image'
}
