import { atomFamily } from 'recoil';
import { TableView } from 'Components/Table/Table.types';

export type TableData<T> = T[] | undefined;

export const TableViewAtomFam = atomFamily<TableView | undefined, string>({
  key: 'TableViewAtomFamily',
  default: undefined
});

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const TableDataAtomFam = atomFamily<TableData<any> | undefined, string>({
  key: 'TableDataAtomFam',
  default: undefined
});

export const TableColumnSelectAtomFam = atomFamily<{ ids: string[] }, string>({
  key: 'TableColumnSelectAtomFam',
  default: undefined
});

export const TableColumnOrderAtomFam = atomFamily<{ ids: string[] }, string>({
  key: 'TableColumnOrderAtomFam',
  default: undefined
});
