import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorPage, ErrorPageProps } from 'Views/ErrorPage/ErrorPage';

export const ErrorFallback: React.FC = () => {
  const { t } = useTranslation(['common']);

  const onButtonClick = useCallback(() => {
    window.location.href = '/';
  }, []);

  const buttonConfig = useMemo(
    (): ErrorPageProps['buttonConfig'] => ({
      label: t('error-page.button-label-home'),
      onClick: onButtonClick
    }),
    [t, onButtonClick]
  );

  return <ErrorPage pageMode="fullscreen" title={t('error-page.title')} buttonConfig={buttonConfig} />;
};
