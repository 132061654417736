import React, { ReactNode, useEffect, useState } from 'react';

interface UiUtilsState {
  scrollbarWidth: number;
  headerHeight: number;
}

const UiUtilsContext = React.createContext<UiUtilsState>({ scrollbarWidth: 0, headerHeight: 0 });

let appMainObserver: MutationObserver;
const observerOptions: MutationObserverInit = {
  childList: true,
  subtree: true
};

const UiUtilsProvider: React.FC<{ children?: ReactNode }> = (props) => {
  const [scrollbarWidth, setScrollbarWidth] = useState<number>(0);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    const scrollDiv = document.createElement('div');
    scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;');
    document.body.appendChild(scrollDiv);

    const w = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    setScrollbarWidth(w);
    document.documentElement.style.setProperty('--scrollWidth', `${w}px`);

    document.body.removeChild(scrollDiv);
  }, []);

  useEffect(() => {
    appMainObserver = new MutationObserver(() => {
      const appLayoutTopBar = document.getElementsByClassName('app-layout-top-bar')[0];
      appLayoutTopBar &&
        setHeaderHeight(appLayoutTopBar.getBoundingClientRect().top + appLayoutTopBar.getBoundingClientRect().height);
    });
    appMainObserver.observe(document.body, observerOptions);

    return () => {
      appMainObserver && appMainObserver.disconnect();
    };
  }, []);

  return <UiUtilsContext.Provider value={{ scrollbarWidth, headerHeight }} {...props} />;
};

const useUiUtils = (): UiUtilsState => React.useContext(UiUtilsContext);

export { UiUtilsProvider, useUiUtils };
