import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AutoComplete, Icon, Icons, Input } from 'Components/Primitives';
import { TableSearchConfig } from 'Components/Table/Table.types';
import {
  TableSearchGroup,
  TableSearchOption,
  getTableSearchOptions
} from 'Components/Table/TableControls/TableSearch/TableSearchUtils';

interface TableSearchProps<T> {
  value: string;
  data: T[];
  config: TableSearchConfig;
  className?: string;
  onChange(val: string): void;
}

const StyledInput = styled(Input)<{ showsuffix: 'show' | 'hide' }>`
  .search-icon {
    opacity: ${({ showsuffix }) => (showsuffix === 'show' ? 1 : 0)};
  }
`;

export const TableSearch = <T extends object>({ value, data, config, className, onChange }: TableSearchProps<T>) => {
  const { t } = useTranslation(['common']);
  const [options, setOptions] = useState<TableSearchGroup[]>();

  const showSuffix = useMemo((): boolean => value === '', [value]);

  const onSearch = useCallback(
    (str: string) => {
      onChange(str);
    },
    [onChange]
  );

  const onClear = useCallback(() => {
    onSearch('');
  }, [onSearch]);

  const onSelect = useCallback(
    (_: string, o: TableSearchOption) => {
      onSearch(o.value);
    },
    [onSearch]
  );

  useEffect(() => {
    setOptions(getTableSearchOptions(data, config, value));
  }, [data, config, value]);

  return (
    <AutoComplete
      data-testid="table-search"
      className={classNames(className, 'table-search')}
      value={value}
      onSearch={onSearch}
      onClear={onClear}
      onSelect={onSelect}
      allowClear={true}
      options={options}
    >
      <StyledInput
        placeholder={t('table.search-table')}
        suffix={<Icon className="search-icon" icon={Icons.Search} />}
        showsuffix={showSuffix ? 'show' : 'hide'}
      />
    </AutoComplete>
  );
};
