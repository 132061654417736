import * as React from "react";
const SvgIntegrateCancel = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "integrate_cancel", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 702.44 311.378 C 813.311 311.536 903.19 401.543 903.191 512.415 C 903.191 623.286 813.312 713.037 702.44 712.877 L 604.477 712.666", style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor",
  strokeLinecap: "round"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 568.859 214.741 L 455.141 809.259", style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor",
  strokeLinecap: "round"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 248.772 311.334 C 359.643 311.492 449.522 401.499 449.523 512.371 C 449.523 623.242 359.644 712.993 248.772 712.833 L 150.809 712.622", transform: "matrix(-1, 0, 0, -1, 570.332122, 1023.956193)", style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor",
  strokeLinecap: "round"
} }));
export default SvgIntegrateCancel;
