import * as React from "react";
const SvgSyncNow = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "sync-now", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 366.17 733.46 L 222.229 588.898 L 78.263 733.46", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentcolor",
  strokeWidth: 70
}, transform: "matrix(0.862176, -0.50661, 0.50661, 0.862176, -304.3328, 203.703677)" }), /* @__PURE__ */ React.createElement("path", { d: "M 946.103 437.534 L 802.162 292.971 L 658.196 437.534", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentcolor",
  strokeWidth: 70
}, transform: "matrix(-0.847435, 0.5309, -0.5309, -0.847435, 1675.831373, 248.919232)" }), /* @__PURE__ */ React.createElement("path", { d: "M 829.667 616.6 C 785.757 750.029 660.128 846.354 512 846.354 C 363.872 846.354 238.243 750.029 194.333 616.6", style: {
  fill: "none",
  stroke: "currentcolor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { d: "M 194.333 407.4 C 238.243 273.971 363.872 177.646 512 177.646 C 660.128 177.646 785.757 273.971 829.667 407.4", style: {
  fill: "none",
  stroke: "currentcolor",
  strokeWidth: 70
} }));
export default SvgSyncNow;
