import * as React from "react";
const SvgSentimentNeutral = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "sentiment-neutral", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("circle", { style: {
  fill: "#ffc408"
}, cx: 512, cy: 512, r: 441.976 }), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1, 0, 0, 1, 23.524643, 6.274232)" }, /* @__PURE__ */ React.createElement("path", { d: "M 126.02 122.212 A 59.467 59.467 0 0 1 66.553 181.679 A 59.467 59.467 0 0 1 7.086 122.212 A 59.467 59.467 0 0 1 66.553 62.745 A 59.467 59.467 0 0 1 126.02 122.212 Z", style: {
  fill: "#6f5606"
}, transform: "matrix(1, 0, 0, 1.420879, 239.084854, 223.2332)" }), /* @__PURE__ */ React.createElement("path", { d: "M 126.02 122.212 A 59.467 59.467 0 0 1 66.553 181.679 A 59.467 59.467 0 0 1 7.086 122.212 A 59.467 59.467 0 0 1 66.553 62.745 A 59.467 59.467 0 0 1 126.02 122.212 Z", style: {
  fill: "#6f5606"
}, transform: "matrix(1, 0, 0, 1.420879, 604.759827, 223.2332)" })), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "#6f5606",
  strokeWidth: 50,
  strokeLinecap: "round",
  fill: "none"
}, d: "M 286.411 686.098 C 443.198 686.098 583.968 686.098 737.59 686.098" }));
export default SvgSentimentNeutral;
