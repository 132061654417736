import * as React from "react";
const SvgUsers = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "users", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { id: "Stroke 1", d: "M 747.199 472.855 C 801.87 465.174 843.96 418.303 844.078 361.517 C 844.078 305.553 803.281 259.152 749.786 250.374", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 3", d: "M 819.341 604.291 C 872.287 612.207 909.243 630.744 909.243 668.955 C 909.243 695.251 891.842 712.338 863.704 723.076", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 5", fillRule: "evenodd", clipRule: "evenodd", d: "M 512.019 620.5 C 386.062 620.5 278.485 639.585 278.485 715.81 C 278.485 791.996 385.396 811.63 512.019 811.63 C 637.977 811.63 745.514 792.741 745.514 716.477 C 745.514 640.213 638.643 620.5 512.019 620.5 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 7", fillRule: "evenodd", clipRule: "evenodd", d: "M 512.019 511.704 C 594.671 511.704 661.687 444.728 661.687 362.037 C 661.687 279.385 594.671 212.37 512.019 212.37 C 429.368 212.37 362.353 279.385 362.353 362.037 C 362.04 444.415 428.545 511.43 510.922 511.704 L 512.019 511.704 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 9", d: "M 276.804 472.855 C 222.095 465.174 180.044 418.303 179.926 361.517 C 179.926 305.553 220.723 259.152 274.218 250.374", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 11", d: "M 204.659 604.291 C 151.713 612.207 114.757 630.744 114.757 668.955 C 114.757 695.251 132.158 712.338 160.296 723.076", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }));
export default SvgUsers;
