import * as React from "react";
const SvgDocOpen = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "doc-open", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 667.548 694.756 L 358.431 694.756", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: 60,
  stroke: "currentColor",
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 667.548 515.515 L 358.431 515.515", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: 60,
  stroke: "currentColor",
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 476.383 336.691 L 358.431 336.691", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: 60,
  stroke: "currentColor",
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 675.785 117.896 C 675.785 117.896 347.101 118.068 346.588 118.068 C 228.422 118.795 155.252 196.545 155.252 315.14 L 155.252 708.859 C 155.252 828.053 228.978 906.103 348.172 906.103 C 348.172 906.103 676.813 905.974 677.369 905.974 C 795.536 905.247 868.748 827.454 868.748 708.859 L 868.748 315.14 C 868.748 195.946 794.98 117.896 675.785 117.896 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: 60,
  stroke: "currentColor",
  fill: "none"
} }));
export default SvgDocOpen;
