import * as React from "react";
const SvgCalendar = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "calendar", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 175.958 414.069 L 848.392 414.069", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { d: "M 664.55 134.736 L 664.55 258.892", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { d: "M 359.798 134.736 L 359.798 258.892", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 671.891 194.304 L 352.457 194.304 C 241.66 194.304 172.457 256.026 172.457 369.481 L 172.457 710.884 C 172.457 826.117 241.66 889.264 352.457 889.264 L 671.542 889.264 C 782.688 889.264 851.542 827.18 851.542 713.739 L 851.542 369.481 C 851.879 256.026 783.039 194.304 671.891 194.304 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { d: "M 380.092 574.216 C 380.092 593.741 364.264 609.569 344.739 609.569 C 325.214 609.569 309.386 593.741 309.386 574.216 C 309.386 554.691 325.214 538.863 344.739 538.863 C 364.264 538.863 380.092 554.691 380.092 574.216 Z M 547.353 574.216 C 547.353 593.741 531.525 609.569 512 609.569 C 492.475 609.569 476.647 593.741 476.647 574.216 C 476.647 554.691 492.475 538.863 512 538.863 C 531.525 538.863 547.353 554.691 547.353 574.216 Z M 714.614 574.216 C 714.614 593.741 698.786 609.569 679.261 609.569 C 659.736 609.569 643.908 593.741 643.908 574.216 C 643.908 554.691 659.736 538.863 679.261 538.863 C 698.786 538.863 714.614 554.691 714.614 574.216 Z M 380.092 721.152 C 380.092 740.677 364.264 756.505 344.739 756.505 C 325.214 756.505 309.386 740.677 309.386 721.152 C 309.386 701.627 325.214 685.799 344.739 685.799 C 364.264 685.799 380.092 701.627 380.092 721.152 Z M 547.353 721.152 C 547.353 740.677 531.525 756.505 512 756.505 C 492.475 756.505 476.647 740.677 476.647 721.152 C 476.647 701.627 492.475 685.799 512 685.799 C 531.525 685.799 547.353 701.627 547.353 721.152 Z M 714.614 721.152 C 714.614 740.677 698.786 756.505 679.261 756.505 C 659.736 756.505 643.908 740.677 643.908 721.152 C 643.908 701.627 659.736 685.799 679.261 685.799 C 698.786 685.799 714.614 701.627 714.614 721.152 Z", style: {
  fill: "currentColor"
} }));
export default SvgCalendar;
