import * as React from "react";
const SvgWarningCircle = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "warning_circle", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 138.023 511.999 C 138.023 456.53 138.023 428.795 144.223 402.97 C 149.72 380.075 158.786 358.187 171.089 338.111 C 184.965 315.467 204.37 296.062 243.177 257.254 L 257.256 243.176 C 296.064 204.367 315.468 184.964 338.113 171.087 C 358.189 158.784 380.076 149.718 402.972 144.221 C 428.796 138.022 456.531 138.022 512.001 138.022 C 567.471 138.022 595.207 138.022 621.031 144.221 C 643.926 149.718 665.814 158.784 685.891 171.087 C 708.533 184.964 727.936 204.367 766.744 243.176 L 780.824 257.254 C 819.632 296.062 839.036 315.467 852.914 338.111 C 865.216 358.187 874.281 380.075 879.779 402.97 C 885.977 428.795 885.977 456.53 885.977 511.999 C 885.977 567.47 885.977 595.204 879.779 621.028 C 874.281 643.922 865.216 665.81 852.914 685.887 C 839.036 708.534 819.632 727.937 780.824 766.745 L 766.744 780.826 C 727.936 819.634 708.533 839.037 685.891 852.909 C 665.814 865.218 643.926 874.283 621.031 879.78 C 595.207 885.978 567.471 885.978 512.001 885.978 C 456.531 885.978 428.796 885.978 402.972 879.78 C 380.076 874.283 358.189 865.218 338.113 852.909 C 315.468 839.037 296.064 819.634 257.256 780.826 L 243.177 766.745 C 204.37 727.937 184.965 708.534 171.089 685.887 C 158.786 665.81 149.72 643.922 144.223 621.028 C 138.023 595.204 138.023 567.47 138.023 511.999 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 72
} }), /* @__PURE__ */ React.createElement("path", { d: "M 511.992 530.698 L 511.992 343.71", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 72
} }), /* @__PURE__ */ React.createElement("ellipse", { cx: 512, cy: 661.808, rx: 42.06, ry: 42.06, style: {
  fill: "currentColor",
  stroke: "none"
} }));
export default SvgWarningCircle;
