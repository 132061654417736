import * as React from "react";
const SvgArrowLeft = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "arrow-left", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 512.004 200.768 L 512.004 823.225", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
}, transform: "matrix(0, -1, 1, 0, 0.007538, 1024.000519)" }), /* @__PURE__ */ React.createElement("path", { d: "M 576.297 637.529 L 326.318 386.472 L 76.297 637.529", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
}, transform: "matrix(0, -1, 1, 0, -185.703491, 838.297485)" }));
export default SvgArrowLeft;
