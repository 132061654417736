import { FilterMetadata, FilterType } from 'Components/Filters/Filter.types';

export enum FilterMetadataApiKeys {
  // dateRange
  DateRangeKey = 'dateRange',
  // customer
  CustomerIdKey = 'customer.id',
  CustomerOwnerKey = 'customer.owner',
  CustomerTierKey = 'customer.tier',
  CustomerStatusKey = 'customer.status',
  CustomerRenewalDateKey = 'customer.renewalDate',
  CustomerJourneyKey = 'customer.journey',
  // customerBuckets
  CustomerBucketsHealthKey = 'customerBuckets.health',
  CustomerBucketsEngagementKey = 'customerBuckets.engagement',
  CustomerBucketsSentimentKey = 'customerBuckets.sentiment',
  CustomerBucketsOpenItemsKey = 'customerBuckets.openItems',
  CustomerBucketsResponseTimeKey = 'customerBuckets.responseTime',
  // user
  UserIdKey = 'user.id',
  UserLoginStateKey = 'user.loginState',
  // stakeholder
  StakeholderIdKey = 'stakeholder.id',
  StakeholderStatusKey = 'stakeholder.statuses',
  StakeholderRoleKey = 'stakeholder.roles',
  // insights
  InsightTypesKey = 'insight.types',
  // Communication
  CommunicationTypedKey = 'communication.type',
  CommunicationIncludeIgnoredKey = 'communication.includeIgnored',
  CommunicationSentimentKey = 'communication.sentimentType',
  CommunicationOpenCloseKey = 'communication.openClosed',
  CommunictionThreadIds = 'communication.threadIds',
  // Events
  EventIdKey = 'event.id',
  EventTypeKey = 'event.type',
  // TextSearch
  TextSearchQueryKey = 'textSearch.query',
  // topic
  TopicIdKey = 'topic.id',
  AiTopicIdKey = 'topic.aiTopicId',
  // threadId
  ThreadIdKey = 'threadId',
  // ticketId
  TicketIdKey = 'ticketId'
}

export const FilterMetadataApi: Record<FilterMetadataApiKeys, FilterMetadata> = {
  //
  // dateRange
  //
  [FilterMetadataApiKeys.DateRangeKey]: {
    type: FilterType.DateRange,
    key: FilterMetadataApiKeys.DateRangeKey
  },
  //
  // customer
  //
  [FilterMetadataApiKeys.CustomerIdKey]: {
    type: FilterType.Customer,
    key: FilterMetadataApiKeys.CustomerIdKey
  },
  [FilterMetadataApiKeys.CustomerOwnerKey]: {
    type: FilterType.User,
    key: FilterMetadataApiKeys.CustomerOwnerKey
  },
  [FilterMetadataApiKeys.CustomerTierKey]: {
    type: FilterType.Tier,
    key: FilterMetadataApiKeys.CustomerTierKey
  },
  [FilterMetadataApiKeys.CustomerStatusKey]: {
    type: FilterType.CustomerStatus,
    key: FilterMetadataApiKeys.CustomerStatusKey
  },
  [FilterMetadataApiKeys.CustomerRenewalDateKey]: {
    type: FilterType.DateRange,
    key: FilterMetadataApiKeys.CustomerRenewalDateKey
  },
  [FilterMetadataApiKeys.CustomerJourneyKey]: {
    type: FilterType.JourneyPhase,
    key: FilterMetadataApiKeys.CustomerJourneyKey
  },
  //
  // customerBuckets
  //
  [FilterMetadataApiKeys.CustomerBucketsHealthKey]: {
    type: FilterType.Score,
    key: FilterMetadataApiKeys.CustomerBucketsHealthKey
  },
  [FilterMetadataApiKeys.CustomerBucketsEngagementKey]: {
    type: FilterType.Score,
    key: FilterMetadataApiKeys.CustomerBucketsEngagementKey
  },
  [FilterMetadataApiKeys.CustomerBucketsSentimentKey]: {
    type: FilterType.Score,
    key: FilterMetadataApiKeys.CustomerBucketsSentimentKey
  },
  [FilterMetadataApiKeys.CustomerBucketsOpenItemsKey]: {
    type: FilterType.Score,
    key: FilterMetadataApiKeys.CustomerBucketsOpenItemsKey
  },
  [FilterMetadataApiKeys.CustomerBucketsResponseTimeKey]: {
    type: FilterType.Score,
    key: FilterMetadataApiKeys.CustomerBucketsResponseTimeKey
  },
  //
  // user
  //
  [FilterMetadataApiKeys.UserIdKey]: {
    type: FilterType.User,
    key: FilterMetadataApiKeys.UserIdKey
  },
  [FilterMetadataApiKeys.UserLoginStateKey]: {
    type: FilterType.ActiveInactive,
    key: FilterMetadataApiKeys.UserLoginStateKey
  },
  //
  // stakeholder
  //
  [FilterMetadataApiKeys.StakeholderIdKey]: {
    type: FilterType.Stakeholders,
    key: FilterMetadataApiKeys.StakeholderIdKey
  },
  [FilterMetadataApiKeys.StakeholderStatusKey]: {
    type: FilterType.ActiveInactive,
    key: FilterMetadataApiKeys.StakeholderStatusKey
  },
  [FilterMetadataApiKeys.StakeholderRoleKey]: {
    type: FilterType.StakeholderRole,
    key: FilterMetadataApiKeys.StakeholderRoleKey
  },
  //
  // insights
  //
  [FilterMetadataApiKeys.InsightTypesKey]: {
    type: FilterType.Insight,
    key: FilterMetadataApiKeys.InsightTypesKey
  },
  //
  // Communication
  //
  [FilterMetadataApiKeys.CommunicationTypedKey]: {
    type: FilterType.CommType,
    key: FilterMetadataApiKeys.CommunicationTypedKey
  },
  [FilterMetadataApiKeys.CommunicationIncludeIgnoredKey]: {
    type: FilterType.Boolean,
    key: FilterMetadataApiKeys.CommunicationIncludeIgnoredKey
  },
  [FilterMetadataApiKeys.CommunicationSentimentKey]: {
    type: FilterType.Sentiment,
    key: FilterMetadataApiKeys.CommunicationSentimentKey
  },
  [FilterMetadataApiKeys.CommunicationOpenCloseKey]: {
    type: FilterType.OpenClosed,
    key: FilterMetadataApiKeys.CommunicationOpenCloseKey
  },
  [FilterMetadataApiKeys.CommunictionThreadIds]: {
    type: FilterType.Text,
    key: FilterMetadataApiKeys.CommunictionThreadIds
  },
  //
  // Events
  //
  [FilterMetadataApiKeys.EventIdKey]: {
    type: FilterType.Number,
    key: FilterMetadataApiKeys.EventIdKey
  },
  [FilterMetadataApiKeys.EventTypeKey]: {
    type: FilterType.Event,
    key: FilterMetadataApiKeys.EventTypeKey
  },
  //
  // TextSearch
  //
  [FilterMetadataApiKeys.TextSearchQueryKey]: {
    type: FilterType.Text,
    key: FilterMetadataApiKeys.TextSearchQueryKey
  },
  //
  // TextSearch
  //
  [FilterMetadataApiKeys.TopicIdKey]: {
    type: FilterType.Number,
    key: FilterMetadataApiKeys.TopicIdKey
  },
  [FilterMetadataApiKeys.AiTopicIdKey]: {
    type: FilterType.Number,
    key: FilterMetadataApiKeys.AiTopicIdKey
  },
  //
  // ThreadId
  //
  [FilterMetadataApiKeys.ThreadIdKey]: {
    type: FilterType.Text,
    key: FilterMetadataApiKeys.ThreadIdKey
  },
  //
  // TicketId
  //
  [FilterMetadataApiKeys.TicketIdKey]: {
    type: FilterType.Text,
    key: FilterMetadataApiKeys.TicketIdKey
  }
};
