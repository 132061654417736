import React from 'react';
import styled from '@emotion/styled';
import { Button, ButtonProps, Icon, Icons } from 'Components/Primitives';
import classNames from 'classnames';
import { Colors, times8 } from 'Constants/Styles';
import { getColorWithBrightness } from 'Utils/colors';

export interface RoundAddButtonProps {
  title: string;
  open?: boolean;
  showTitleOnHover?: boolean;
  className?: string;
  'data-testid'?: string;
  onClick?: ButtonProps['onClick'];
}

const StyledRoundAddButton = styled(Button)`
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: ${Colors.Primary};
  .plus-title,
  .plus-circle {
    display: inline-block;
    vertical-align: middle;
  }
  .plus-circle {
    position: relative;
    margin-right: ${times8(0.5)}px;
    border: 1px solid ${Colors.Primary};
    transition: transform 0.1s ease-out;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    font-size: 16px;
    text-align: center;
  }
  &.open .plus-circle {
    transform: rotate(45deg);
  }
  &.show-title-on-hover {
    .plus-title {
      white-space: nowrap;
      pointer-events: none;
      width: 0;
      opacity: 0;
      transition:
        transform 0.1s ease-out,
        opacity 0.1s ease-out;
      transform: translateX(-${times8(0.5)}px);
    }
    &:hover {
      .plus-title {
        width: auto;
        pointer-events: auto;
        opacity: 1;
        transform: translateX(0);
      }
      .plus-circle {
        transform: rotate(90deg);
      }
    }
  }
  &:hover {
    color: ${getColorWithBrightness(Colors.Primary, 0.2)} !important;
    .plus-circle {
      border-color: ${getColorWithBrightness(Colors.Primary, 0.2)} !important;
    }
  }
`;

export const RoundAddButton: React.FC<RoundAddButtonProps> = ({
  title,
  open,
  showTitleOnHover,
  className,
  'data-testid': dataTestId,
  onClick
}) => (
  <StyledRoundAddButton
    type="link"
    data-testid={dataTestId}
    className={classNames(className, { open }, showTitleOnHover ? 'show-title-on-hover' : '')}
    onClick={onClick}
  >
    <div className="plus-circle">
      <Icon icon={Icons.Plus} />
    </div>
    <span className="plus-title">{title}</span>
  </StyledRoundAddButton>
);
