import * as React from "react";
const SvgCalendarMinimal = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "calendar-minimal", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 139.219 460.095 L 885.177 460.095", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { d: "M 681.239 150.217 L 681.239 287.942", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { d: "M 343.156 150.217 L 343.156 287.942", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 689.378 216.305 L 335.008 216.305 C 212.103 216.305 135.336 274.696 135.336 382.025 L 135.336 705.026 C 135.336 814.044 212.103 873.783 335.008 873.783 L 688.99 873.783 C 812.285 873.783 888.663 815.057 888.663 707.725 L 888.663 382.025 C 889.048 274.696 812.67 216.305 689.378 216.305 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }));
export default SvgCalendarMinimal;
