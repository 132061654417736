import React, { useMemo, useState } from 'react';
import { lineSizeByPropSize, PROGRESS_COLOR, RAIL_COLOR } from 'Components/Primitives/Progress/Progress.consts';
import styled from '@emotion/styled';
import { times8 } from 'Constants/Styles';
import { ProgressProps } from 'Components/Primitives/Progress/Progress';
import { isMobileApp } from 'Mobile/MobileAppUtils';

const StyledProgressLine = styled.div<{
  progColor: string;
  railColor: string;
  lineSize: number;
  strokeWidth: number;
  showLabel: ProgressProps['showLabel'];
}>`
  display: flex;
  align-items: center;

  .progress-bar {
    position: relative;
    width: ${({ lineSize }) => lineSize}px;
    height: ${({ strokeWidth }) => strokeWidth}px;
    border-radius: ${({ strokeWidth }) => strokeWidth * 0.5}px;
    background-color: ${({ railColor }) => railColor};
    overflow: hidden;
  }
  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: ${({ strokeWidth }) => strokeWidth}px;
    border-radius: ${({ strokeWidth }) => Math.ceil(strokeWidth * 0.5)}px;
    background-color: ${({ progColor }) => progColor};
    transition: width 0.4s ease-out;
  }
  .label {
    margin-left: ${times8()}px;
    opacity: ${({ showLabel }) => (showLabel === 'hover' ? 0 : 1)};
  }
  &:hover {
    .label {
      opacity: 1;
    }
  }
`;

const ProgressLine: React.FC<Omit<ProgressProps, 'type'>> = ({
  progress,
  size,
  strokeWidth: strokeWidthProp,
  showLabel = 'always',
  progressColor = PROGRESS_COLOR,
  railColor = RAIL_COLOR,
  labelSuffix,
  className,
  'data-testid': dataTestId
}) => {
  const [isMobile] = useState<boolean>(isMobileApp());
  const lineSize = useMemo(() => (typeof size === 'number' ? size : lineSizeByPropSize[size]), [size]);
  const progressWidth = useMemo(() => Math.min((progress / 100) * lineSize, lineSize), [lineSize, progress]);

  const strokeWidth = useMemo(
    (): number => (strokeWidthProp ? strokeWidthProp : isMobile ? 4 : 6),
    [strokeWidthProp, isMobile]
  );

  return (
    <StyledProgressLine
      className={className}
      progColor={progressColor}
      railColor={railColor}
      lineSize={lineSize}
      strokeWidth={strokeWidth}
      showLabel={showLabel}
      data-testid={dataTestId}
    >
      <div className="progress-bar">
        <div className="progress" style={{ width: progressWidth }} />
      </div>
      {showLabel !== 'never' && (
        <span className="label">
          {Math.round(Math.min(progress, 100))}
          {labelSuffix && <>{labelSuffix}</>}
        </span>
      )}
    </StyledProgressLine>
  );
};

export default ProgressLine;
