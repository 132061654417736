import * as React from "react";
const SvgStairsUp = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "stairs-up", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 767.869 377.539 L 588.657 211.947 L 416.614 387.575", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 90
} }), /* @__PURE__ */ React.createElement("path", { d: "M 256.041 811.947 L 414.464 811.947 L 414.464 594.742 L 590.808 594.742 L 590.808 283.631", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 90
} }));
export default SvgStairsUp;
