import styled from '@emotion/styled';
import { borderRadius, Colors, mediaQueryMax, times8 } from 'Constants/Styles';
import { Link } from 'react-router-dom';

export interface WidgetBoxProps {
  borderColor?: string | null;
  backgroundColor?: string;
}

export const WidgetBox = styled.div<WidgetBoxProps>`
  padding: ${times8(3)}px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  border: ${({ borderColor }) => (borderColor === null ? 'none' : `1px solid ${borderColor || Colors.Border}`)};
  border-radius: ${borderRadius}px;

  // Mobile
  ${mediaQueryMax.small} {
    padding: ${times8()}px;
  }
`;

export const LinkBox = styled(Link)<WidgetBoxProps>`
  display: block;
  padding: ${times8(3)}px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  border: ${({ borderColor }) => (borderColor === null ? 'none' : `1px solid ${borderColor || Colors.Border}`)};
  border-radius: ${borderRadius}px;
  &:hover {
    text-decoration: none;
  }

  // Mobile
  ${mediaQueryMax.small} {
    padding: ${times8()}px;
  }
`;
