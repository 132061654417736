import styled from '@emotion/styled';
import { mediaQueryMin, times8 } from 'Constants/Styles';
import { css } from '@emotion/react';
import { nonNullable } from 'Utils/functional';

export interface GridLayoutProps {
  colgap?: number;
  rowgap?: number;
}

export const GridColomnsSpan = css`
  .col-2 {
    grid-column-start: span 2;
  }
  .col-3 {
    grid-column-start: span 3;
  }
  .col-4 {
    grid-column-start: span 4;
  }
  .col-5 {
    grid-column-start: span 5;
  }
  .col-6 {
    grid-column-start: span 6;
  }
  .col-8 {
    grid-column-start: span 8;
  }
  .col-12 {
    grid-column-start: span 12;
  }
`;

export const GridLayout = styled.div<GridLayoutProps>`
  display: grid;
  grid-template-columns: repeat(12, [col] 1fr);
  grid-column-gap: ${({ colgap }) => (nonNullable(colgap) ? colgap : times8(3))}px;
  grid-row-gap: ${({ rowgap }) => (nonNullable(rowgap) ? rowgap : times8(2))}px;
  ${GridColomnsSpan};

  ${mediaQueryMin.xSmall} {
    .col-sm-3 {
      grid-column-start: span 3;
    }
    .col-sm-4 {
      grid-column-start: span 4;
    }
    .col-sm-6 {
      grid-column-start: span 6;
    }
    .col-sm-12 {
      grid-column-start: span 12;
    }
  }

  ${mediaQueryMin.small} {
    .col-md-3 {
      grid-column-start: span 3;
    }
    .col-md-4 {
      grid-column-start: span 4;
    }
    .col-md-6 {
      grid-column-start: span 6;
    }
    .col-md-12 {
      grid-column-start: span 6;
    }
  }

  ${mediaQueryMin.medium} {
    .col-lg-1 {
      grid-column-start: span 1;
    }
    .col-lg-2 {
      grid-column-start: span 2;
    }
    .col-lg-3 {
      grid-column-start: span 3;
    }
    .col-lg-4 {
      grid-column-start: span 4;
    }
    .col-lg-6 {
      grid-column-start: span 6;
    }
  }

  ${mediaQueryMin.xLarge} {
    .col-xl-1 {
      grid-column-start: span 1;
    }
    .col-xl-2 {
      grid-column-start: span 2;
    }
    .col-xl-3 {
      grid-column-start: span 3;
    }
    .col-xl-4 {
      grid-column-start: span 4;
    }
    .col-xl-6 {
      grid-column-start: span 6;
    }
  }

  ${mediaQueryMin.xxLarge} {
    .col-xxl-1 {
      grid-column-start: span 1;
    }
    .col-xxl-2 {
      grid-column-start: span 2;
    }
    .col-xxl-3 {
      grid-column-start: span 3;
    }
    .col-xxl-4 {
      grid-column-start: span 4;
    }
    .col-xxl-6 {
      grid-column-start: span 6;
    }
  }
`;
