import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { PaginationState, Table } from '@tanstack/react-table';
import { isNumber } from 'Utils/functional';
import { times8 } from 'Constants/Styles';
import { Button, Icon, Icons, InputNumber, Select, SelectChangeValue, SelectOption } from 'Components/Primitives';

interface PaginationProps<T> {
  table: Table<T>;
  onChange(paginationState: PaginationState): void;
}

const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  .page-num {
    margin: 0 ${times8()}px;
    position: relative;
    color: #666;

    .page-input {
      position: relative;
      border-radius: 0;
      width: 64px;
      border-bottom: 1px solid #ccc;
      box-shadow: none !important;
      &:hover,
      &:focus,
      &:active {
        border-bottom: 1px solid black;
      }
      input {
        text-align: center;
        padding: 0;
      }
    }
    .display-page-num {
      text-align: center;
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: transform 0.1s;
    }
    &.focus {
      .display-page-num {
        transform: scale(0.7) translateY(32px);
      }
    }
  }
  .per-page-select {
    margin-left: ${times8(2)}px;
  }
`;

export const Pagination = <T extends object>(props: PaginationProps<T>) => {
  const { t } = useTranslation(['common']);
  const ref = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [pageNumVal, setPageNumVal] = useState<number | null>(null);
  const [perPageVal, setPerPageVal] = useState<number>(0);

  const { table, onChange } = props;

  const totalPages = table.getPageCount();

  const emitChange = useCallback(
    (paginationState: PaginationState) => {
      onChange(paginationState);
    },
    [onChange]
  );

  const perPageOptions = useMemo((): SelectOption[] => {
    return [10, 20, 50, 100].map((pageSize) => ({
      label: t('table.pagination.per-page-option', { num: pageSize }),
      value: pageSize
    }));
  }, [t]);

  const onNext = useCallback(() => {
    table.nextPage();
    const state = table.getState().pagination;
    emitChange({ ...state, pageIndex: state.pageIndex + 1 });
  }, [table, emitChange]);

  const onPrev = useCallback(() => {
    table.previousPage();
    const state = table.getState().pagination;
    emitChange({ ...state, pageIndex: state.pageIndex - 1 });
  }, [table, emitChange]);

  const onPerPageChange = useCallback(
    (e: SelectChangeValue) => {
      setPerPageVal(Number(e));
      table.setPageSize(Number(e));
      const state = table.getState().pagination;
      emitChange({ ...state, pageSize: Number(e) });
    },
    [table, emitChange]
  );

  const onPageNumChange = useCallback(
    (e: number | null) => {
      if (isNumber(e)) {
        const total = table.getPageCount();
        const changeVal = e < 1 ? 1 : e > total ? total : e;
        table.setPageIndex(changeVal - 1);
        setPageNumVal(changeVal);
        const state = table.getState().pagination;
        emitChange({ ...state, pageIndex: changeVal - 1 });
      } else {
        setPageNumVal(null);
      }
    },
    [table, emitChange]
  );

  const onPageNumFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const onPageNumBlur = useCallback(() => {
    setPageNumVal(null);
    setIsFocus(false);
  }, []);

  useEffect(() => {
    setPerPageVal(table.getState().pagination.pageSize);
  }, [table]);

  useEffect(() => {
    const current = table.getState().pagination.pageIndex;
    if (totalPages > 0 && current > totalPages) {
      table.setPagination({ ...table.getState().pagination, pageIndex: totalPages - 1 });
    }
  }, [totalPages, table, emitChange]);

  return (
    <StyledPagination className="pagination">
      <Button onClick={onPrev} disabled={!table.getCanPreviousPage()} type="link">
        <Icon icon={Icons.ChevronLeft} />
        {t('table.pagination.prev')}
      </Button>

      <span className={`page-num${isFocus ? ' focus' : ''}`}>
        <InputNumber
          className="page-input"
          ref={ref}
          value={pageNumVal}
          variant={'borderless'}
          controls={false}
          onChange={onPageNumChange}
          onFocus={onPageNumFocus}
          onBlur={onPageNumBlur}
        />
        <span className="display-page-num">
          {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
        </span>
      </span>

      <Button onClick={onNext} disabled={!table.getCanNextPage()} type="link">
        {t('table.pagination.next')}
        <Icon icon={Icons.ChevronRight} />
      </Button>

      <Select
        className="per-page-select"
        borderless={true}
        showSearch={false}
        value={perPageVal}
        options={perPageOptions}
        onChange={onPerPageChange}
      />
    </StyledPagination>
  );
};
