/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { normalizedStringValue } from 'Utils/text';
import { Highlight } from 'Components/Primitives';
import { TableSearchConfig } from 'Components/Table/Table.types';
import { nonNullable } from 'Utils/functional';

type TableData<T> = T[];

export interface TableSearchGroup {
  label: string;
  value: string;
  fieldkey: string[];
  options: TableSearchOption[];
}

export interface TableSearchOption {
  value: string;
  label: ReactNode;
  fieldkey: string[];
}

export const getTableSearchOptions = (
  data: TableData<any>,
  config: TableSearchConfig,
  searchVal: string
): TableSearchGroup[] => {
  return config.fields
    .map((field) => {
      const options: TableSearchOption[] = (data || [])
        .reduce((acc: TableData<any>, row: any) => {
          if (row.subRows && row.subRows.length > 0) {
            acc.push(row, ...row.subRows);
          } else {
            acc.push(row);
          }
          return acc;
        }, [])
        .filter((d) => {
          const dVal = get(d, field.accessor.join('.'), null);
          return typeof dVal === 'string'
            ? normalizedStringValue(dVal, true).includes(normalizedStringValue(searchVal.toLocaleLowerCase()))
            : true;
        })
        .map((d) => {
          const dVal = get(d, field.accessor.join('.'), null);
          return {
            label: dVal ? (
              typeof dVal === 'string' && field.labelFormater ? (
                field.labelFormater(dVal.toString())
              ) : (
                <Highlight text={dVal} wholeWordOnly={false} highlight={[searchVal]} />
              )
            ) : (
              ''
            ),
            fieldkey: field.accessor,
            value: dVal
          };
        }) as TableSearchOption[];
      return {
        label: field.label,
        value: field.label,
        fieldkey: field.accessor,
        options: uniqBy(options, 'value').filter((o) => nonNullable(o.value))
      };
    })
    .filter((group) => group.options.length > 0);
};

const isSearchInVal = (val: any, searchVal: string): boolean => {
  return typeof val === 'string'
    ? normalizedStringValue(val, true).includes(normalizedStringValue(searchVal.toLocaleLowerCase()))
    : false;
};

export const getTableFilteredData = (
  data: TableData<any>,
  config: TableSearchConfig,
  searchVal: string
): TableData<any> => {
  return data.filter((r) => {
    const matchingSubRows = r.subRows && r.subRows.length > 0 ? getTableFilteredData(r.subRows, config, searchVal) : [];
    return config.fields.some((field) => {
      const val = get(r, field.accessor.join('.'), null);
      return isSearchInVal(val, searchVal) || matchingSubRows.length > 0;
    });
  });
};
