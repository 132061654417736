import { CustomerEssentials, CustomerStatus } from 'Types/Customer.types';
import { normalizedStringValue } from 'Utils/text';
import { AvatarAndTitle, TreeSelectValue, TreeNode } from 'Components/Primitives';
import { CustomerStatusLabel } from 'Components/Customers/Customers.consts';
import { nonNullable } from 'Utils/functional';
import { CustomersMap } from 'hooks';

type CustomerMapByStatus = Record<CustomerStatus, CustomerEssentials[]>;

const getCustomersTree = (customersStatusMap: CustomerMapByStatus, searchTerm: string | undefined): TreeNode[] => {
  const nonEmptyStatuses: CustomerStatus[] = (Object.keys(customersStatusMap) as CustomerStatus[]).filter(
    (status) => customersStatusMap[status].length > 0
  );

  return nonEmptyStatuses.length > 1
    ? nonEmptyStatuses.map((status) => ({
        title: CustomerStatusLabel[status],
        value: status,
        selectable: false,
        searchValue: '',
        children: getCustomersChildrenTree(customersStatusMap[status], searchTerm)
      }))
    : getCustomersChildrenTree(customersStatusMap[nonEmptyStatuses[0]] || [], searchTerm);
};

const getCustomersChildrenTree = (customers: CustomerEssentials[], searchTerm: string | undefined): TreeNode[] => {
  return customers.map((customer) => {
    return {
      title: (
        <AvatarAndTitle
          size="small"
          title={normalizedStringValue(customer.customer.data.name)}
          avatarSrc={customer.customer.data.icon}
          highlight={searchTerm ? [searchTerm] : undefined}
        />
      ),
      searchValue: normalizedStringValue(customer.customer.data.name),
      value: customer.customer.value,
      selectable: true,
      children: []
    };
  });
};

const getCustomersStatusMap = (customers: CustomerEssentials[]): CustomerMapByStatus =>
  customers.reduce(
    (acc: CustomerMapByStatus, customer) => {
      if (customer.status.value) {
        acc[customer.status.value].push(customer);
      }
      return acc;
    },
    {
      [CustomerStatus.Active]: [],
      [CustomerStatus.Churned]: [],
      [CustomerStatus.Prospect]: []
    }
  );

export const getCustomersTreeSelectOptions = (
  customers: CustomerEssentials[],
  searchTerm: string | undefined
): TreeNode[] => {
  const customersStatusMap = getCustomersStatusMap(customers);
  const customersTree = getCustomersTree(customersStatusMap, searchTerm);
  return customersTree.sort((a: TreeNode, b: TreeNode) => b.children.length - a.children.length);
};

export const getSelectedCustomers = (
  selectedIds: TreeSelectValue,
  customersMap: CustomersMap | undefined
): CustomerEssentials | CustomerEssentials[] | null => {
  return selectedIds && customersMap
    ? typeof selectedIds === 'number'
      ? customersMap.get(selectedIds) || null
      : Array.isArray(selectedIds)
        ? (selectedIds as number[]).map((id) => customersMap.get(id) || null).filter(nonNullable)
        : null
    : null;
};
