import * as React from "react";
const SvgBubbles = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "bubbles", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 863.254 547.154 C 863.254 644.146 784.594 722.753 687.599 722.753 C 590.607 722.753 512 644.146 512 547.154 C 512 450.106 590.607 371.499 687.599 371.499 C 784.594 371.499 863.254 450.106 863.254 547.154 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 441.75 318.824 C 441.75 367.321 402.418 406.624 353.922 406.624 C 305.425 406.624 266.123 367.321 266.123 318.824 C 266.123 270.3 305.425 230.997 353.922 230.997 C 402.418 230.997 441.75 270.3 441.75 318.824 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 406.624 670.086 C 406.624 737.98 351.561 793.004 283.666 793.004 C 215.77 793.004 160.746 737.98 160.746 670.086 C 160.746 602.15 215.77 547.126 283.666 547.126 C 351.561 547.126 406.624 602.15 406.624 670.086 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }));
export default SvgBubbles;
