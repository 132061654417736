import axios from 'axios';

export type ErrorResponse = { id: string; message?: string };

const backendApi = axios.create({
  baseURL: '/api',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  transformRequest: (data) => JSON.stringify(data)
});

const backendApiCsv = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'text/csv, */*'
  },
  responseType: 'blob',
  transformRequest: (data) => JSON.stringify(data)
});

backendApi.interceptors.response.use(undefined, (error) => {
  if (error.response?.status === 401) {
    window.location.href = '/';
  }
  if (error.response?.status === 503 && window.location.pathname !== '/error') {
    window.location.href = '/error';
  }
  return Promise.reject(error);
});

export { backendApi, backendApiCsv };
