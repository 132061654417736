import * as React from "react";
const SvgCopy = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "copy", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("rect", { x: 369.488, y: 363.382, width: 507.621, height: 499.924, style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
}, rx: 119.814, ry: 119.814 }), /* @__PURE__ */ React.createElement("path", { d: "M 277.992 160.693 L 489.918 160.693 C 562.323 160.693 613.253 182.949 613.253 255.354 M 244.089 624.547 C 172.749 623.315 146.891 575.355 146.891 503.72 L 146.891 291.795 C 146.891 219.39 205.587 160.693 277.992 160.693", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60,
  strokeLinecap: "round",
  strokeLinejoin: "round"
} }));
export default SvgCopy;
