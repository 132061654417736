import * as React from "react";
const SvgArrowRight = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "arrow-right", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 511.997 200.776 L 511.997 823.233", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
}, transform: "matrix(0, 1, -1, 0, 1024.001495, 0.007477)" }), /* @__PURE__ */ React.createElement("path", { d: "M 947.705 637.529 L 697.726 386.472 L 447.705 637.529", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
}, transform: "matrix(0, 1, -1, 0, 1209.70549, -185.704514)" }));
export default SvgArrowRight;
