import React, { ReactNode, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { borderRadius, Colors, hexAlpha10, hexAlpha15, times8 } from 'Constants/Styles';
import { Icon } from 'Components/Primitives/Icon/Icon';
import { Icons } from 'Components/Primitives/Icon/Icon.types';
import { Button } from 'Components/Primitives/Button/Button';
import { Tooltip } from 'Components/Primitives/Tooltip/Tooltip';
import { getColorWithBrightness } from 'Utils/colors';

export interface TagProps {
  size?: TagSize;
  color?: string;
  className?: string;
  selected?: boolean;
  tooltip?: string;
  border?: boolean;
  children?: ReactNode;
  'data-testid'?: string;
  onClick?: () => void;
  onClose?: () => void;
}

type TagSize = 'small' | 'middle' | 'large';

const lineHeightByTagSize: Record<TagSize, number> = {
  small: times8(3),
  middle: times8(4),
  large: times8(5)
};

const fontSizeByTagSize: Record<TagSize, number | 'inherit'> = {
  small: 12,
  middle: 'inherit',
  large: 'inherit'
};

const StyledTag = styled.span<{
  size: TagSize;
  color: TagProps['color'];
  border?: boolean;
}>`
  position: relative;
  display: inline-block;
  padding: 0 10px;
  line-height: ${({ size }) => `${lineHeightByTagSize[size]}px`};
  font-size: ${({ size }) =>
    typeof fontSizeByTagSize[size] === 'number' ? `${fontSizeByTagSize[size]}px` : fontSizeByTagSize[size]};
  border-radius: ${borderRadius}px;
  transition: none;
  border: ${({ border, color }) => (border && color ? `1px solid ${getColorWithBrightness(color, -0.1)}` : 'none')};
  font-weight: 500;
  white-space: nowrap;
  color: ${({ color }) => (color ? getColorWithBrightness(color, -0.1) : getColorWithBrightness(Colors.Primary, -0.1))};
  background-color: ${({ color }) => (color ? `${color}${hexAlpha10}` : `${Colors.Primary}${hexAlpha10}`)};

  .tag-content,
  .tag-close-button {
    display: inline-block;
    vertical-align: middle;
  }

  .tag-close-button {
    color: ${({ color }) => (color ? color : Colors.Primary)};
    margin-left: 4px;
    .tag-close-button-icon {
      padding: 4px;
      font-size: 12px;
      svg path {
        stroke-width: 100 !important;
      }
    }
  }

  * {
    white-space: nowrap;
  }

  &.clickable {
    cursor: pointer;
    &:hover {
      background-color: ${({ color }) => (color ? `${color}${hexAlpha15}` : `${Colors.Primary}${hexAlpha15}`)};
    }
  }

  &.selected {
    color: white;
    background-color: ${({ color }) => (color ? color : Colors.Primary)};
    &:hover {
      background-color: ${({ color }) =>
        color ? getColorWithBrightness(color, -0.1) : getColorWithBrightness(Colors.Primary, -0.1)};
    }
  }
`;

export const TagList = styled.div`
  .tag {
    margin: 4px ${times8()}px 4px 0;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const Tag: React.FC<TagProps> = ({
  size = 'middle',
  color,
  className,
  selected,
  border,
  tooltip,
  onClick,
  onClose,
  'data-testid': dataTestId,
  children
}) => {
  const [onCloseHover, setOnCloseHover] = useState<boolean>(false);
  const onTagClick = useMemo(() => (onClick && !onCloseHover ? onClick : undefined), [onClick, onCloseHover]);

  return (
    <Tooltip title={tooltip}>
      <StyledTag
        size={size}
        color={color}
        border={border}
        data-testid={dataTestId || 'tag'}
        className={classNames('tag', className, { clickable: onTagClick !== undefined }, { selected })}
        onClick={onTagClick}
      >
        <span className="tag-content">{children}</span>
        {onClose && (
          <Button
            onClick={onClose}
            className="tag-close-button"
            type="text"
            icon={<Icon className="tag-close-button-icon" icon={Icons.Close} />}
            onMouseEnter={() => setOnCloseHover(true)}
            onMouseLeave={() => setOnCloseHover(false)}
          />
        )}
      </StyledTag>
    </Tooltip>
  );
};
