import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { animated, easings, useSpring } from 'react-spring';
import { numberWithCommas } from 'Utils/text';

export interface AnimatedCounterProps {
  number: number;
  duration?: number; // ms
  className?: string;
  style?: CSSProperties;
  'data-testid'?: string;
}

const StyledAnimatedCounter = styled.span``;

export const AnimatedCounter: React.FC<AnimatedCounterProps> = ({
  number: n,
  duration = 4000,
  className,
  style,
  'data-testid': dataTestid
}) => {
  const Animated = animated(StyledAnimatedCounter);

  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    // config: { mass: 1, tension: 20, friction: 10 },
    config: { duration, easing: easings.easeOutQuad }
  });

  return (
    <Animated className={className} style={style} data-testid={dataTestid}>
      {number.to((v) => numberWithCommas(Math.round(v)))}
    </Animated>
  );
};
