import * as React from "react";
const SvgEmailSearchFull = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "email-search-full", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 729.279 838.012 C 823.993 838.012 900.738 761.693 900.738 667.547 C 900.738 573.401 823.993 497.083 729.279 497.083 C 634.6 497.083 557.82 573.401 557.82 667.547 C 557.82 761.693 634.6 838.012 729.279 838.012 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 848.477 795.041 L 915.772 861.806", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: 60,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 508.1 646.842 C 502.949 694.371 512.458 738.309 536.808 779.515 L 546.208 795.432 C 556.019 807.881 544.312 829.884 517.861 829.884 L 288.96 829.884 C 227.368 828.52 170.638 802.257 135.267 762.164 C 100.076 722.25 79.401 662.72 78.609 602.832 L 78.609 357.817 C 79.473 297.928 101.013 238.363 136.599 198.485 C 172.294 158.463 228.989 132.129 288.96 130.764 L 642.668 130.764 C 704.26 132.129 761.026 158.499 796.361 198.629 C 831.552 238.578 852.226 298.108 853.019 357.817 L 853.019 437.428 C 854.275 455.902 845.657 471.177 827.949 466.816 L 810.3 459.883 C 766.212 442.566 721.909 440.589 675.913 452.05 C 629.953 463.547 591.881 486.072 561.156 522.033 C 529.495 557.924 511.99 599.203 508.1 646.842 Z M 661.289 311.041 L 501.545 440.589 C 490.883 450.075 481.158 452.697 466.894 452.697 C 452.631 452.697 442.978 450.147 432.353 440.663 L 270.987 310.969 C 251.825 289.486 204.856 316.824 237.057 352.93 L 398.423 482.624 C 417.008 496.491 443.698 506.586 466.894 506.586 C 490.09 506.586 516.673 496.527 535.258 482.695 L 695.363 352.859 C 731.094 316.394 682.54 289.341 661.289 311.041 Z", style: {
  fill: "currentColor"
} }));
export default SvgEmailSearchFull;
