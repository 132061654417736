import * as React from "react";
const SvgUploadFile = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "trash", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 430.113 817.599 L 227.149 817.599 C 183.639 817.599 148.368 782.328 148.368 738.818 L 148.368 217.422 C 148.368 173.912 183.639 138.641 227.149 138.641 L 664.544 138.641 C 708.054 138.641 743.325 173.912 743.325 217.422 L 743.325 393.854", style: {
  fill: "none",
  strokeWidth: 60,
  strokeLinecap: "round",
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 304.761 346.333 L 544.73 346.333 M 304.761 478.12 L 457.476 478.12 M 304.762 609.907 L 392.603 609.907", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 675.022, cy: 670.446, r: 208.139, fill: "white", style: {
  strokeWidth: 60,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 675.022 600.229 L 675.022 760.384 M 765.747 656.661 L 675.03 584.979 L 584.297 656.661", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor"
} }));
export default SvgUploadFile;
