import * as React from "react";
const SvgStairsDown = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "stairs-down", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 414.358 648.557 L 594.075 812 L 764.594 638.651", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 90
} }), /* @__PURE__ */ React.createElement("path", { d: "M 259.406 212 L 415.774 212 L 415.774 426.387 L 589.83 426.387 L 589.83 810.585", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 90
} }));
export default SvgStairsDown;
