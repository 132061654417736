import * as React from "react";
const SvgLogout = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "logout", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { id: "path-1", d: "M 623.191 323.009 L 623.191 284.941 C 623.191 201.911 555.868 134.589 472.838 134.589 L 273.933 134.589 C 190.944 134.589 123.622 201.911 123.622 284.941 L 123.622 739.059 C 123.622 822.089 190.944 889.411 273.933 889.411 L 473.246 889.411 C 556.032 889.411 623.191 822.293 623.191 739.507 L 623.191 701.032", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 80
} }), /* @__PURE__ */ React.createElement("path", { id: "path-2", d: "M 900.379 511.999 L 409.088 511.999", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 80
} }), /* @__PURE__ */ React.createElement("path", { id: "path-3", d: "M 780.896 393.054 L 900.362 511.988 L 780.896 630.964", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 80
} }));
export default SvgLogout;
