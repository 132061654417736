import * as React from "react";
const SvgMegaphone = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "megaphone", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("defs", null), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1, 0, 0, 1, 4.893646, 1.731102)" }, /* @__PURE__ */ React.createElement("path", { d: "M 816.506 124.795 C 838.048 108.44 883.027 110.032 882.217 156.05 L 882.217 706.782 C 883.015 740.945 846.045 757.109 814.417 738.659 L 540.49 615.333 L 304.275 615.333 C 209.121 615.333 131.983 538.196 131.983 443.042 C 131.983 347.888 209.121 270.75 304.275 270.75 L 536.624 270.75 L 816.506 124.795 Z", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { d: "M 327.593 707.48 L 398.489 707.48 C 421.103 707.48 439.435 725.812 439.435 748.426 L 421.749 864.247 C 421.749 886.861 403.416 905.193 380.803 905.193 L 309.907 905.193 C 287.294 905.193 268.961 886.861 268.961 864.247 L 286.647 748.426 C 286.647 725.812 304.98 707.48 327.593 707.48 Z", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { d: "M 382.068 387.941 C 382.068 380.577 382.068 503.054 382.068 495.69 L 382.068 387.941 Z", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70,
  transformBox: "fill-box",
  transformOrigin: "50% 50%"
}, transform: "matrix(0.999998, -0.000001, 0.000001, 1, 0.000859, 0)" }), /* @__PURE__ */ React.createElement("path", { d: "M 278.693 387.941 C 278.693 380.577 278.693 503.054 278.693 495.69 L 278.693 387.941 Z", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} })));
export default SvgMegaphone;
