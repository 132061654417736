import * as React from "react";
const SvgEdit = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "edit", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 526.074 857.093 L 821.697 857.093", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 563.203 178.497 C 594.82 140.712 651.656 135.171 690.225 166.145 C 692.357 167.825 760.872 221.052 760.872 221.052 C 803.244 246.666 816.409 301.118 790.215 342.673 C 788.826 344.899 401.463 829.437 401.463 829.437 C 388.575 845.515 369.012 855.003 348.104 855.231 L 199.761 857.093 L 166.336 715.626 C 161.654 695.733 166.336 674.841 179.224 658.763 L 563.203 178.497 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { d: "M 491.492 268.412 L 713.731 439.083", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }));
export default SvgEdit;
