import { ConditionGroup } from 'Components/Conditions';
import { FilterFormData, QueryPagination } from 'Components/Filters';
import { message, messageDuration } from 'Components/Primitives';
import { TrackEventName } from 'Constants/AnalyticsEvent.enum';
import { QueryKey } from 'Constants/QueryKeys.enum';
import { backendApi, backendApiCsv } from 'http/Request';
import { QueryObserverOptions, useQuery } from 'react-query';
import {
  CustomReportData,
  CustomReportDefinition,
  CustomReportEntryMetadata,
  CustomReportMetadata,
  ReportBuilderConfig,
  ReportEntity
} from 'Types/Reports.types';
import { useTrack } from 'Utils/analytics';
import { setObjectFromKeys } from 'Utils/functional';

// custom reports
export const useCustomReportQuery = (options?: QueryObserverOptions<{ data: CustomReportDefinition[] }>) => {
  return useQuery<{ data: CustomReportDefinition[] }>(
    [QueryKey.ReportDefinitions],
    () =>
      backendApi
        .get<{ data: CustomReportDefinition[] }>('reports/definition')
        .then((response) => {
          return {
            data: response.data.data.map((report) => {
              return {
                ...report,
                config: {
                  ...report.config,
                  columns: report.config.columns
                }
              };
            })
          };
        })
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, staleTime: Infinity, ...(options && { ...options }) }
  );
};

// entities metadata
export const useReportEntitiesQuery = (
  options?: QueryObserverOptions<{ entities: { name: ReportEntity; label: string }[] }>
) => {
  return useQuery<{ entities: { name: ReportEntity; label: string }[] }>(
    [],
    () =>
      backendApi
        .get<{ entities: { name: ReportEntity; label: string }[] }>('reports/metadata')
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, staleTime: Infinity, ...(options && { ...options }) }
  );
};

// entity metadata
export const useReportEntityMetadataQuery = (
  entityId: ReportEntity,
  options?: QueryObserverOptions<{ entries: CustomReportEntryMetadata[] }>
) => {
  return useQuery<{ entries: CustomReportEntryMetadata[] }>(
    [QueryKey.ReportEntityMetadata, entityId],
    () =>
      backendApi
        .get<{ entries: CustomReportEntryMetadata[] }>(`reports/metadata/${entityId}`)
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, staleTime: Infinity, ...(options && { ...options }) }
  );
};

// account breakdown metadata
export const useCustomerDetailsBreakdownMetadataQuery = (
  options?: QueryObserverOptions<{ data: { id: string }[] }>
) => {
  return useQuery<{ data: { id: string }[] }>(
    [QueryKey.CustomerBreakdownMetadata],
    () =>
      backendApi
        .get<{ data: { id: string }[] }>('/metadata/fields-selection/customer/summary')
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, staleTime: Infinity, ...(options && { ...options }) }
  );
};

// custom report data
interface CustomReportQueryParams extends ReportBuilderConfig {
  pagination?: QueryPagination;
}
export const useCustomReportDataQuery = (
  params: CustomReportQueryParams,
  options?: QueryObserverOptions<{ data: CustomReportData[] }>
) => {
  return useQuery<{ data: CustomReportData[] }>(
    [QueryKey.CustomReportData, params],
    () =>
      backendApi
        .post<{ data: CustomReportData[] }>('reports/data', params)
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, ...(options && { ...options }) }
  );
};

// report download
export const useCustomReportDownloadQuery = (
  params: { id: number; metadata?: CustomReportMetadata; config: ReportBuilderConfig },
  options?: QueryObserverOptions<string>
) => {
  const { track } = useTrack();

  return useQuery<string>(
    [QueryKey.CustomReportData, params],
    () =>
      backendApiCsv
        .post<string>('reports/data', params.config)
        .then((response) => {
          track({
            eventName: TrackEventName.ReportDownload,
            params: { id: params.id, name: params.metadata?.name }
          });
          return response.data;
        })
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, ...(options && { ...options }) }
  );
};

// filter conversion
export const useConvertFilterToConditionQuery = (
  params: FilterFormData,
  options: QueryObserverOptions<ConditionGroup>
) => {
  return useQuery<ConditionGroup>(
    [QueryKey.ConvertFilterToCondition, params],
    () =>
      backendApi
        .post<ConditionGroup>('/reports/convert-filter', setObjectFromKeys(params))
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, staleTime: Infinity, ...(options && { ...options }) }
  );
};
