import React, { ReactNode, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { times8 } from 'Constants/Styles';
import { Dropdown, DropDownProps } from 'Components/Primitives/Dropdown/Dropdown';
import { Tree, TreeProps } from 'Components/Primitives/Tree/Tree/Tree';

interface TreeDropdownProps extends TreeProps {
  openByDefault?: boolean;
  dropdownPlacement?: DropDownProps['placement'];
  'data-testid'?: string;
  children: ReactNode;
}

const StyledTreeDropdown = styled.div`
  min-width: 340px;
  .tree-select {
    padding: ${times8()}px;
  }
`;

export const TreeDropdown: React.FC<TreeDropdownProps> = (props) => {
  const { dropdownPlacement, openByDefault, className, 'data-testid': dataTestId, onChange, children } = props;

  const [listValue, setListValue] = useState<(string | number)[]>();
  const [visible, setVisible] = useState<boolean>(openByDefault || false);

  const onVisibilityChange = useCallback(
    (e: boolean) => {
      setVisible(e);
      if (e === false && listValue) {
        onChange && onChange(listValue);
      }
    },
    [listValue, onChange]
  );

  const onValuesChange = useCallback((cbChangeValue: (string | number)[]): void => {
    setVisible(true);
    setListValue(cbChangeValue);
  }, []);

  return (
    <Dropdown
      open={visible}
      className="tree-dropdown"
      onOpenChange={onVisibilityChange}
      arrow={false}
      placement={dropdownPlacement || 'bottomRight'}
      trigger={['click']}
      dropdownRender={() => (
        <StyledTreeDropdown className="ant-dropdown-menu">
          <Tree
            {...props}
            data-testid={dataTestId || 'tree-select'}
            className="tree-select"
            selectMultiple={true}
            height={320}
            onChange={onValuesChange}
          />
        </StyledTreeDropdown>
      )}
    >
      <span style={{ display: 'inline-block' }} className={className}>
        {children}
      </span>
    </Dropdown>
  );
};
