import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormInstance } from 'Components/Primitives/Form/Form';
import { nonNullable } from 'Utils/functional';
import { ListSelect } from 'Components/ListSelect';
import { ListType } from 'Types/Shared.types';
import { UsersTreeSelect } from 'Components/Users';
import { FilterMetadata, FilterType } from 'Components/Filters/Filter.types';
import { DateRangeSelect } from 'Components/DateRangeSelect/DateRangeSelect';
import { Input, InputNumber } from 'Components/Primitives/Input/Input';
import { CustomersTreeSelect } from 'Components/Customers/CustomersTreeSelect/CustomersTreeSelect';
import { StakeholderTreeSelect } from 'Components/Stakeholders/StakeholderTreeSelect/StakeholderTreeSelect';

interface FilterFormProps<T> {
  formId: string;
  metadata: FilterMetadata[];
  form?: FormInstance<T>;
  initialFormData?: T;
  onChange(formData: T): void;
}

export const FilterForm = <T extends object>({
  formId,
  metadata,
  form: propForm,
  initialFormData,
  onChange
}: FilterFormProps<T>) => {
  const { t } = useTranslation(['common']);
  const [formFallback] = Form.useForm<T>();
  const form = useMemo(() => propForm || formFallback, [propForm, formFallback]);

  const renderFormItem = useCallback(
    (fmd: FilterMetadata): ReactNode => {
      const filterByFilterType: Record<FilterType, ReactNode> = {
        [FilterType.DateRange]: (
          <DateRangeSelect
            allowClear={fmd.options?.allowClear}
            predefinedOptions={fmd.options?.predefinedOptions}
            placeholder={fmd.placeholder || t('form.select-placeholder', { fieldName: fmd.label })}
          />
        ),

        //
        [FilterType.Customer]: (
          <CustomersTreeSelect
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            allowClear
            placeholder={fmd.placeholder || t('form.select-placeholder', { fieldName: fmd.label })}
          />
        ),
        [FilterType.User]: (
          <UsersTreeSelect
            allowClear
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            userSelectType={fmd.options?.userSelectType}
            placeholder={fmd.placeholder || t('form.select-placeholder', { fieldName: fmd.label })}
          />
        ),

        [FilterType.Stakeholders]: fmd.options?.customerId ? (
          <StakeholderTreeSelect
            customerId={fmd.options?.customerId}
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            allowClear={true}
            placeholder={fmd.placeholder || t('form.select-placeholder', { fieldName: fmd.label })}
          />
        ) : null,

        //
        [FilterType.Tier]: (
          <ListSelect
            type={ListType.Tier}
            data-testid="tier-select"
            mode={fmd.options?.selectMode || 'multiple'}
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            allowClear={true}
            placeholder={fmd.placeholder || t('tier.placeholder')}
          />
        ),

        //
        [FilterType.JourneyPhase]: (
          <ListSelect
            type={ListType.Journey}
            data-testid="journey-phase-select"
            mode={fmd.options?.selectMode || 'multiple'}
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            allowClear={true}
            placeholder={fmd.placeholder || t('journey-phase.placeholder')}
          />
        ),

        //
        [FilterType.Event]: (
          <ListSelect
            type={ListType.LifecycleEvents}
            data-testid="event-type-select"
            mode={fmd.options?.selectMode || 'multiple'}
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            allowClear
            placeholder={fmd.placeholder || t('lifecycle-events.placeholder')}
          />
        ),

        //
        [FilterType.Score]: (
          <ListSelect
            type={
              fmd.key.toLowerCase().includes('sentiment')
                ? ListType.SentimentBucketScore
                : ListType.EngagementBucketScore
            }
            data-testid="score-select"
            mode={fmd.options?.selectMode || 'multiple'}
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            allowClear={true}
            placeholder={fmd.placeholder || t('form.select-placeholder', { fieldName: fmd.label })}
          />
        ),

        //
        [FilterType.Sentiment]: (
          <ListSelect
            type={ListType.SentimentType}
            data-testid="sentiment-select"
            mode={fmd.options?.selectMode || 'multiple'}
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            allowClear={true}
            placeholder={fmd.placeholder || t('form.select-placeholder', { fieldName: fmd.label })}
          />
        ),

        //
        [FilterType.ActiveInactive]: (
          <ListSelect
            type={ListType.ActiveInactive}
            data-testid="active-status-select"
            mode={fmd.options?.selectMode || 'multiple'}
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            allowClear={true}
            placeholder={fmd.placeholder || t('form.select-placeholder', { fieldName: fmd.label })}
          />
        ),

        //
        [FilterType.CustomerStatus]: (
          <ListSelect
            type={ListType.CustomerStatus}
            data-testid="customer-status-select"
            mode={fmd.options?.selectMode || 'multiple'}
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            allowClear={true}
            placeholder={fmd.placeholder || t('customer-status.placeholder')}
          />
        ),

        //
        [FilterType.Boolean]: (
          <ListSelect
            type={ListType.Boolean}
            data-testid="boolean-select"
            mode="single"
            allowClear={true}
            placeholder={fmd.placeholder || t('boolean-select.placeholder')}
          />
        ),

        //
        [FilterType.Insight]: (
          <ListSelect
            type={ListType.InsightType}
            data-testid="insight-select"
            mode={fmd.options?.selectMode || 'multiple'}
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            allowClear={true}
            placeholder={fmd.placeholder || t('insights.placeholder')}
          />
        ),

        //
        [FilterType.StakeholderRole]: (
          <ListSelect
            type={ListType.StakeholderRoles}
            data-testid="stakeholder-role-select"
            mode={fmd.options?.selectMode || 'multiple'}
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            allowClear={true}
            placeholder={fmd.placeholder || t('stakeholder-role-select.placeholder')}
          />
        ),

        [FilterType.CommType]: (
          <ListSelect
            type={ListType.CommType}
            data-testid="communication-type-select"
            mode={fmd.options?.selectMode || 'multiple'}
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            allowClear={true}
            placeholder={fmd.placeholder || t('communication-type-select.placeholder')}
          />
        ),

        //
        [FilterType.Topic]: (
          <ListSelect
            type={ListType.Topic}
            data-testid="topics-select"
            mode={fmd.options?.selectMode || 'multiple'}
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            allowClear={true}
            placeholder={fmd.placeholder || t('form.select-placeholder', { fieldName: fmd.label })}
          />
        ),

        //
        [FilterType.List]: (
          <ListSelect
            data-testid="list-select"
            mode="multiple"
            allowClear={true}
            emptyAsAll={nonNullable(fmd.options?.emptyAsAll) ? fmd.options?.emptyAsAll : true}
            listEntries={fmd.typeData?.listEntries}
            placeholder={fmd.placeholder || t('form.select-placeholder', { fieldName: fmd.label })}
          />
        ),

        //
        [FilterType.Number]: <InputNumber data-testid="filter-form-number" />,
        [FilterType.Text]: <Input data-testid="filter-form-input" />,
        [FilterType.OpenClosed]: null
      };

      return filterByFilterType[fmd.type];
    },
    [t]
  );

  const onFieldsChange = useCallback(() => {
    onChange(form.getFieldsValue());
  }, [form, onChange]);

  return (
    <Form
      data-testid="filter-form"
      id={formId}
      form={form}
      initialValues={initialFormData}
      layout="vertical"
      onFieldsChange={onFieldsChange}
    >
      {metadata.map((fmd) => {
        return (
          <Form.Item
            data-testid="filter-form-item"
            key={fmd.key}
            name={fmd.key}
            label={fmd.label}
            hidden={fmd.hidden || false}
            extra={fmd.caption}
          >
            {renderFormItem(fmd)}
          </Form.Item>
        );
      })}
    </Form>
  );
};
