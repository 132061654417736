import * as React from "react";
const SvgPulseSquare = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "plus", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 249.495 490.276 L 360.36 493.43 L 454.254 341.13 L 559.771 682.869 L 656.282 526.529 L 774.506 527.614", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 72
} }), /* @__PURE__ */ React.createElement("path", { d: "M 696.88 117.415 L 327.077 117.415 C 198.209 117.415 117.415 208.661 117.415 337.786 L 117.415 686.215 C 117.415 815.341 197.827 906.585 327.077 906.585 L 696.835 906.585 C 826.133 906.585 906.585 815.341 906.585 686.215 L 906.585 337.786 C 906.585 208.661 826.133 117.415 696.88 117.415 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 72
} }));
export default SvgPulseSquare;
