import React, { useEffect, useMemo, useState } from 'react';
import { ListItem } from 'Types/Shared.types';
import { nonNullable } from 'Utils/functional';

interface ListDisplayValueProps {
  listEntries?: ListItem[];
  value: string | number | (string | number)[];
  onValuesInvalid?(): void;
}

export const ListDisplayValue: React.FC<ListDisplayValueProps> = ({ listEntries, value, onValuesInvalid }) => {
  const [sanitized, setSanitzed] = useState<boolean>(false);

  const listNameByListId = useMemo(
    (): { [key: string]: string } =>
      (listEntries || []).reduce((acc: { [key: string]: string }, listItem: ListItem) => {
        acc[listItem.id] = listItem.name;
        return acc;
      }, {}),
    [listEntries]
  );

  useEffect(() => {
    if (Object.keys(listNameByListId).length > 0 && !sanitized) {
      const valueIds = Object.keys(listNameByListId);
      if (Array.isArray(value)) {
        const validValues: (string | number)[] = value.filter((v) => valueIds.includes(v.toString()));
        if (validValues.length === 0) {
          onValuesInvalid && onValuesInvalid();
        }
      } else {
        const valueIsValid: boolean = valueIds.includes(value.toString());
        if (!valueIsValid) {
          onValuesInvalid && onValuesInvalid();
        }
      }
      setSanitzed(true);
    }
  }, [value, listNameByListId, sanitized, onValuesInvalid]);

  return Object.keys(listNameByListId).length > 0 ? (
    <>
      {Array.isArray(value)
        ? value
            .map((v) => listNameByListId[v])
            .filter(nonNullable)
            .join(', ')
        : listNameByListId[value]}
    </>
  ) : null;
};
