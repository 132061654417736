import * as React from "react";
const SvgTime = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "time", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { id: "Stroke 1", fillRule: "evenodd", clipRule: "evenodd", d: "M 927 512 C 927 741.216 741.215 927.001 512 927.001 C 282.785 927.001 97 741.216 97 512 C 97 282.784 282.785 96.999 512 96.999 C 741.215 96.999 927 282.784 927 512 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentcolor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 3", d: "M 665.958 644.002 L 496.817 543.101 L 496.817 325.64", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentcolor",
  strokeWidth: 60
} }));
export default SvgTime;
