import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Colors, times8 } from 'Constants/Styles';
import classNames from 'classnames';

interface WidgetHeaderProps {
  id?: string;
  className?: string;
  title?: string;
  subtitle?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  size?: HeaderSize;
  children?: ReactNode;
  'data-testid'?: string;
}

export const StyledWidgetHeaderWrapper = styled.div<{ size?: HeaderSize }>`
  margin-bottom: ${({ size }) => (size === 'small' ? `${times8()}px` : `${times8(3)}px`)};
  .widget-subtitle {
    margin-top: ${times8(0.5)}px;
    color: ${Colors.SubText};
    white-space: pre-line;
  }
`;

const StyledWidgetHeader = styled(StyledWidgetHeaderWrapper)<{ size: HeaderSize }>`
  display: flex;
  align-items: center;

  .widget-title-wrapper.with-content {
    margin-right: ${times8(2)}px;
  }

  .content {
    flex-grow: 1;
  }

  .prefix,
  .suffix {
    flex-shrink: 0;
  }

  .prefix {
    margin-right: ${times8()}px;
  }

  .suffix {
    > button,
    > span,
    > div {
      margin-left: ${times8()}px;
    }
  }
`;

type HeaderSize = 'large' | 'small';

export const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  id,
  className,
  title,
  subtitle,
  prefix,
  suffix,
  children,
  size,
  'data-testid': dataTestId
}) => (
  <StyledWidgetHeader
    id={id}
    className={classNames('widget-header', className)}
    data-testid={dataTestId}
    size={size || 'large'}
  >
    {prefix && <div className="prefix">{prefix}</div>}
    {(title || subtitle) && (
      <div className={classNames('widget-title-wrapper', children !== undefined && 'with-content')}>
        {title &&
          (size === 'small' ? <h4 className="widget-title">{title}</h4> : <h2 className="widget-title">{title}</h2>)}
        {subtitle && (
          <div className="widget-subtitle">{typeof subtitle === 'string' ? <p>{subtitle}</p> : subtitle}</div>
        )}
      </div>
    )}
    <div className="content">{children}</div>
    {suffix && <div className="suffix">{suffix}</div>}
  </StyledWidgetHeader>
);
