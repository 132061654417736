import { Spin as RefSpin, SpinProps as BaseSpinProps } from 'antd';

export interface SpinProps {
  className?: string;
  size?: BaseSpinProps['size'];
  'data-testid'?: string;
}

export const Spin: React.FC<SpinProps> = ({ className, size = 'small', 'data-testid': dataTestId }) => (
  <RefSpin className={className} data-testid={dataTestId} size={size} />
);
