import React, { ReactNode, useCallback, useState } from 'react';
import { times8 } from 'Constants/Styles';
import { Dropdown, DropDownProps } from 'Components/Primitives/Dropdown/Dropdown';
import { CheckboxSelectDnD, CheckboxSelectDnDProps } from 'Components/CheckboxSelect/CheckboxSelectDnD';
import { CheckboxSelectOption } from 'Components/CheckboxSelect/CheckboxSelect.types';
import { CheckboxSelectVirtual } from './CheckboxSelectVirtual';

export interface CheckboxSelectDropdownProps {
  options: CheckboxSelectOption[];
  value?: (string | number)[];
  defaultValue?: (string | number)[] | undefined;
  order?: (string | number)[];
  defaultOrder?: (string | number)[] | undefined;
  dropdownPlacement?: DropDownProps['placement'];
  arrow?: boolean;
  openByDefault?: boolean;
  invokeChangeOnDropdownClose?: boolean;
  className?: string;
  children: ReactNode;
  onChange?(values: (string | number)[]): void;
  onOrderChange?: CheckboxSelectDnDProps['onOrderChange'];
}

export const CheckboxSelectDropdown: React.FC<CheckboxSelectDropdownProps> = ({
  options,
  value,
  order,
  defaultValue,
  defaultOrder,
  dropdownPlacement,
  arrow = true,
  openByDefault = false,
  invokeChangeOnDropdownClose = false,
  className,
  children,
  onChange,
  onOrderChange
}) => {
  const [listValue, setListValue] = useState<(string | number)[]>(value || defaultValue || []);
  const [visible, setVisible] = useState<boolean>(openByDefault);

  const onVisibilityChange = useCallback(
    (e: boolean) => {
      setVisible(e);
      if (e === false && invokeChangeOnDropdownClose) {
        onChange && onChange(listValue);
      }
    },
    [listValue, invokeChangeOnDropdownClose, onChange]
  );

  const onValuesChange = useCallback(
    (cbChangeValue: (string | number)[]): void => {
      setVisible(true);
      setListValue(cbChangeValue);
      if (invokeChangeOnDropdownClose) return;
      onChange && onChange(cbChangeValue);
    },
    [invokeChangeOnDropdownClose, onChange]
  );

  return (
    <Dropdown
      open={visible}
      onOpenChange={onVisibilityChange}
      arrow={arrow}
      placement={dropdownPlacement || 'bottomRight'}
      trigger={['click']}
      dropdownRender={() =>
        onOrderChange ? (
          <CheckboxSelectDnD
            options={options}
            value={value}
            defaultValue={defaultValue}
            order={order}
            defaultOrder={defaultOrder}
            className="ant-dropdown-menu"
            style={{ minWidth: 320, maxHeight: 400, overflow: 'auto', padding: `0 ${times8(2)}px` }}
            onChange={onValuesChange}
            onOrderChange={onOrderChange}
          />
        ) : (
          <CheckboxSelectVirtual
            options={options}
            value={value}
            defaultValue={defaultValue}
            className="ant-dropdown-menu"
            onChange={onValuesChange}
            maxHeight={400}
            style={{ minWidth: 320, overflow: 'auto', padding: `0 ${times8(2)}px ${times8(2)}px` }}
          />
        )
      }
    >
      <span style={{ display: 'inline-block' }} className={className}>
        {children}
      </span>
    </Dropdown>
  );
};
