import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from '../Tooltip/Tooltip';

export interface TruncateTooltipProps {
  text: string;
  className?: string;
}

const StyledTruncateTooltip = styled.span`
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TruncateTooltip: React.FC<TruncateTooltipProps> = ({ text, className }) => {
  const [tooltipText, setTooltipText] = useState<string | null>(null);
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (ref.current && ref.current.scrollWidth > ref.current.offsetWidth) {
      setTooltipText(text);
    }
  }, [text, ref]);

  return (
    <Tooltip title={tooltipText}>
      <StyledTruncateTooltip ref={ref} className={className}>
        {text}
      </StyledTruncateTooltip>
    </Tooltip>
  );
};
