import { ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';
import { ColorPalette, Colors } from 'Constants/Styles';
import { Button, Dropdown, Icon, Icons, MenuItem, Tooltip } from 'Components/Primitives';
import { TableAction } from 'Components/Table/Table.types';

interface TableActionsCellProps<T> {
  record: T;
  actions: TableAction<T>[];
}

const StyledTableActionsCell = styled.div`
  button {
    width: 32px;
    height: 32px;
    span {
      &.ant-btn-icon,
      &.anticon {
        display: block;
      }
    }
    &:disabled,
    &[disabled] {
      color: ${ColorPalette.Gray400};
    }
    &:hover {
      color: ${Colors.Primary};
    }
  }
`;

const TableActionButton = function <T>(props: { record: T; action: TableAction<T> }): ReactNode {
  const { record, action } = props;
  const isHidden = useMemo(
    (): boolean => (typeof action.hidden === 'function' ? action.hidden(record) : false),
    [record, action]
  );

  return isHidden ? null : (
    <Tooltip
      title={typeof action.label === 'function' ? action.label(record) : action.label}
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
      align={{ offset: [0, -8] }}
      overlayStyle={{ pointerEvents: 'none' }}
    >
      <Button
        data-testid={action.dataTestId}
        size="small"
        type="default"
        className="table-row-action-button"
        loading={action.loading ? action.loading(record) : false}
        onClick={(e) => {
          e.currentTarget.blur();
          action.onClick(record);
        }}
        disabled={action.disabled ? action.disabled(record) : false}
        icon={typeof action.icon === 'function' ? action.icon(record) : action.icon}
      />
    </Tooltip>
  );
};

const TableActionsMenu = function <T>(props: TableActionsCellProps<T>): ReactNode {
  const { record, actions } = props;

  const menuItems = useMemo(
    (): MenuItem[] =>
      actions
        .filter((action) => (typeof action.hidden === 'function' ? !action.hidden(record) : true))
        .map((action) => {
          const label: string = typeof action.label === 'function' ? action.label(record) : action.label;
          const tooltip: string | null =
            typeof action.tooltip === 'function' ? action.tooltip(record) : action.tooltip || null;
          return {
            key: action.key,
            icon: typeof action.icon === 'function' ? action.icon(record) : action.icon,
            label: (
              <Tooltip title={tooltip}>
                <span data-testid={action.dataTestId} className="table-menu-action-label">
                  {label}
                </span>
              </Tooltip>
            ),
            disabled: action.disabled && record ? action.disabled(record) : false,
            onClick: () => record && action.onClick(record)
          };
        }),
    [actions, record]
  );

  return (
    <Dropdown
      trigger={['click']}
      arrow={true}
      placement="bottomRight"
      align={{ offset: [4, 4] }}
      overlayStyle={{ minWidth: 120 }}
      menu={{ items: menuItems }}
    >
      <button data-testid="table-row-actions-menu-trigger">
        <Icon icon={Icons.MoreVertical} />
      </button>
    </Dropdown>
  );
};

export const TableActionsCell = function <T>(props: TableActionsCellProps<T>): ReactNode {
  const { record, actions } = props;
  return (
    <StyledTableActionsCell>
      {actions.length > 1 ? (
        <TableActionsMenu {...props} />
      ) : actions.length === 1 ? (
        <TableActionButton record={record} action={actions[0]} />
      ) : (
        <></>
      )}
    </StyledTableActionsCell>
  );
};
