import React from 'react';
import { Spin } from 'Components/Primitives';
import styled from '@emotion/styled';

const StyledFullPageSpinner = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  .spinner {
    position: relative;
    top: 30vh;
  }
`;

const FullPageSpinner: React.FC = () => (
  <StyledFullPageSpinner>
    <Spin data-testid="full-page-spinner" className="spinner" size="default" />
  </StyledFullPageSpinner>
);

export default FullPageSpinner;
