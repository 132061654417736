import * as React from "react";
const SvgInfoSquare = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "info-square", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 682.423 148.268 L 341.539 148.268 C 222.745 148.268 148.268 232.379 148.268 351.407 L 148.268 672.592 C 148.268 791.622 222.391 875.732 341.539 875.732 L 682.384 875.732 C 801.571 875.732 875.732 791.622 875.732 672.592 L 875.732 351.407 C 875.732 232.379 801.571 148.268 682.423 148.268 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 511.789 669.289 L 511.789 512", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 511.591 362.741 L 511.985 362.741", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }));
export default SvgInfoSquare;
