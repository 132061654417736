import React, { ReactNode, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames';

export interface VirtualListProps {
  list: { key: string; element: ReactNode }[];
  getListItemHeight(index: number): number;
  listItemClassName?: string;
  className?: string;
}

const StyledVirtualList = styled.div<{ h: number }>`
  height: ${({ h }) => h}px;
  .inner {
    position: relative;
  }
`;

export const VirtualList: React.FC<VirtualListProps> = ({ list, listItemClassName, className, getListItemHeight }) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const listHeight = useMemo(
    (): number => list.reduce((acc: number, _, index) => acc + getListItemHeight(index), 0),
    [list, getListItemHeight]
  );

  const rowVirtualizer = useWindowVirtualizer({
    count: list.length,
    overscan: 10,
    estimateSize: getListItemHeight
  });

  return (
    <StyledVirtualList ref={parentRef} className={className} h={listHeight}>
      <div className="inner">
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const height = getListItemHeight(virtualRow.index);
          return (
            <div
              key={virtualRow.key}
              ref={rowVirtualizer.measureElement}
              data-index={virtualRow.index}
              className={classNames(listItemClassName, virtualRow.index % 2 ? 'list-item-odd' : 'list-item-even')}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${height}px`,
                transform: `translateY(${virtualRow.start}px)`
              }}
            >
              {list[virtualRow.index].element}
            </div>
          );
        })}
      </div>
    </StyledVirtualList>
  );
};
