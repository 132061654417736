import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Colors, times8 } from 'Constants/Styles';
import { Progress } from 'Components/Primitives/Progress/Progress';
import { Slider } from 'Components/Primitives/Sliders/Slider';
import { Tooltip } from 'Components/Primitives/Tooltip/Tooltip';
import { Icon } from 'Components/Primitives/Icon/Icon';
import { Icons } from 'Components/Primitives/Icon/Icon.types';

export interface SliderGroupProps {
  sliders: SliderGroupItem[];
  editable?: boolean;
  labelSuffix?: string;
  className?: string;
  onChange?(sliders: SliderGroupItem[]): void;
}

export interface SliderGroupItem {
  key: string;
  label: ReactNode;
  value: number;
  tooltip?: string;
  suffix?: ReactNode;
}

const StyledSliderGroup = styled.ul<{ editable: boolean }>`
  .slider-group-list-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: ${times8(2)}px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .slider-group-list-item-label {
    width: 220px;
    flex-shrink: 0;
    margin-right: ${times8(2)}px;
  }
  .slider-group-list-item-slider {
    flex-shrink: 0;
  }
  .slider-group-info-icon,
  .slider-group-list-item-suffix {
    margin-left: ${times8()}px;
  }
  .slider-group-info-icon {
    margin-top: 2px;
    color: ${Colors.SubText};
  }
`;

export const SliderGroup: React.FC<SliderGroupProps> = ({ sliders, editable, labelSuffix, className, onChange }) => {
  const [groupSliders, setGroupSliders] = useState<SliderGroupItem[]>();

  const onSliderValueChange = useCallback(
    (changedSlider: SliderGroupItem, changedValue: number) => {
      if (!groupSliders) return;
      const slidersUpdate: SliderGroupItem[] = [
        ...groupSliders.map((s) => (s.key === changedSlider.key ? { ...s, value: changedValue } : s))
      ];
      setGroupSliders(slidersUpdate);
      onChange && onChange(slidersUpdate);
    },
    [groupSliders, onChange]
  );

  useEffect(() => {
    setGroupSliders(sliders);
  }, [sliders]);

  return (
    <StyledSliderGroup className={className} data-testid="slider-group" editable={editable || false}>
      {groupSliders &&
        groupSliders.map((slider) => {
          return (
            <li key={slider.key} className="slider-group-list-item">
              <div className="slider-group-list-item-label">{slider.label}</div>

              {editable ? (
                <Slider
                  className="slider-group-list-item-slider"
                  size="middle"
                  value={slider.value}
                  labelSuffix={labelSuffix}
                  onChange={(v) => onSliderValueChange(slider, v)}
                />
              ) : (
                <Progress
                  className="slider-group-list-item-slider"
                  type="bar"
                  size="middle"
                  progress={slider.value}
                  labelSuffix={labelSuffix}
                />
              )}

              {slider.tooltip && (
                <Tooltip title={slider.tooltip}>
                  <span>
                    <Icon className="slider-group-info-icon" icon={Icons.InfoSquare} />
                  </span>
                </Tooltip>
              )}

              {slider.suffix && <div className="slider-group-list-item-suffix">{slider.suffix}</div>}
            </li>
          );
        })}
    </StyledSliderGroup>
  );
};
