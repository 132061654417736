import * as React from "react";
const SvgColumns = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "columns", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { id: "Stroke 1", fillRule: "evenodd", clipRule: "evenodd", d: "M 151.436 291.656 C 151.436 186.483 152.563 151.436 291.656 151.436 C 430.749 151.436 431.875 186.483 431.875 291.656 C 431.875 396.828 432.319 431.875 291.656 431.875 C 150.993 431.875 151.436 396.828 151.436 291.656 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 80
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 3", fillRule: "evenodd", clipRule: "evenodd", d: "M 592.125 291.656 C 592.125 186.483 593.251 151.436 732.344 151.436 C 871.438 151.436 872.564 186.483 872.564 291.656 C 872.564 396.828 873.008 431.875 732.344 431.875 C 591.681 431.875 592.125 396.828 592.125 291.656 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 80
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 5", fillRule: "evenodd", clipRule: "evenodd", d: "M 151.436 732.344 C 151.436 627.172 152.563 592.125 291.656 592.125 C 430.749 592.125 431.875 627.172 431.875 732.344 C 431.875 837.517 432.319 872.564 291.656 872.564 C 150.993 872.564 151.436 837.517 151.436 732.344 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 80
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 7", fillRule: "evenodd", clipRule: "evenodd", d: "M 592.125 732.344 C 592.125 627.172 593.251 592.125 732.344 592.125 C 871.438 592.125 872.564 627.172 872.564 732.344 C 872.564 837.517 873.008 872.564 732.344 872.564 C 591.681 872.564 592.125 837.517 592.125 732.344 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 80
} }));
export default SvgColumns;
