import * as React from "react";
const SvgSentimentPositive = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "sentiment-positive", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("circle", { style: {
  fill: "#10bf91"
}, cx: 512, cy: 512, r: 441.976 }), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1, 0, 0, 1, 23.524643, 6.274232)" }, /* @__PURE__ */ React.createElement("path", { d: "M 126.02 122.212 A 59.467 59.467 0 0 1 66.553 181.679 A 59.467 59.467 0 0 1 7.086 122.212 A 59.467 59.467 0 0 1 66.553 62.745 A 59.467 59.467 0 0 1 126.02 122.212 Z", style: {
  fill: "#04664c"
}, transform: "matrix(1, 0, 0, 1.420879, 239.084854, 223.2332)" }), /* @__PURE__ */ React.createElement("path", { d: "M 126.02 122.212 A 59.467 59.467 0 0 1 66.553 181.679 A 59.467 59.467 0 0 1 7.086 122.212 A 59.467 59.467 0 0 1 66.553 62.745 A 59.467 59.467 0 0 1 126.02 122.212 Z", style: {
  fill: "#04664c"
}, transform: "matrix(1, 0, 0, 1.420879, 604.759827, 223.2332)" })), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "#04664c",
  strokeWidth: 50,
  strokeLinecap: "round",
  fill: "none"
}, d: "M 286.411 628.954 C 321.492 711.49 409.268 770 512.001 770 C 614.733 770 702.509 711.49 737.59 628.954" }));
export default SvgSentimentPositive;
