import { TreeNode } from 'Components/Primitives/Tree/Tree.types';
import { Stakeholder } from 'Types/Stakeholder.types';
import groupBy from 'lodash/groupBy';
import { translate } from 'Utils/translate';
import { normalizedStringValue } from 'Utils/text';
import { Highlight } from 'Components/Primitives';

export const getStakeholdersTreeSelectOptions = (
  stakeholders: Stakeholder[],
  selectMultiple: boolean,
  searchTerm: string | undefined
): TreeNode[] => {
  const stakeholdersByRole = groupBy(stakeholders, (stakeholder) => stakeholder.role?.name ?? 'unassigned');

  return Object.keys(stakeholdersByRole).map((stakeholderGroup) => {
    const groupTitle = stakeholderGroup === 'unassigned' ? translate('common:unassigned') : stakeholderGroup;
    return {
      title: searchTerm ? <Highlight text={groupTitle} wholeWordOnly={false} highlight={[searchTerm]} /> : groupTitle,
      searchValue: groupTitle,
      value: stakeholderGroup,
      selectable: selectMultiple,
      children: stakeholdersByRole[stakeholderGroup].map((stakeholder) => ({
        title: searchTerm ? (
          <Highlight text={stakeholder.name} wholeWordOnly={false} highlight={[searchTerm]} />
        ) : (
          stakeholder.name
        ),
        searchValue: normalizedStringValue(stakeholder.name),
        value: stakeholder.id,
        selectable: true,
        children: []
      }))
    };
  });
};
