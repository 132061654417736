import * as React from "react";
const SvgChat = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "chat", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 785.967 785.91 C 667.56 904.324 492.232 929.911 348.752 863.554 C 327.571 855.027 310.205 848.135 293.697 848.135 C 247.713 848.406 190.478 892.995 160.729 863.282 C 130.982 833.533 175.603 776.251 175.603 729.993 C 175.603 713.48 168.984 696.425 160.458 675.201 C 94.071 531.744 119.691 356.358 238.096 237.979 C 389.247 86.774 634.815 86.774 785.967 237.939 C 937.387 389.379 937.116 634.742 785.967 785.91 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { d: "M 664.62 528.001 L 664.968 528.001", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { d: "M 509.31 528.001 L 509.659 528.001", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { d: "M 353.977 528.001 L 354.325 528.001", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }));
export default SvgChat;
