import React, { useState } from 'react';
import { RouterProvider, createBrowserRouter, useSearchParams } from 'react-router-dom';
import { isMobileApp } from 'Mobile/MobileAppUtils';
import MobileAppEntry from 'Mobile/MobileAppEntry';
import { isCRMApp } from 'Views/CRMApp/CRMAppUtils';
import CRMAppEntry from 'Views/CRMApp/CRMAppEntry';
import { isIFrame } from 'Utils/url';
import IframeAppEntry from 'Views/IframeApp/IframeAppEntry';
import DesktopAppEntry from 'Views/App/DesktopAppEntry';

const EntryApp: React.FC = () => {
  const [searchParams] = useSearchParams();

  const [mobileApp] = useState<boolean>(isMobileApp());
  const [crmApp] = useState<boolean>(isCRMApp(searchParams));
  const [iframeApp] = useState<boolean>(isIFrame());

  if (mobileApp) return <MobileAppEntry />;
  if (crmApp) return <CRMAppEntry />;
  if (iframeApp) return <IframeAppEntry />;
  return <DesktopAppEntry />;
};

const router = createBrowserRouter([{ path: '*', Component: EntryApp }]);

const AppEntry: React.FC = () => <RouterProvider router={router} />;

export default AppEntry;
