import { DateRangeValue, PredefinedDateRange } from 'Types/DateRange.types';
import { SelectProps } from 'Components/Primitives';
import { FieldTypeData } from 'Types/Shared.types';

export const QUERY_RANGE_LIMIT = 20000;
export interface QueryPagination {
  from: number;
  to: number;
}

export enum FilterType {
  DateRange = 'DateRange',
  Customer = 'Customer',
  User = 'User',
  Stakeholders = 'Stakeholders',
  Tier = 'Tier',
  Number = 'Number',
  ActiveInactive = 'ActiveInactive',
  Insight = 'Insight',
  JourneyPhase = 'JourneyPhase',
  Score = 'Score',
  Sentiment = 'Sentiment',
  Event = 'Event', // Lifecycle event
  Boolean = 'Boolean',
  CustomerStatus = 'CustomerStatus',
  StakeholderRole = 'StakeholderRole',
  CommType = 'CommType',
  OpenClosed = 'OpenClosed',
  Text = 'Text',
  Topic = 'Topic',
  List = 'List'
}

export type FilterValue =
  | DateRangeValue
  | number
  | number[]
  | string
  | string[]
  | boolean
  | boolean[]
  | undefined
  | null;

export type FilterFormData = { [key: string]: FilterValue };

export interface FilterDefaultsConfig<T> {
  localStorageKey: string;
  filters: T | undefined;
  defaultFilters: T | undefined;
  isLoading?: boolean;
}

export interface FilterMetadata {
  type: FilterType;
  key: string;
  label?: string;
  placeholder?: string;
  caption?: string;
  mandatory?: boolean;
  typeData?: FieldTypeData | null;
  hidden?: boolean;
  options?: {
    customerId?: number;
    allowClear?: boolean;
    predefinedOptions?: PredefinedDateRange[] | 'all';
    maxShowcaseAvatarsToShow?: number;
    emptyAsAll?: boolean;
    selectMode?: SelectProps['mode'];
    userSelectType?: 'all' | 'managers';
    tagFormat?: (value: FilterValue) => string;
  };
}
