import { ReactNode, useCallback } from 'react';
import { Radio as RefRadio, RadioChangeEvent } from 'antd';
import styled from '@emotion/styled';
import { RadioGroupOptionType } from 'antd/es/radio';
import { times8 } from 'Constants/Styles';

type RadioSize = 'small' | 'middle' | 'large';
export interface RadioOption<T> {
  value: T;
  label: ReactNode;
  key: string;
  dataTestId?: string;
}
export interface RadioProps<T> {
  options: RadioOption<T>[];
  layout: RadioLayout;
  value?: T;
  className?: string;
  optionType?: RadioGroupOptionType;
  size?: RadioSize;
  'data-testid'?: string;
  onChange?(value: T): void;
}

type RadioLayout = 'horizontal' | 'vertical';

const StyledRadio = styled(RefRadio.Group)<{ layout: RadioLayout }>`
  .ant-radio-wrapper {
    display: ${({ layout }) => (layout === 'vertical' ? 'flex' : 'inline-flex')};
    align-items: baseline;
    margin-bottom: ${({ layout }) => (layout === 'vertical' ? '2px' : 0)};
    .ant-radio {
      position: relative;
      align-self: baseline;
      top: 2px;
    }
  }
  &.ant-radio-group-small .radio-button {
    padding: 0px ${times8()}px;
  }
`;

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const Radio = <T extends any>({
  value,
  options,
  layout,
  className,
  optionType,
  size = 'middle',
  'data-testid': dataTestId,
  onChange
}: RadioProps<T>) => {
  const onRadioChange = useCallback((e: RadioChangeEvent) => onChange && onChange(e.target.value), [onChange]);

  return (
    <StyledRadio
      className={className}
      value={value}
      onChange={onRadioChange}
      layout={layout}
      buttonStyle="solid"
      size={size}
      data-testid={dataTestId}
    >
      {options.map((option) =>
        optionType === 'button' ? (
          <RefRadio.Button
            className="radio-button"
            key={option.key}
            value={option.value}
            data-testid={option.dataTestId || 'radio-option'}
          >
            {option.label}
          </RefRadio.Button>
        ) : (
          <RefRadio key={option.key} value={option.value} data-testid={option.dataTestId || 'radio-option'}>
            {option.label}
          </RefRadio>
        )
      )}
    </StyledRadio>
  );
};
