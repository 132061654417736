import * as React from "react";
const SvgEmailSearch = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "email-search", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 290.429 800.013 L 505.201 800.013 M 824.136 428.897 L 824.136 360.001 C 824.136 250.974 752.546 162.19 641.938 162.19 L 290.429 162.19 C 183.044 162.19 108.232 250.974 108.232 360.001 L 108.232 602.202 C 108.232 711.584 179.822 800.013 290.429 800.013", strokeLinecap: "round", strokeLinejoin: "round", style: {
  stroke: "currentColor",
  fill: "none",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { d: "M 677.376 334.431 L 518.445 462.634 C 503.912 474.176 485.871 480.427 467.258 480.427 C 448.644 480.427 430.603 474.176 416.071 462.634 L 255.708 334.431", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { d: "M 729.279 838.012 C 823.993 838.012 900.738 761.693 900.738 667.547 C 900.738 573.401 823.993 497.083 729.279 497.083 C 634.6 497.083 557.82 573.401 557.82 667.547 C 557.82 761.693 634.6 838.012 729.279 838.012 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { d: "M 848.477 795.041 L 915.772 861.806", strokeLinecap: "round", strokeLinejoin: "round", style: {
  stroke: "currentColor",
  strokeWidth: 60
} }));
export default SvgEmailSearch;
