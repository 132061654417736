import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tab } from 'Components/Primitives/Tabs/Tabs.types';
import classNames from 'classnames';
import styled from '@emotion/styled';

interface TabComponentProps {
  tab: Tab;
  isActive: boolean;
  onTabClick?(key: string): void;
  className?: string;
}

const StyledTabComponentLink = styled(NavLink)`
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

const StyledTabComponentDiv = styled.div`
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

const TabComponent: React.FC<TabComponentProps> = ({ tab, isActive, onTabClick, className }) => {
  return tab.linkTo ? (
    <StyledTabComponentLink
      onClick={(e) => tab.disabled && e.preventDefault()}
      className={classNames('tab', { active: isActive, disabled: tab.disabled }, className)}
      to={tab.linkTo}
      data-testid={tab['data-testid']}
    >
      {tab.icon && <div className="tab-icon">{tab.icon}</div>}
      {tab.title}
    </StyledTabComponentLink>
  ) : (
    <StyledTabComponentDiv
      className={classNames('tab', { active: isActive, disabled: tab.disabled }, className)}
      onClick={(e) => {
        if (tab.disabled) {
          e.preventDefault();
          return;
        }
        onTabClick && onTabClick(tab.key);
      }}
      data-testid={tab['data-testid']}
    >
      {tab.icon && <div className="tab-icon">{tab.icon}</div>}
      {tab.title}
    </StyledTabComponentDiv>
  );
};

export default TabComponent;
