import * as React from "react";
const SvgVideo = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "video", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { id: "Stroke 1", fillRule: "evenodd", clipRule: "evenodd", d: "M 678.93 648.38 C 682.047 718.997 625.056 778.709 551.646 781.712 C 546.237 781.939 282.634 781.408 282.634 781.408 C 209.58 786.954 145.643 734.457 139.88 664.106 C 139.447 658.864 139.565 376.024 139.565 376.024 C 136.328 305.332 193.24 245.466 266.689 242.353 C 272.175 242.086 535.427 242.579 535.427 242.579 C 608.833 237.109 672.97 289.985 678.652 360.678 C 679.05 365.768 678.93 648.38 678.93 648.38 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 3", d: "M 679.05 434.134 L 805.975 330.258 C 837.427 304.51 884.604 326.943 884.567 367.529 L 884.103 650.79 C 884.066 691.377 836.848 713.616 805.474 687.869 L 679.05 583.992", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }));
export default SvgVideo;
