import * as React from "react";
const SvgArrowDown = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "arrow-down", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 511.996 200.768 L 511.996 823.225", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
}, transform: "matrix(-1, 0, 0, -1, 1023.992004, 1023.992981)" }), /* @__PURE__ */ React.createElement("path", { d: "M 762 823.232 L 512.021 572.175 L 262 823.232", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
}, transform: "matrix(-1, 0, 0, -1, 1024, 1395.406982)" }));
export default SvgArrowDown;
