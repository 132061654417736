import * as React from "react";
const SvgNotificationConfig = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "notification-config", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 500.549 177.209 L 481.315 177.209 C 355.661 177.209 292.84 177.209 244.563 201.016 C 198.988 223.49 162.108 260.376 139.63 305.953 C 115.826 354.221 115.826 417.051 115.826 542.702 C 115.826 668.351 115.826 731.179 139.63 779.452 C 162.108 825.022 198.988 861.913 244.563 884.383 C 292.84 908.19 355.661 908.19 481.315 908.19 C 606.97 908.19 669.793 908.19 718.068 884.383 C 763.643 861.913 800.525 825.022 822.999 779.452 C 846.801 731.179 846.801 668.351 846.801 542.702 L 846.801 523.465", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 769.83, cy: 254.155, r: 138.344, strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor"
} }));
export default SvgNotificationConfig;
