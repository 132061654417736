import { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { times8 } from 'Constants/Styles';
import { Button, Drawer, FormInstance, Icon, Icons } from 'Components/Primitives';
import { FilterFormData, FilterMetadata } from 'Components/Filters/Filter.types';
import { FilterTags } from 'Components/Filters/FilterTags';
import { FilterForm } from 'Components/Filters/FilterForm';

interface FilterDrawerFormAndTagsDesktopProps<T> {
  metadata: FilterMetadata[];
  filterData: T;
  resetDisabled: boolean;
  form: FormInstance<T>;
  formId: string;
  onFormChange(formData: T): void;
  onFilterTagsRemove(filterData: FilterMetadata): void;
  onReset?(): void;
}

const StyledFilterDrawerFormAndTags = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: baseline;
  .filter-tags {
    flex-grow: 1;
  }
  .open-filter-drawer-button {
    flex-shrink: 0;
  }
`;

const StyledFilterDrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .reset-btn {
    margin-bottom: ${times8(3)}px;
    align-self: flex-end;
  }
  .filter-form {
    width: 100%;
  }
`;

const FilterDrawerFormAndTagsDesktop = <T extends FilterFormData>({
  metadata,
  filterData,
  resetDisabled,
  form,
  formId,
  onFormChange,
  onFilterTagsRemove,
  onReset
}: FilterDrawerFormAndTagsDesktopProps<T>) => {
  const { t } = useTranslation(['common']);
  const [visible, setVisible] = useState<boolean>(false);

  const onFilterTagClick = useCallback(() => setVisible(true), []);

  return (
    <StyledFilterDrawerFormAndTags className="filter-drawer-form-and-tags">
      <FilterTags
        filterData={filterData}
        metadata={metadata}
        onRemove={onFilterTagsRemove}
        onClick={onFilterTagClick}
      />

      <Button
        className="open-filter-drawer-button"
        data-testid="open-filter-drawer-button"
        onClick={() => setVisible(true)}
        icon={<Icon icon={Icons.Filter} />}
      >
        {t('filters.filter')}
      </Button>

      <Drawer
        title={<h2>{t('filters.filters')}</h2>}
        size="small"
        onClose={() => setVisible(false)}
        open={visible}
        destroyOnClose={false}
        zIndex={700}
      >
        <StyledFilterDrawerContent>
          {onReset && (
            <Button
              data-testid="filters-reset-button"
              className="reset-btn"
              type="link"
              onClick={onReset}
              disabled={resetDisabled}
            >
              {t('filters.reset')}
            </Button>
          )}

          <div className="filter-form">
            <FilterForm
              metadata={metadata}
              formId={formId}
              form={form}
              initialFormData={filterData}
              onChange={onFormChange}
            />
          </div>
        </StyledFilterDrawerContent>
      </Drawer>
    </StyledFilterDrawerFormAndTags>
  );
};

export default FilterDrawerFormAndTagsDesktop;
