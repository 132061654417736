import * as React from "react";
const SvgDragNDrop = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "drag-n-drop", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { x: 315.167, y: 324.975, width: 171.838, height: 171.838, style: {
  fill: "currentColor"
}, rx: 26.164, ry: 26.164 }), /* @__PURE__ */ React.createElement("rect", { x: 536.995, y: 324.975, width: 171.838, height: 171.838, style: {
  fill: "currentColor"
}, rx: 26.164, ry: 26.164 }), /* @__PURE__ */ React.createElement("rect", { x: 315.167, y: 122.764, width: 171.838, height: 171.838, style: {
  fill: "currentColor"
}, rx: 26.164, ry: 26.164 }), /* @__PURE__ */ React.createElement("rect", { x: 536.995, y: 122.764, width: 171.838, height: 171.838, style: {
  fill: "currentColor"
}, rx: 26.164, ry: 26.164 }), /* @__PURE__ */ React.createElement("rect", { x: 315.167, y: 527.186, width: 171.838, height: 171.838, style: {
  fill: "currentColor"
}, rx: 26.164, ry: 26.164 }), /* @__PURE__ */ React.createElement("rect", { x: 536.995, y: 527.186, width: 171.838, height: 171.838, style: {
  fill: "currentColor"
}, rx: 26.164, ry: 26.164 }), /* @__PURE__ */ React.createElement("rect", { x: 315.167, y: 729.398, width: 171.838, height: 171.838, style: {
  fill: "currentColor"
}, rx: 26.164, ry: 26.164 }), /* @__PURE__ */ React.createElement("rect", { x: 536.995, y: 729.398, width: 171.838, height: 171.838, style: {
  fill: "currentColor"
}, rx: 26.164, ry: 26.164 }));
export default SvgDragNDrop;
