import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Colors, times8 } from 'Constants/Styles';
import { Input } from 'Components/Primitives/Input/Input';
import { Button } from 'Components/Primitives/Button/Button';
import { Icon } from 'Components/Primitives/Icon/Icon';
import { Icons } from 'Components/Primitives/Icon/Icon.types';
import { Tag, TagList } from 'Components/Primitives/Tag/Tag';
import EditTermModal from 'Components/Primitives/TermsInput/EditTermModal';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export interface TermsInputProps {
  value?: string[];
  color?: string;
  borderless?: boolean;
  className?: string;
  onChange?(v: string[]): void;
}

const StyledTermsInput = styled.div`
  .term-input-form {
    display: flex;
    min-width: 0;
  }
  .term-input-form input {
    flex-grow: 1;
    max-width: 300px;
    margin-right: ${times8()}px;
  }
  .error-message {
    color: ${Colors.Negative};
  }
  .terms-tag-list {
    margin-top: ${times8(2)}px;
  }
  .term-tag:hover {
    .term-value {
      width: calc(100% - 20px);
      margin-right: 4px;
    }
    .edit-term-button {
      display: block;
    }
  }
  &.borderless {
    .term-input-form input {
      padding: 0;
      border-bottom: 1px solid ${Colors.Border};
      border-radius: 0;
    }
    .terms-tag-list {
      margin-top: ${times8()}px;
    }
  }
`;

const StyledTermAndEdit = styled.div<{ color?: string }>`
  position: relative;
  .term-sizer {
    visibility: hidden;
  }
  .term-display {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 4px);
  }
  .term-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .edit-term-button {
    color: ${({ color }) => color};
    display: none;
  }
`;

const TermAndEdit: React.FC<{ term: string; color?: string; onEdit(): void }> = ({ term, color, onEdit }) => {
  return (
    <StyledTermAndEdit color={color}>
      <div className="term-sizer">{term}</div>
      <div className="term-display">
        <span className="term-value">{term}</span>
        <Button
          className="edit-term-button"
          data-testid="edit-term-button"
          type="text"
          size="small"
          icon={<Icon icon={Icons.Edit} />}
          onClick={onEdit}
        />
      </div>
    </StyledTermAndEdit>
  );
};

export const TermsInput: React.FC<TermsInputProps> = ({ value, color, borderless, className, onChange }) => {
  const { t } = useTranslation(['common']);

  const [displayTerms, setDisplayTerms] = useState<string[]>(value || []);
  const [editTerm, setEditTerm] = useState<string | null>(null);
  const [addTermInputValue, setAddTermInputValue] = useState<string>('');

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const inputRef = useRef<any>(null);

  const errorMessage = useMemo(
    (): string | null =>
      displayTerms.map((term) => term.toLowerCase()).includes(addTermInputValue.toLowerCase())
        ? t('terms-input.term-exists-error')
        : null,
    [t, addTermInputValue, displayTerms]
  );

  const onAddTerm = useCallback(() => {
    const updatedList = [...displayTerms, addTermInputValue];
    setDisplayTerms(updatedList);
    onChange && onChange(updatedList);

    setAddTermInputValue('');
    setTimeout(() => {
      inputRef.current && inputRef.current!.focus();
    }, 0);
  }, [displayTerms, addTermInputValue, inputRef, onChange]);

  const onEditTerm = useCallback(
    (oldTerm: string, newTerm: string) => {
      const updatedList = [...displayTerms.map((v) => (v === oldTerm ? newTerm : v))];
      setDisplayTerms(updatedList);
      onChange && onChange(updatedList);

      setEditTerm(null);
    },
    [displayTerms, onChange]
  );

  const onDeleteTerm = useCallback(
    (term: string) => {
      const updatedList = [...displayTerms.filter((v) => v !== term)];
      setDisplayTerms(updatedList);

      onChange && onChange(updatedList);
    },
    [displayTerms, onChange]
  );

  const onInputPressEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      if (addTermInputValue === '' || errorMessage !== null) return;
      onAddTerm();
    },
    [addTermInputValue, errorMessage, onAddTerm]
  );

  useEffect(() => {
    if (!value) return;
    setDisplayTerms(value);
  }, [value]);

  return (
    <>
      <StyledTermsInput className={classNames(className, borderless && 'borderless')}>
        <div className="term-input-form">
          <Input
            ref={inputRef}
            data-testid="add-term-input"
            placeholder={t('terms-input.placeholder')}
            value={addTermInputValue}
            onChange={(e) => setAddTermInputValue(e.target.value)}
            onPressEnter={onInputPressEnter}
            variant={borderless ? 'borderless' :undefined}
          />

          <Button
            data-testid="add-term-submit"
            className="add-term-submit"
            type="primary"
            icon={<Icon icon={Icons.Plus} />}
            disabled={addTermInputValue === '' || errorMessage !== null}
            onClick={onAddTerm}
            size={borderless ? 'small' : 'large'}
          >
            {t('add')}
          </Button>
        </div>
        {errorMessage && <p className="error-message caption">{errorMessage}</p>}

        {displayTerms.length > 0 && (
          <TagList className="terms-tag-list">
            {displayTerms.map((term) => (
              <Tag
                data-testid="term-tag"
                className="term-tag"
                key={term}
                color={color}
                onClose={() => onDeleteTerm(term)}
              >
                <TermAndEdit term={term} color={color} onEdit={() => setEditTerm(term)} />
              </Tag>
            ))}
          </TagList>
        )}
      </StyledTermsInput>

      <EditTermModal term={editTerm} isOpen={editTerm !== null} onEdit={onEditTerm} onClose={() => setEditTerm(null)} />
    </>
  );
};
