import * as React from "react";
const SvgTicketsOpen = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "tickets-open", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 805.517 292.176 C 805.517 188.252 734.467 146.591 632.174 146.591 L 391.942 146.591 C 292.79 146.591 218.482 185.412 218.482 285.245 L 218.482 841.491 C 218.482 868.91 247.986 886.179 271.883 872.775 L 512.985 737.528 L 752.003 872.544 C 775.939 886.028 805.517 868.759 805.517 841.302 L 805.517 292.176 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 372.291 400.758 L 648.766 400.758", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 60,
  stroke: "currentColor"
} }));
export default SvgTicketsOpen;
