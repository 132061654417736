import { useEffect, useState } from 'react';
import { ShowcaseAvatar } from 'Components/Primitives';
import { useCustomersMap } from 'hooks';
import { sortValues } from 'Components/Table';
import { nonNullable } from 'Utils/functional';

export const useCustomerAvatarList = (customerIds: number[]): ShowcaseAvatar[] => {
  const [avatarList, setAvatarList] = useState<ShowcaseAvatar[]>([]);

  const customersMap = useCustomersMap();

  useEffect(() => {
    if (customersMap?.size === 0) return;

    setAvatarList(
      customerIds
        .map((id) => {
          const customer = customersMap?.get(id);
          return customer
            ? {
                key: customer.customer.value,
                title: customer.customer.data.name,
                avatarSrc: customer.customer.data.icon,
                linkTo: `/customers/${customer.customer.value}/overview`
              }
            : null;
        })
        .sort((a, b) => sortValues({ a: a?.title || null, b: b?.title || null, sortOrder: 'ascend' }))
        .filter(nonNullable)
    );
  }, [customerIds, customersMap]);

  return avatarList;
};
