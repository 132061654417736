import * as React from "react";
const SvgDocFolded = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "doc-folded", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 636.076 119.767 L 331.628 119.767 C 236.445 119.425 158.422 190.337 156.179 279.255 L 156.179 737.445 C 154.074 827.817 230.724 902.707 327.418 904.717 C 328.837 904.717 330.209 904.76 331.628 904.717 L 697.214 904.717 C 793.038 901.081 868.544 827.09 867.858 737.445 L 867.858 345.42 L 636.076 119.767 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: 60,
  fill: "none",
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 624.051 119.264 L 624.051 243.681 C 624.051 304.414 676.585 353.642 741.566 353.813 L 867.638 353.813", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: 60,
  fill: "none",
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 615.498 658.526 L 368.386 658.526", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: 60,
  fill: "none",
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 521.916 498.033 L 368.341 498.033", strokeLinecap: "round", strokeLinejoin: "round", style: {
  strokeWidth: 60,
  fill: "none",
  stroke: "currentColor"
} }));
export default SvgDocFolded;
