import React from 'react';
import styled from '@emotion/styled';
import { WidgetBox } from 'Components/Styled/WidgetBox';
import { CardHover } from 'Components/Styled/StyledSnippets';
import { ColorPalette, Colors, times8 } from 'Constants/Styles';
import { Icons } from 'Components/Primitives/Icon/Icon.types';
import { Icon } from 'Components/Primitives/Icon/Icon';

export interface AddCardProps {
  title: string;
  className?: string;
  'data-testid'?: string;
  onClick(): void;
}

const StyledAddCard = styled(WidgetBox)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.SubText};
  .add-icon {
    display: block;
    font-size: 50px;
    margin-bottom: ${times8(2)}px;
  }
  h5 {
    font-weight: normal;
    margin-bottom: ${times8(2)}px;
  }
  ${CardHover};
`;

export const AddCard: React.FC<AddCardProps> = (props) => {
  const { title, className, 'data-testid': dataTestId, onClick } = props;

  return (
    <StyledAddCard
      role="button"
      className={className}
      data-testid={dataTestId}
      backgroundColor={ColorPalette.Gray100}
      onClick={onClick}
    >
      <Icon className="add-icon" icon={Icons.Plus} />
      <h5>{title}</h5>
    </StyledAddCard>
  );
};
