import React, { useEffect, useState } from 'react';
import { AvatarShowcase, AvatarSize, ShowcaseAvatar } from 'Components/Primitives';
import { nonNullable } from 'Utils/functional';
import { useStakeholdersMap } from 'hooks';
import { sortValues } from 'Components/Table';

interface StakeholderShowcaseProps {
  size: AvatarSize;
  customerId: number;
  stakeholderIds: number[];
  maxAvatarsToShow?: number;
}

export const StakeholderShowcase: React.FC<StakeholderShowcaseProps> = ({
  size,
  customerId,
  stakeholderIds,
  maxAvatarsToShow
}) => {
  const [avatarList, setAvatarList] = useState<ShowcaseAvatar[]>([]);
  const stakeholdersMap = useStakeholdersMap(customerId);

  useEffect(() => {
    if (stakeholdersMap.size === 0) return;

    setAvatarList(
      stakeholderIds
        .map((id) => {
          const stakeholder = stakeholdersMap.get(id);
          return stakeholder
            ? {
                key: stakeholder.stakeholder.value,
                title: stakeholder.stakeholder.data.name,
                avatarSrc: null,
                linkTo: `/stakeholder/${stakeholder.stakeholder.value}`
              }
            : null;
        })
        .sort((a, b) => sortValues({ a: a?.title || null, b: b?.title || null, sortOrder: 'ascend' }))
        .filter(nonNullable)
    );
  }, [stakeholderIds, stakeholdersMap]);

  return <AvatarShowcase size={size} avatarList={avatarList} maxAvatarsToShow={maxAvatarsToShow} />;
};
