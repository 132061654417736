import * as React from "react";
const SvgUser = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "user", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 511.999 648.756 C 349.322 648.756 210.386 673.344 210.386 771.862 C 210.386 870.385 348.474 895.862 511.999 895.862 C 674.72 895.862 813.615 871.233 813.615 772.753 C 813.615 674.274 675.565 648.756 511.999 648.756 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 511.999 508.239 C 618.773 508.239 705.315 421.694 705.315 314.924 C 705.315 208.15 618.773 121.606 511.999 121.606 C 405.27 121.606 318.727 208.15 318.727 314.924 C 318.343 421.316 404.255 507.86 510.689 508.239 L 511.999 508.239 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }));
export default SvgUser;
