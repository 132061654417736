import * as React from "react";
const SvgNotification = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "notification", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M 512 761.918 C 743.033 761.918 849.917 732.279 860.236 613.319 C 860.236 494.44 785.722 502.085 785.722 356.224 C 785.722 242.289 677.727 112.658 512 112.658 C 346.273 112.658 238.282 242.289 238.282 356.224 C 238.282 502.085 163.764 494.44 163.764 613.319 C 174.128 732.731 281.009 761.918 512 761.918 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 62,
  stroke: "currentColor"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 609.868 864.522 C 553.983 926.579 466.8 927.315 410.376 864.522", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  strokeWidth: 62,
  stroke: "currentColor"
} }));
export default SvgNotification;
