import {
  AbsoluteDateRange,
  DateRangeValue,
  PredefinedDateRange,
  PredefinedDateRangeOption
} from 'Types/DateRange.types';
import { RIGHT_ARROW } from 'Constants/Chars.consts';
import {
  addTimeToDate,
  formatDate,
  getStartOfMonth,
  getStartOfQuarter,
  getStartOfWeek,
  getStartOfYear,
  subtractTimeFromDate
} from 'Utils/date';
import { translate } from 'Utils/translate';

const dateRangeLabel: Record<PredefinedDateRange, { label: string; prefix: string; description: string }> = {
  // Present periods
  [PredefinedDateRange.ThisWeek]: {
    label: 'date-range.this-week',
    prefix: 'date-range.this-week-prefix',
    description: 'date-range.this-week-description'
  },
  [PredefinedDateRange.ThisMonth]: {
    label: 'date-range.this-month',
    prefix: 'date-range.this-month-prefix',
    description: 'date-range.this-month-description'
  },
  [PredefinedDateRange.ThisQuarter]: {
    label: 'date-range.this-quarter',
    prefix: 'date-range.this-quarter-prefix',
    description: 'date-range.this-quarter-description'
  },
  [PredefinedDateRange.ThisYear]: {
    label: 'date-range.this-year',
    prefix: 'date-range.this-year-prefix',
    description: 'date-range.this-year-description'
  },

  // Past dates
  [PredefinedDateRange.LastWeek]: {
    label: 'date-range.last-week',
    prefix: 'date-range.last-week-prefix',
    description: 'date-range.last-week-description'
  },
  [PredefinedDateRange.LastMonth]: {
    label: 'date-range.last-month',
    prefix: 'date-range.last-month-prefix',
    description: 'date-range.last-month-description'
  },
  [PredefinedDateRange.LastQuarter]: {
    label: 'date-range.last-quarter',
    prefix: 'date-range.last-quarter-prefix',
    description: 'date-range.last-quarter-description'
  },
  [PredefinedDateRange.LastYear]: {
    label: 'date-range.last-year',
    prefix: 'date-range.last-year-prefix',
    description: 'date-range.last-year-description'
  },

  [PredefinedDateRange.PastWeek]: {
    label: 'date-range.past-week',
    prefix: 'date-range.past-week-prefix',
    description: 'date-range.past-week-description'
  },
  [PredefinedDateRange.Past2Weeks]: {
    label: 'date-range.past-2-weeks',
    prefix: 'date-range.past-2-weeks-prefix',
    description: 'date-range.past-2-weeks-description'
  },
  [PredefinedDateRange.PastMonth]: {
    label: 'date-range.past-month',
    prefix: 'date-range.past-month-prefix',
    description: 'date-range.past-month-description'
  },
  [PredefinedDateRange.Past3Months]: {
    label: 'date-range.past-3-months',
    prefix: 'date-range.past-3-months-prefix',
    description: 'date-range.past-3-months-description'
  },
  [PredefinedDateRange.Past6Months]: {
    label: 'date-range.past-6-months',
    prefix: 'date-range.past-6-months-prefix',
    description: 'date-range.past-6-months-description'
  },
  [PredefinedDateRange.PastYear]: {
    label: 'date-range.past-year',
    prefix: 'date-range.past-year-prefix',
    description: 'date-range.past-year-description'
  },

  // Future dates
  [PredefinedDateRange.NextWeek]: {
    label: 'date-range.next-week',
    prefix: 'date-range.next-week-prefix',
    description: 'date-range.next-week-description'
  },
  [PredefinedDateRange.NextMonth]: {
    label: 'date-range.next-month',
    prefix: 'date-range.next-month-prefix',
    description: 'date-range.next-month-description'
  },
  [PredefinedDateRange.NextQuarter]: {
    label: 'date-range.next-quarter',
    prefix: 'date-range.next-quarter-prefix',
    description: 'date-range.next-quarter-description'
  },
  [PredefinedDateRange.NextYear]: {
    label: 'date-range.next-year',
    prefix: 'date-range.next-year-prefix',
    description: 'date-range.next-year-description'
  },

  [PredefinedDateRange.Next7Days]: {
    label: 'date-range.next-7-days',
    prefix: 'date-range.next-7-days-prefix',
    description: 'date-range.next-7-days-description'
  },
  [PredefinedDateRange.Next30Days]: {
    label: 'date-range.next-30-days',
    prefix: 'date-range.next-30-days-prefix',
    description: 'date-range.next-30-days-description'
  },
  [PredefinedDateRange.Next90Days]: {
    label: 'date-range.next-90-days',
    prefix: 'date-range.next-90-days-prefix',
    description: 'date-range.next-90-days-description'
  }
};

const PREDEFINED_PAST_DATES = [
  PredefinedDateRange.PastWeek,
  PredefinedDateRange.Past2Weeks,
  PredefinedDateRange.PastMonth,
  PredefinedDateRange.Past3Months,
  PredefinedDateRange.Past6Months,
  PredefinedDateRange.PastYear,
  PredefinedDateRange.ThisWeek,
  PredefinedDateRange.ThisMonth,
  PredefinedDateRange.ThisQuarter,
  PredefinedDateRange.ThisYear,
  PredefinedDateRange.LastWeek,
  PredefinedDateRange.LastMonth,
  PredefinedDateRange.LastQuarter,
  PredefinedDateRange.LastYear
];

export const PREDIFINED_HISTORICAL_DATE_RANGE: PredefinedDateRange[] = [
  PredefinedDateRange.LastWeek,
  PredefinedDateRange.LastMonth,
  PredefinedDateRange.LastQuarter,
  PredefinedDateRange.LastYear
];

export const getPredefinedDateRangeOptions = (
  predefinedOptions?: PredefinedDateRange[] | 'all' | undefined
): PredefinedDateRangeOption[] => {
  const options: PredefinedDateRange[] =
    predefinedOptions === 'all'
      ? Object.values(PredefinedDateRange)
      : Array.isArray(predefinedOptions)
        ? Object.values(predefinedOptions)
        : Object.values(PREDEFINED_PAST_DATES);
  return options.map((dateRange) => ({
    value: dateRange,
    label: getPredefinedDateRangeLabel(dateRange),
    description: getPredefinedDateRangeDescription(dateRange)
  }));
};

const getPredefinedDateRangeLabel = (predefinedDateRange: PredefinedDateRange): string =>
  translate(`common:${dateRangeLabel[predefinedDateRange].label}`);
const getPredefinedDateRangeLabelWithPrefix = (predefinedDateRange: PredefinedDateRange): string =>
  translate(`common:${dateRangeLabel[predefinedDateRange].prefix}`);
const getPredefinedDateRangeDescription = (predefinedDateRange: PredefinedDateRange): string =>
  translate(`common:${dateRangeLabel[predefinedDateRange].description}`);

export const getDateRangeText = (dateRange: DateRangeValue, withPrefix = false) => {
  const isAbsoluteDateRange = Array.isArray(dateRange);

  return isAbsoluteDateRange
    ? withPrefix
      ? `${translate('common:date-range.date-range-prefix')} ${formatDate(dateRange[0])} ${RIGHT_ARROW} ${formatDate(dateRange[1])}`
      : `${formatDate(dateRange[0])} ${RIGHT_ARROW} ${formatDate(dateRange[1])}`
    : withPrefix
      ? getPredefinedDateRangeLabelWithPrefix(dateRange)
      : getPredefinedDateRangeLabel(dateRange);
};

const getHistoricalWarningDate = (dateRange: PredefinedDateRange): string => {
  switch (dateRange) {
    case PredefinedDateRange.LastWeek:
      return formatDate(subtractTimeFromDate(getStartOfWeek(new Date()), { days: 1 }));
    case PredefinedDateRange.LastMonth:
      return formatDate(subtractTimeFromDate(getStartOfMonth(new Date()), { days: 1 }));
    case PredefinedDateRange.LastQuarter:
      return formatDate(subtractTimeFromDate(getStartOfQuarter(new Date()), { days: 1 }));
    case PredefinedDateRange.LastYear:
      return formatDate(subtractTimeFromDate(getStartOfYear(new Date()), { days: 1 }));
    default:
      return '';
  }
};
export const getDateRangeIsHistoricalWarning = (dateRange: DateRangeValue): string | null =>
  !Array.isArray(dateRange) && PREDIFINED_HISTORICAL_DATE_RANGE.includes(dateRange)
    ? translate('common:date-range.historical-data-warning', { date: getHistoricalWarningDate(dateRange) })
    : null;

export const getDateRangeDates = (dateRange: DateRangeValue): { startDate: Date; endDate: Date } =>
  Array.isArray(dateRange)
    ? { startDate: new Date(dateRange[0]), endDate: new Date(dateRange[1]) }
    : getPredefinedDateRangeAsDates(dateRange);

// checks if date range string is an absolute date range
const ABSOLUTE_DATE_RANGE_VALIDATION = /\[\d{4}-\d{2}-\d{2},\d{4}-\d{2}-\d{2}]/;
export const isAbsoluteDateRange = (dateRangeAsString: string): boolean =>
  ABSOLUTE_DATE_RANGE_VALIDATION.test(dateRangeAsString);

export const convertQueryDateRangeToAbsoluteDateRange = (val: string): AbsoluteDateRange =>
  val.replace('[', '').replace(']', '').split(',') as AbsoluteDateRange;

const getPredefinedDateRangeAsDates = (dateRange: PredefinedDateRange): { startDate: Date; endDate: Date } => {
  let endDate: Date;
  let startDate: Date;

  switch (dateRange) {
    // Present periods
    case PredefinedDateRange.ThisWeek:
      startDate = getStartOfWeek(new Date());
      endDate = addTimeToDate(startDate, { weeks: 1 });
      break;
    case PredefinedDateRange.ThisMonth:
      startDate = getStartOfMonth(new Date());
      endDate = addTimeToDate(startDate, { months: 1 });
      break;
    case PredefinedDateRange.ThisQuarter:
      startDate = getStartOfQuarter(new Date());
      endDate = addTimeToDate(startDate, { months: 3 });
      break;
    case PredefinedDateRange.ThisYear:
      startDate = getStartOfYear(new Date());
      endDate = addTimeToDate(startDate, { years: 1 });
      break;

    // Past dates
    case PredefinedDateRange.LastWeek:
      endDate = getStartOfWeek(new Date());
      startDate = subtractTimeFromDate(endDate, { weeks: 1 });
      break;
    case PredefinedDateRange.LastMonth:
      endDate = getStartOfMonth(new Date());
      startDate = subtractTimeFromDate(endDate, { months: 1 });
      break;
    case PredefinedDateRange.LastQuarter:
      endDate = getStartOfQuarter(new Date());
      startDate = subtractTimeFromDate(endDate, { months: 3 });
      break;
    case PredefinedDateRange.LastYear:
      endDate = getStartOfYear(new Date());
      startDate = subtractTimeFromDate(endDate, { years: 1 });
      break;

    case PredefinedDateRange.PastWeek:
      endDate = new Date();
      startDate = subtractTimeFromDate(endDate, { days: 7 });
      break;
    case PredefinedDateRange.Past2Weeks:
      endDate = new Date();
      startDate = subtractTimeFromDate(endDate, { days: 14 });
      break;
    case PredefinedDateRange.PastMonth:
      endDate = new Date();
      startDate = subtractTimeFromDate(endDate, { days: 30 });
      break;
    case PredefinedDateRange.Past3Months:
      endDate = new Date();
      startDate = subtractTimeFromDate(endDate, { days: 90 });
      break;
    case PredefinedDateRange.Past6Months:
      endDate = new Date();
      startDate = subtractTimeFromDate(endDate, { days: 180 });
      break;
    case PredefinedDateRange.PastYear:
      endDate = new Date();
      startDate = subtractTimeFromDate(endDate, { days: 365 });
      break;

    // Future dates
    case PredefinedDateRange.NextWeek:
      startDate = addTimeToDate(getStartOfWeek(new Date()), { weeks: 1 });
      endDate = addTimeToDate(startDate, { weeks: 1 });
      break;
    case PredefinedDateRange.NextMonth:
      startDate = addTimeToDate(getStartOfMonth(new Date()), { months: 1 });
      endDate = addTimeToDate(startDate, { months: 1 });
      break;
    case PredefinedDateRange.NextQuarter:
      startDate = addTimeToDate(getStartOfQuarter(new Date()), { months: 3 });
      endDate = addTimeToDate(startDate, { months: 3 });
      break;
    case PredefinedDateRange.NextYear:
      startDate = addTimeToDate(getStartOfYear(new Date()), { years: 1 });
      endDate = addTimeToDate(startDate, { years: 1 });
      break;

    case PredefinedDateRange.Next7Days:
      startDate = new Date();
      endDate = addTimeToDate(startDate, { days: 7 });
      break;
    case PredefinedDateRange.Next30Days:
      startDate = new Date();
      endDate = addTimeToDate(startDate, { days: 30 });
      break;
    case PredefinedDateRange.Next90Days:
      startDate = new Date();
      endDate = addTimeToDate(startDate, { days: 90 });
      break;
  }

  return { startDate, endDate };
};
