import styled from '@emotion/styled';
import { ColorPalette } from 'Constants/Styles';

export const StyledMoreNumber = styled.span<{ height: number }>`
  padding: 0 6px;
  height: ${({ height }) => height}px;
  line-height: ${({ height }) => height}px;
  border-radius: ${({ height }) => height / 2}px;
  background-color: ${ColorPalette.Gray200};
  outline: 1px solid ${ColorPalette.White};
`;
