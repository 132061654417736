import { FilterFormData, FilterMetadata, FilterType, QueryPagination } from 'Components/Filters/Filter.types';
import * as Sentry from '@sentry/react';
import { DateRangeValue } from 'Types/DateRange.types';
import setWith from 'lodash/setWith';
import { FilterMetadataApi, FilterMetadataApiKeys } from 'Components/Filters/FilterApi.consts';
import { nonNullable } from 'Utils/functional';
import { convertQueryDateRangeToAbsoluteDateRange, isAbsoluteDateRange } from 'Components/DateRangeSelect';

const filterTypeList = Object.values(FilterType);

// checks filter type is supported, used in reports where
// filter metadata is returned from BE
export const filterTypeSupported = (type: string): boolean => {
  if (filterTypeList.includes(type as FilterType)) {
    return true;
  } else {
    console.warn(`No filter support for type "${type}"`);
    Sentry.captureException(`No filter support for type "${type}"`);
    return false;
  }
};

// converts dateRange to string
export const convertDateRangeValueToQueryDateRange = (dateRange: DateRangeValue): string =>
  Array.isArray(dateRange) ? `[${dateRange.join(',')}]` : dateRange;

export const covertQueryDateRangeToDateRangeValue = (dateRange: string): DateRangeValue =>
  isAbsoluteDateRange(dateRange) ? convertQueryDateRangeToAbsoluteDateRange(dateRange) : (dateRange as DateRangeValue);

// converts pagination to range
export const convertPaginationToGetRange = (pagination: QueryPagination): string =>
  `[${pagination.from},${pagination.to}]`;

// coverts flat filter structure to JSON object
// used for api POST requests
export const convertFiltersToPostFilters = (filters: FilterFormData, filtersMetadata?: FilterMetadata[]): object => {
  const metadata = filtersMetadata || getMetadataFromFilterKeys(Object.keys(filters));
  const queryFilters = convertFilterValuesToQueryValues(filters, metadata);
  return Object.entries(queryFilters).reduce((acc: object, [k, v]) => {
    nonNullable(v) && setWith(acc, k, v, Object);
    return acc;
  }, {});
};

const getMetadataFromFilterKeys = (filterKeys: string[]): FilterMetadata[] =>
  filterKeys
    .map((key) =>
      (Object.values(FilterMetadataApiKeys) as string[]).includes(key)
        ? FilterMetadataApi[key as FilterMetadataApiKeys]
        : undefined
    )
    .filter(nonNullable);

// converts filters where the date range are string to a [string, string] format
export const convertQueryFiltersToFilters = (
  filters: FilterFormData,
  filtersMetadata?: FilterMetadata[]
): FilterFormData => {
  const metadata = filtersMetadata || getMetadataFromFilterKeys(Object.keys(filters));
  const dateRangeFitlers = metadata.reduce((acc: FilterFormData, fmd) => {
    if (fmd.type === FilterType.DateRange && typeof filters[fmd.key] === 'string') {
      acc[fmd.key] = isAbsoluteDateRange(filters[fmd.key] as string)
        ? convertQueryDateRangeToAbsoluteDateRange(filters[fmd.key] as string)
        : filters[fmd.key];
    }
    return acc;
  }, {});
  return {
    ...filters,
    ...dateRangeFitlers
  };
};

// converts date range fitlers to strings
const convertFilterValuesToQueryValues = (filters: FilterFormData, filtersMetadata: FilterMetadata[]): FilterFormData =>
  filtersMetadata.reduce((acc: FilterFormData, fmd) => {
    switch (fmd.type) {
      case FilterType.DateRange:
        acc[fmd.key] = filters[fmd.key]
          ? convertDateRangeValueToQueryDateRange(filters[fmd.key] as DateRangeValue)
          : null;
        break;
      case FilterType.Boolean:
        acc[fmd.key] =
          typeof filters[fmd.key] === 'boolean'
            ? filters[fmd.key]
            : filters[fmd.key] === 'true'
              ? true
              : filters[fmd.key] === 'false'
                ? false
                : null;
        break;
      default:
        acc[fmd.key] = filters[fmd.key];
    }
    return acc;
  }, {});
