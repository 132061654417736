import * as React from "react";
const SvgEventTicket = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "event-ticket", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { id: "Stroke 1", d: "M 583.836 211.039 L 583.836 305.023", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 3", d: "M 583.836 735.707 L 583.836 814.311", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 6", d: "M 583.836 602.289 L 583.836 415.062", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 7", fillRule: "evenodd", clipRule: "evenodd", d: "M 772.283 822.688 C 843.046 822.688 900.361 766.089 900.361 696.203 L 900.361 595.52 C 853.536 595.52 815.826 558.281 815.826 512.039 C 815.826 465.801 853.536 428.518 900.361 428.518 L 900.321 327.795 C 900.321 257.914 842.964 201.312 772.245 201.312 L 251.756 201.312 C 181.035 201.312 123.68 257.914 123.68 327.795 L 123.639 431.798 C 170.463 431.798 208.174 465.801 208.174 512.039 C 208.174 558.281 170.463 595.52 123.639 595.52 L 123.639 696.203 C 123.639 766.089 180.954 822.688 251.716 822.688 L 772.283 822.688 Z", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60
} }));
export default SvgEventTicket;
