import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { DateRangeValue, PredefinedDateRange, PredefinedDateRangeOption } from 'Types/DateRange.types';
import { RangePicker, RangeValue, Dropdown, Input, Radio, Icons, Icon, RadioOption } from 'Components/Primitives';
import { addTimeToDate, formatDateAsQueryStringDate, getLocalDate } from 'Utils/date';
import { useTranslation } from 'react-i18next';
import { getDateRangeText, getPredefinedDateRangeOptions } from 'Components/DateRangeSelect/DateRangeSelectUtils';
import { Colors, times8 } from 'Constants/Styles';
import classNames from 'classnames';
import { isSameDay } from 'date-fns';

export interface DateRangeSelectProps {
  value?: DateRangeValue;
  placeholder?: string;
  className?: string;
  allowClear?: boolean;
  borderless?: boolean;
  predefinedOptions?: PredefinedDateRange[] | 'all';
  withPrefix?: boolean;
  disabled?: boolean;
  'data-testid'?: string;
  onChange?: (value: DateRangeValue | undefined) => void;
}

enum DateRangePickerType {
  PredefinedOption = 'PredefinedOption',
  DateRangeOption = 'DateRangeOption'
}

const StyledDateRangeSelect = styled(Dropdown)`
  position: relative;
  cursor: pointer;
  input {
    pointer-events: none;
  }
  .ant-select-clear {
    position: absolute;
    font-size: 12px;
    color: ${Colors.SubText};
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  &.disabled {
    pointer-events: none;
  }
  &.borderless {
    .date-range-select-input {
      padding: 0 ${times8(2)}px 0 0;
      outline: none !important;
      border-color: transparent !important;
    }
    input {
      visibility: hidden;
    }
    .ant-select-arrow,
    .ant-select-clear {
      color: initial;
      right: 0;
    }
    &:hover {
      color: ${Colors.Primary};
    }
  }
`;

const StyledDateRangeSelectOverlay = styled.div`
  min-width: 300px;
  background-color: white;
  box-shadow:
    0 3px 6px -4px rgb(0 0 0 / 12%),
    0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  .date-range-radio-options,
  .date-range-picker-wrapper {
    padding: ${times8(2)}px;
  }
  .date-range-radio-options {
    border-bottom: 1px solid ${Colors.Border};
    width: 100%;
  }
  .predefined-option-list {
    max-height: 300px;
    padding: ${times8()}px;
    overflow: auto;
  }
  .predefined-option {
    padding: ${times8()}px;
    cursor: pointer;
    line-height: 1.2;
    &:hover,
    &.selected {
      background-color: rgba(34, 36, 38, 0.04);
    }
    &.selected {
      font-weight: 500;
    }
  }
  .date-range-picker {
    width: 100%;
  }
`;

export const DateRangeSelect: React.FC<DateRangeSelectProps> = ({
  value: defaultValue,
  placeholder,
  className,
  allowClear = false,
  borderless = false,
  predefinedOptions,
  withPrefix,
  disabled,
  'data-testid': dataTestId,
  onChange
}) => {
  const { t } = useTranslation(['common']);

  const [value, setValue] = useState<DateRangeValue | null>();
  const [radioValue, setRadioValue] = useState<DateRangePickerType>();
  const [selectedPredefinedRange, setSelectedPredefinedRange] = useState<PredefinedDateRange>();
  const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date]>();

  const [visible, setVisible] = useState<boolean>(false);
  const [showClear, setShowClear] = useState<boolean>(false);

  const [inputDisplayValue, setInputDisplayValue] = useState<string>();

  const showClearButton = useMemo((): boolean => showClear && value !== undefined, [showClear, value]);

  const predefindDateRangeOptions: PredefinedDateRangeOption[] = useMemo(
    () => getPredefinedDateRangeOptions(predefinedOptions),
    [predefinedOptions]
  );

  const radioOptions = useMemo((): RadioOption<DateRangePickerType>[] => {
    const optionLabels: Record<DateRangePickerType, string> = {
      [DateRangePickerType.PredefinedOption]: t('date-range.predefined'),
      [DateRangePickerType.DateRangeOption]: t('date-range.date-range')
    };

    return Object.values(DateRangePickerType).map((radioOption) => ({
      label: optionLabels[radioOption],
      value: radioOption,
      key: radioOption
    }));
  }, [t]);

  useEffect(() => setValue(defaultValue), [defaultValue]);

  useEffect(() => {
    if (!value) {
      setInputDisplayValue('');
      setRadioValue(DateRangePickerType.PredefinedOption);
      setSelectedPredefinedRange(undefined);
      setSelectedDateRange(undefined);
      return;
    }

    if (Array.isArray(value)) {
      setRadioValue(DateRangePickerType.DateRangeOption);
      setSelectedDateRange([getLocalDate(value[0]), getLocalDate(value[1])]);
      setSelectedPredefinedRange(undefined);
    } else if (Object.values(PredefinedDateRange).includes(value)) {
      setRadioValue(DateRangePickerType.PredefinedOption);
      setSelectedPredefinedRange(value);
      setSelectedDateRange(undefined);
    }

    setInputDisplayValue(getDateRangeText(value, withPrefix));
  }, [value, withPrefix]);

  const onPredefinedChange = useCallback(
    (predefinedDateRange: PredefinedDateRange) => {
      setValue(predefinedDateRange);
      onChange && onChange(predefinedDateRange);
      setVisible(false);
    },
    [onChange]
  );

  const onRangeChange = useCallback(
    (range: RangeValue) => {
      if (range === null) {
        setValue(null);
      } else if (range[0] && range[1]) {
        const start = formatDateAsQueryStringDate(range[0]);
        const end = isSameDay(range[0], range[1])
          ? formatDateAsQueryStringDate(addTimeToDate(range[1], { days: 1 }))
          : formatDateAsQueryStringDate(range[1]);

        setValue([start, end]);
        onChange && onChange([start, end]);
      }
    },
    [onChange]
  );

  const onClear = useCallback(() => {
    setValue(undefined);
    setVisible(false);
    onChange && onChange(undefined);
  }, [onChange]);

  const onRadioChange = useCallback((v: DateRangePickerType) => setRadioValue(v), []);

  return (
    <StyledDateRangeSelect
      date-testid={dataTestId}
      open={visible}
      onOpenChange={(isVisible) => isVisible !== visible && setVisible(isVisible)}
      trigger={['click']}
      dropdownRender={() => (
        <StyledDateRangeSelectOverlay>
          <Radio
            className="date-range-radio-options"
            options={radioOptions}
            value={radioValue}
            layout="horizontal"
            onChange={onRadioChange}
          />

          {radioValue === DateRangePickerType.PredefinedOption && (
            <div className="predefined-option-list">
              {predefindDateRangeOptions.map((option) => (
                <li
                  key={option.value}
                  onClick={() => onPredefinedChange(option.value)}
                  className={classNames('predefined-option', selectedPredefinedRange === option.value && 'selected')}
                  data-testid="predefined-date-option"
                >
                  <p>{option.label}</p>
                  <p className="caption">{option.description}</p>
                </li>
              ))}
            </div>
          )}
          {radioValue === DateRangePickerType.DateRangeOption && (
            <div className="date-range-picker-wrapper">
              <RangePicker
                className="date-range-picker"
                value={selectedDateRange}
                allowClear={false}
                onChange={(range) => onRangeChange(range)}
                onOpenChange={(e) => setVisible(e)}
              />
            </div>
          )}
        </StyledDateRangeSelectOverlay>
      )}
    >
      <div
        className={classNames('date-range-select', className, disabled && 'disabled', borderless && 'borderless')}
        onMouseEnter={() => allowClear && setShowClear(true)}
        onMouseLeave={() => allowClear && setShowClear(false)}
      >
        {borderless && <span className="borderless-display-value">{inputDisplayValue}</span>}
        <Input
          className="date-range-select-input"
          data-testid="date-range-input"
          readOnly
          value={inputDisplayValue}
          placeholder={placeholder || t('date-range.placeholder')}
          style={{ width: borderless ? 0 : '100%' }}
          disabled={disabled}
          suffix={
            showClearButton ? null : visible ? (
              <Icon icon={Icons.ChevronUp} className="ant-select-arrow" />
            ) : (
              <Icon icon={Icons.ChevronDown} className="ant-select-arrow" />
            )
          }
        />
        {allowClear && (
          <button
            data-testid="date-range-select-clear"
            style={{ opacity: showClearButton ? 1 : 0 }}
            className="ant-select-clear"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClear();
            }}
          >
            <Icon icon={Icons.CloseRound} />
          </button>
        )}
      </div>
    </StyledDateRangeSelect>
  );
};
