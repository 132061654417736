import React from 'react';
import { AntdConfigProvider } from 'Providers/AntdConfig';
import AppEntry from 'Views/App/AppEntry';

const App: React.FC = () => {
  return (
    <AntdConfigProvider>
      <AppEntry />
    </AntdConfigProvider>
  );
};

export default App;
