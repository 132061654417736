import * as React from "react";
const SvgTrash = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "trash", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { id: "Stroke 1", d: "M 789.975 412.798 C 789.975 412.798 768.703 676.645 756.363 787.785 C 750.487 840.868 717.697 871.973 663.988 872.953 C 561.779 874.794 459.453 874.911 357.285 872.757 C 305.612 871.699 273.371 840.202 267.612 788.06 C 255.194 675.94 234.039 412.798 234.039 412.798", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 3", d: "M 844.167 286.32 L 179.833 286.32", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }), /* @__PURE__ */ React.createElement("path", { id: "Stroke 5", d: "M 716.161 286.329 C 685.409 286.329 658.926 264.587 652.893 234.461 L 643.374 186.824 C 637.497 164.847 617.596 149.647 594.914 149.647 L 429.085 149.647 C 406.402 149.647 386.501 164.847 380.625 186.824 L 371.105 234.461 C 365.072 264.587 338.59 286.329 307.837 286.329", strokeLinecap: "round", strokeLinejoin: "round", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 70
} }));
export default SvgTrash;
